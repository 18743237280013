import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Appointment = () => {
    const navigate = useNavigate();

    // Retrieve doctor details from session
    const doctorDetails = sessionStorage.getItem('selectedDoctorDetails');
    const selectedDoctorDetails = doctorDetails ? JSON.parse(doctorDetails) : null;
    // Retrieve label name from session
    const labelName = sessionStorage.getItem('selectedCardData');
    const selectedCardData = labelName ? JSON.parse(labelName) : null;

    // Function to get the next 5 days starting from tomorrow and mark Sundays as unavailable
    const getNext5Days = () => {
        const today = new Date();
        today.setDate(today.getDate() + 1); // Start from tomorrow
        const next5Days = [];

        for (let i = 0; i < 6; i++) {
            const date = new Date(today);
            date.setDate(today.getDate() + i);
            const dayOfWeek = date.getDay();

            next5Days.push({
                date: date.toDateString(),
                status: dayOfWeek === 0 ? "Unavailable" : "Available",
            });
        }

        return next5Days;
    };

    // Placeholder for time slots (you should replace this with your actual time slots)
    const timeSlots = [
        "09:00AM-11:00AM",
        "11:00AM-01:00PM",
        "01:00PM-03:00PM",
        "03:00PM-05:00PM",
    ];

    const [appointmentDates, setAppointmentDates] = useState(getNext5Days());
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);

    useEffect(() => {
        const checkLoginStatus = () => {
            // Check if the user is not logged in
            const userId = sessionStorage.getItem('userId');
            const firstName = sessionStorage.getItem('firstName');

            if (!userId || !firstName) {
                // Redirect to the sign-in page
                navigate('/signin');
            }
        };

        // Check the login status on component mount
        checkLoginStatus();

        // Set up a timer to periodically check the login status
        const intervalId = setInterval(() => {
            checkLoginStatus();
        }, 500); // Refresh every 2 seconds

        // Clean up the timer when the component unmounts
        return () => clearInterval(intervalId);
    }, [navigate]);
    const handleConfirm = () => {
        // Store the selected date and time in session storage
        sessionStorage.setItem('selectedAppointmentDate', selectedDate);
        sessionStorage.setItem('selectedAppointmentTime', selectedTimeSlot);

        // After handling the confirmation, you can navigate to a success page or perform other actions
        navigate('/confirmation', { state: { selectedDate, selectedTimeSlot } });
    };

    const handleDateSelection = (date, status) => {
        if (status === "Available") {
            setSelectedDate(date);
            // Reset the selected time slot when a new date is selected
            setSelectedTimeSlot(null);
        }
    };

    const handleTimeSlotSelection = (timeSlot) => {
        setSelectedTimeSlot(timeSlot);
    };

    return (
        <main className="page-blog-post" style={{ paddingTop: '30px', paddingBottom: '50px' }}>
            <div className="container" style={{paddingTop:'100px'}}>
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <section className="blog-post-author-info-section">
                            <div className="row">
                                <div className="col-lg-10">
                                    <h5 className="blog-post-author-info-section-title">Book Appointment at {selectedDoctorDetails.location}</h5>
                                    <div className="blog-post-author-info media">
                                        <img src={selectedDoctorDetails.image} alt={`Doctor ${selectedDoctorDetails.doctor}`} className="align-self-sm-center blog-post-author-avatar"/>
                                        <div className="media-body">
                                            <div className="d-flex flex-wrap align-items-center">
                                                <h6 className="mr-3 mb-0">{selectedDoctorDetails.doctor}</h6>
                                                <p class="blog-post-date mr-3 mb-0">{selectedCardData ? selectedCardData.name : "New"}</p>
                                            </div>
                                            <p className="author-description"><img className="blog-post-excerpt" src={require('../assets/img/location.png')} alt="blog" width="18px" /> {selectedDoctorDetails.location}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <form>
                            <section>
                                <div className="container">
                                    <h3 className="text-muted mb-5">Select Date</h3>
                                    <div className="row">
                                        {appointmentDates.map((appointment, index) => (
                                            <div className="col-lg-2" key={index}>
                                                <input
                                                    type="radio"
                                                    id={`date-${index}`}
                                                    name="appointmentDate"
                                                    value={appointment.date}
                                                    onChange={() => handleDateSelection(appointment.date, appointment.status)}
                                                    checked={selectedDate === appointment.date}
                                                    disabled={appointment.status === "Unavailable"}
                                                    style={{ display: "none" }}
                                                />
                                                <label
                                                    htmlFor={`date-${index}`}
                                                    className={`card pricing-card_1 ${
                                                        selectedDate === appointment.date ? "selected-date" : ""
                                                    } ${appointment.status === "Unavailable" ? "unavailable-date" : ""}`}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    <div className="card-body">
                                                        <h6>{appointment.date}</h6>
                                                        <p>{appointment.status}</p>
                                                    </div>
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </section>
                            <section style={{ paddingTop: '30px'}}> 
                                <div className="container">
                                    <h3 className="text-muted mb-5">Select Time Slot</h3>
                                    <div className="row">
                                        {timeSlots.map((timeSlot, index) => (
                                            <div className="col-lg-3" key={index}>
                                                <input
                                                    type="radio"
                                                    id={`time-${index}`}
                                                    name="appointmentTime"
                                                    value={timeSlot}
                                                    onChange={() => handleTimeSlotSelection(timeSlot)}
                                                    checked={selectedTimeSlot === timeSlot}
                                                    disabled={!selectedDate || selectedDate === "Unavailable"}
                                                    style={{ display: "none" }}
                                                />
                                                <label
                                                htmlFor={`time-${index}`}
                                                className={`card pricing-card_1 ${
                                                    selectedTimeSlot === timeSlot ? "selected-time" : ""
                                                } ${!selectedDate || selectedDate === "Unavailable" ? "unavailable-time" : ""}`}
                                                style={{ cursor: "pointer" }}
                                            >
                                                <div className="card-body">
                                                    <h6>{timeSlot}</h6>
                                                </div>
                                            </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </section>
                            {/* Display the confirmation button only when both date and time are selected */}
                            {selectedDate && selectedTimeSlot && (
                                <center>
                                    <section style={{ paddingTop: '30px' }}>
                                        <div className="container">
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={handleConfirm}
                                            >
                                                Confirm Appointment for {selectedDate} at {selectedTimeSlot}
                                            </button>
                                        </div>
                                    </section>
                                </center>
                            )}
                        </form>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default Appointment;
