import React, { useEffect,  useState} from "react";
import { Link, useNavigate } from "react-router-dom";
import foodlogo from '../assets/images/food_logo.gif';
import '../food/FoodHome.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import foodItemsData from './Data.json';



const FoodHome = ({ restaurantItems }) =>  {
    const navigate = useNavigate();

    useEffect(() => {
        const checkLoginStatus = () => {
            // Check if the user is not logged in
            const userId = sessionStorage.getItem('userId');
            const firstName = sessionStorage.getItem('firstName');

            if (!userId || !firstName) {
                // Redirect to the sign-in page
                navigate('/signin');
            }
        };

        // Check the login status on component mount
        checkLoginStatus();

        // Set up a timer to periodically check the login status
        const intervalId = setInterval(() => {
            checkLoginStatus();
        }, 500); // Refresh every 2 seconds

        // Clean up the timer when the component unmounts
        return () => clearInterval(intervalId);
    }, [navigate]);

     // Settings for react-slick slider
     const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const items = [
        {
            id: 1,
            name: "Biryani",
            image: require('../assets/images/food/Biryani.jpg'),
        },
        {
            id: 2,
            name: "North Indian",
            image: require('../assets/images/food/North Indian.jpg'),
        },

        {
            id: 3,
            name: "South Indian",
            image: require('../assets/images/food/South Indian.jpg'),
        },
        {
            id: 4,
            name: "Pizza",
            image: require('../assets/images/food/Pizza.jpg'),
        },
        {
            id: 5,
            name: "Burger",
            image: require('../assets/images/food/Burger.jpg'),
        },
        {
            id: 6,
            name: "Chinese",
            image: require('../assets/images/food/Chinese.jpg'),
        },
        {
            id: 7,
            name: "Rolls",
            image: require('../assets/images/food/Rolls.jpg'),
        },
        {
            id: 8,
            name: "Idli",
            image: require('../assets/images/food/Idli.jpg'),
        },
        {
            id: 9,
            name: "Salad",
            image: require('../assets/images/food/Salad.jpg'),
        },{
            id: 10,
            name: "Sharwarma",
            image: require('../assets/images/food/Sharwarma.jpg'),
        },{
            id: 11,
            name: "Dosa",
            image: require('../assets/images/food/Dosa.jpg'),
        },{
            id: 12,
            name: "Cakes",
            image: require('../assets/images/food/Cakes.jpg'),
        },
        {
            id: 13,
            name: "Paratha",
            image: require('../assets/images/food/Paratha.jpg'),
        },
        {
            id: 14,
            name: "Noodles",
            image: require('../assets/images/food/Noodles.jpg'),
        },

        {
            id: 15,
            name: "Khichdi",
            image: require('../assets/images/food/Khichdi.jpg'),
        },
        {
            id: 16,
            name: "Pure Veg",
            image: require('../assets/images/food/Pure Veg.jpg'),
        },
        {
            id: 17,
            name: "Ice Cream",
            image: require('../assets/images/food/Ice Cream.jpg'),
        },
        {
            id: 18,
            name: "Pastry",
            image: require('../assets/images/food/Pastry.jpg'),
        },
        {
            id: 19,
            name: "Kebabs",
            image: require('../assets/images/food/Kebabs.jpg'),
        },
        {
            id: 20,
            name: "Chole Bhature",
            image: require('../assets/images/food/Chole Bhature.jpg'),
        },
    ];

    const restaurant_items = [
        {
            id: 1,
            name: "Theobroma",
            rating: 4.5,
            charges:38,
            reviews:"5.5K+",
            distance: "2.4 km",
            time: "15-20 mins",
            location: "Gachibowlli",
            avilable:"Desserts",
            image: require('../assets/images/food/restaurant/Theobroma.jpg'),
            category:"Non-Veg",
            types: [
                {
                    name: "Desserts",
                    items: [
                        {
                            name: "Chocolate Truffle Cake",
                            image: require('../assets/images/food/desserts/Chocolate_Truffle_Cake.jpg'),
                            price: 250,
                            description: "Rich chocolate cake with layers of truffle",
                            category:"Veg"
                        },
                        {
                            name: "Red Velvet Cupcake",
                            image: require('../assets/images/food/desserts/Red_Velvet_Cupcake.jpg'),
                            price: 150,
                            description: "Moist red velvet cupcake topped with cream cheese frosting",
                            category:"Veg"
                        },
                        {
                            name: "New York Cheesecake",
                            image: require('../assets/images/food/desserts/New_York_Cheesecake.jpg'),
                            price: 180,
                            description: "Creamy cheesecake with a graham cracker crust",
                            category:"Veg"
                        },
                        {
                            name: "Tiramisu",
                            image: require('../assets/images/food/desserts/Tiramisu.jpg'),
                            price: 200,
                            description: "Classic Italian dessert with layers of coffee-soaked ladyfingers and mascarpone cream",
                            category:"Veg"
                        }
                    ]
                }
            ]
        },
        {
            id: 2,
            name: "Burger King",
            rating: 4,
            charges:48,
            reviews:"5.5K+",
            distance: "1.8 km",
            time: "35-40 mins",
            location: "Forest Dept Colony",
            avilable:"Burgers, American",
            image: require('../assets/images/food/restaurant/Burger King.jpg'),
            category:"Non-Veg",
            types: [
                {
                    name: "Burgers",
                    items: [
                        {
                            name: "Whopper",
                            image: require('../assets/images/food/burgers/Whopper.jpg'),
                            price: 180,
                            description: "Classic flame-grilled beef burger",
                            category:"Non-Veg",

                        },
                        {
                            name: "Veggie Burger",
                            image: require('../assets/images/food/burgers/Veggie_Burger.jpg'),
                            price: 150,
                            description: "Plant-based burger patty with lettuce, tomato, and mayo",
                            category:"Veg"
                        },
                        {
                            name: "Cheeseburger",
                            image: require('../assets/images/food/burgers/Cheeseburger.jpg'),
                            price: 170,
                            description: "Juicy beef patty topped with melted cheese",
                            category:"Non-Veg"
                        },
                        {
                            name: "Chicken Burger",
                            image: require('../assets/images/food/burgers/Chicken_Burger.jpg'),
                            price: 200,
                            description: "Crispy chicken patty with lettuce, tomato, and mayo",
                            category:"Non-Veg"
                        }
                    ]
                },
                {
                    name: "American",
                    items: [
                        {
                            name: "French Fries",
                            image: require('../assets/images/food/american/French_Fries.jpg'),
                            price: 100,
                            description: "Crispy and golden potato fries",
                            category:"Veg"
                        },
                        {
                            name: "Onion Rings",
                            image: require('../assets/images/food/american/Onion_Rings.jpg'),
                            price: 120,
                            description: "Battered and deep-fried onion rings",
                            category:"Veg"
                        },
                        {
                            name: "Chicken Nuggets",
                            image: require('../assets/images/food/american/Chicken_Nuggets.jpg'),
                            price: 160,
                            description: "Breaded and fried chicken pieces",
                            category:"Non-Veg"
                        },
                        {
                            name: "Hot Dog",
                            image: require('../assets/images/food/american/Hot_Dog.jpg'),
                            price: 140,
                            description: "Grilled sausage served in a bun with condiments",
                            category:"Non-Veg"
                        }
                    ]
                }
            ]
        },

        {
            id: 3,
            name: "Pizza Hut",
            rating: 3.9,
            charges:30,
            reviews:"5.5K+",
            distance: "1.8 km",
            time: "25-30 mins",
            location: "Gachibowlli",
            avilable: "Pizzas",
            image: require('../assets/images/food/restaurant/Pizza Hut.jpg'),
            category:"Veg",
        },
        {
            id: 4,
            name: "Starbucks Coffee",
            rating: 4.3,
            charges:35,
            reviews:"5.5K+",
            distance: "1.8 km",
            time: "25-30 mins",
            location: "Gachibowlli",
            avilable: "Beverages, Cafe, Snacks, Desserts",
            image: require('../assets/images/food/restaurant/Starbucks Coffee.jpg'),
            category:"Non-Veg",
        },
        {
            id: 5,
            name: "McDonald's",
            rating: 4.2,
            charges:48,
            reviews:"5.5K+",
            distance: "1.8 km",
            time: "25-30 mins",
            location: "Forest Dept Colony",
            avilable: "Burgers, Beverages, Cafe, Desserts",
            image: require('../assets/images/food/restaurant/McDonalds.jpg'),
            category:"Veg",
        },
        {
            id: 6,
            name: "KFC",
            rating: 4.1,
            charges:38,
            reviews:"5.5K+",
            distance: "1.8 km",
            time: "20-35 mins",
            location: "Bal Schander",
            avilable: "Burgers, Biryani, American, Snacks",
            image: require('../assets/images/food/restaurant/KFC.jpg'),
            category:"Non-Veg",
        },
        {
            id: 7,
            name: "Gappe Vappe By Almond House",
            rating: 4,
            charges:38,
            reviews:"5.5K+",
            distance: "1.8 km",
            time: "30-35 mins",
            location: "Gachibowli",
            avilable: "Ice Cream",
            image: require('../assets/images/food/restaurant/Gappe Vappe By Almond House.jpg'),
            category:"Non-Veg",
        },
        {
            id: 8,
            name: "Srikanya",
            rating: 4.1,
            charges:38,
            reviews:"5.5K+",
            distance: "1.8 km",
            time: "25-30 mins",
            location: "Hanuman Nagar",
            avilable: "Biryani, Andhra",
            image: require('../assets/images/food/restaurant/Srikanya.jpg'),
            category:"Veg",
        },
        {
            id: 9,
            name: "Subway",
            rating: 4.1,
            charges:38,
            reviews:"5.5K+",
            distance: "1.8 km",
            time: "25-30 mins",
            location: "Nanakramguda",
            avilable: "Salad, Snacks, Desserts, Beverages",
            image: require('../assets/images/food/restaurant/Subway.jpg'),
            category:"Veg",
        },{
            id: 10,
            name: "Kodi Kura Chitti Gaare",
            rating: 4.2,
            charges:38,
            reviews:"5.5K+",
            distance: "1.8 km",
            time: "25-30 mins",
            location: "Hitech City",
            avilable: "Biryani, Andhra, Chinese",
            image: require('../assets/images/food/restaurant/Kodi Kura Chitti Gaare.jpg'),
            category:"Veg",
        },{
            id: 11,
            name: "Harley's Fine Baking",
            rating: 4.6,
            charges:38,
            reviews:"5.5K+",
            distance: "1.8 km",
            time: "25-30 mins",
            location: "Nanakramguda",
            avilable: "Bakery, Desserts",
            image: require('../assets/images/food/restaurant/Harleys Fine Baking.jpg'),
            category:"Veg",
        },{
            id: 12,
            name: "Euphoria",
            rating: 4.7,
            charges:38,
            reviews:"5.5K+",
            distance: "1.8 km",
            time: "15-20 mins",
            location: "Gachibowli",
            avilable: "Bakery, Desserts",
            image: require('../assets/images/food/restaurant/Euphoria.jpg'),
            category:"Non-Veg",
        },
        {
            id: 13,
            name: "China Bistro",
            rating: 4.3,
            charges:38,
            reviews:"5.5K+",
            distance: "1.8 km",
            time: "35-40 mins",
            location: "Kondapur",
            avilable: "Chinese, Asian, Pan-Asian",
            image: require('../assets/images/food/restaurant/China Bistro.jpg'),
            category:"Non-Veg",
        },
        {
            id: 14,
            name: "Soul Rasa",
            rating: 4.3,
            charges:38,
            reviews:"5.5K+",
            distance: "1.8 km",
            time: "25-30 mins",
            location: "Kondapur",
            avilable: "Indian, Healthy Food, Home Food",
            image: require('../assets/images/food/restaurant/Soul Rasa.jpg'),
            category:"Veg",
        },

        {
            id: 15,
            name: "Keshav Reddy Sweets",
            rating: 4.3,
            charges:38,
            reviews:"5.5K+",
            distance: "1.8 km",
            time: "25-30 mins",
            location: "Gachibowli",
            avilable: "Sweets, Bakery, Desserts",
            image: require('../assets/images/food/restaurant/Keshav Reddy Sweets.jpg'),
            category:"Veg",
        },
        {
            id: 16,
            name: "Wow! Momo",
            rating: 4.1,
            charges:38,
            reviews:"5.5K+",
            distance: "1.8 km",
            time: "20-25 mins",
            location: "Gachibowli",
            avilable: "Tibetan, Healthy Food",
            image: require('../assets/images/food/restaurant/WowMomo.jpg'),
            category:"Non-Veg",
        },
        {
            id: 17,
            name: "Agra Sweets Banjara",
            rating: 4.2,
            charges:38,
            reviews:"5.5K+",
            distance: "1.8 km",
            time: "20-25 mins",
            location: "Gachibowli",
            avilable: "Sweets, Desserts, Chaat",
            image: require('../assets/images/food/restaurant/Agra Sweets Banjara.jpg'),
            category:"Veg",
        },
        {
            id: 18,
            name: "Tossin Pizza",
            rating: 4,
            charges:38,
            reviews:"5.5K+",
            distance: "1.8 km",
            time: "25-35 mins",
            location: "Gachibowli",
            avilable: "Pizzas, Italian, Beverages, Desserts",
            image: require('../assets/images/food/restaurant/Tossin Pizza.jpg'),
            category:"Veg",
        },
        {
            id: 19,
            name: "Chinese Pavilion",
            rating: 4.1,
            charges:38,
            reviews:"5.5K+",
            distance: "1.8 km",
            time: "30-35 mins",
            location: "Gachibowli",
            avilable: "Chinese, Asian",
            image: require('../assets/images/food/restaurant/Chinese Pavilion.jpg'),
            category:"Non-Veg",
        },
        {
            id: 20,
            name: "Barbeque Nation",
            rating: 3.6,
            charges:38,
            reviews:"5.5K+",
            distance: "1.8 km",
            time: "25-30 mins",
            location: "Nanakaramaguda",
            avilable: "North Indian, Barbecue, Biryani",
            image: require('../assets/images/food/restaurant/Barbeque Nation.jpg'),
            category:"Veg",
        },
    ];

    const responsive = {
        0: { items: 1 },
        568: { items: 3 },
        1024: { items: 6 },
    };
    const responsive_restaurant = {
        0: { items: 1 },
        568: { items: 2 },
        1024: { items: 4 },
    };
    
    const renderCarouselItems = () => {
        const groupedItems = [];
    
        for (let i = 0; i < items.length; i += 1) {
            const item1 = items[i];
    
            groupedItems.push(
                <div className="item" key={i}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="team-member-card">
                                <Link className="food_items">
                                    <img src={item1.image} alt={item1.name} className="team-member-avatar_1" />
                                    <h6 style={{color:'#626262'}} className="mb-0">{item1.name}</h6>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    
        return groupedItems;
    };

    const renderCarouselItems_restaurant = () => {
        // Shuffle the restaurant_items array
        const shuffledItems = [...restaurant_items];
        for (let i = 10 - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffledItems[i], shuffledItems[j]] = [shuffledItems[j], shuffledItems[i]];
        }
    
        const groupedItems_restaurant = [];
    
        for (let i = 0; i < 10; i += 1) {
            const item1 = shuffledItems[i];
    
            groupedItems_restaurant.push(
                <div className="item" key={i}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="team-member-card">
                                <Link className="restaurant_items">
                                    <img src={item1.image} alt={item1.name} className="team-member-avatar_2" />
                                    <h6 style={{ color: '#414449', textAlign: 'center', paddingBottom: '5px' }} className="mb-0">{item1.name}</h6>
                                    <h6 style={{ color: '#414449', textAlign: 'center', fontSize: '14px', paddingBottom: '5px' }} className="mb-0">
                                        <img src={require('../assets/images/food/restaurant/star.png')} alt="star" width="14px" className="blog-post-thumbnail mr-md-1" />
                                        <span style={{ display: 'inline-block', marginLeft: '5px' }}>{item1.rating}</span>
                                        <span style={{ marginLeft: '10px' }}><img src={require('../assets/images/food/restaurant/time.png')} alt="star" width="14px" className="blog-post-thumbnail mr-md-1" />{item1.time}</span>
                                    </h6>
                                    <h6 style={{ fontWeight: '300', color: '#414449', textAlign: 'center', paddingBottom: '5px' }} className="mb-0">{item1.avilable}</h6>
                                    <h6 style={{ fontWeight: '300', color: '#414449', textAlign: 'center', paddingBottom: '5px' }} className="mb-0">{item1.location}</h6>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    
        return groupedItems_restaurant;
    };

    // Custom render for the AliceCarousel buttons
    const renderPrevButton = ({ isDisabled }) => (
        <button
            disabled={isDisabled}
            className="btn btn-food active alice-carousel-btn alice-carousel-prev-btn"
            style={{ position: 'absolute', top: 30, left: -20, zIndex:'9999' }}
        >
            &lt;
        </button>
    );

    const renderNextButton = ({ isDisabled }) => (
        <button
            disabled={isDisabled}
            className="btn btn-food active alice-carousel-btn alice-carousel-next-btn"
            style={{ position: 'absolute', top: 30, right: -20, zIndex:'9999' }}
        >
            &gt;
        </button>
    );

    // Custom render for the AliceCarousel buttons
    const renderPrevButton_1 = ({ isDisabled }) => (
        <button
            disabled={isDisabled}
            className="btn btn-food active alice-carousel-btn alice-carousel-prev-btn"
            style={{ position: 'absolute', top: 100, left: -20, zIndex:'9999' }}
        >
            &lt;
        </button>
    );

    const renderNextButton_1 = ({ isDisabled }) => (
        <button
            disabled={isDisabled}
            className="btn btn-food active alice-carousel-btn alice-carousel-next-btn"
            style={{ position: 'absolute', top: 100, right: -20, zIndex:'9999' }}
        >
            &gt;
        </button>
    );

    const [selectedCategory, setSelectedCategory] = useState(null);

    // Extract unique categories from restaurant_items
    const categories = ['All', ...new Set(restaurant_items.map(item => item.category))];

    const handleCategoryChange = (category) => {
        setSelectedCategory(category === 'All' ? null : category);
    };

    const filteredItems = selectedCategory ? restaurant_items.filter(item => item.category === selectedCategory) : restaurant_items;

    // Function to store restaurant details in session and redirect to details page
    const handleRestaurantClick = (restaurant) => {
        // Store restaurant details in session
        sessionStorage.setItem('selectedRestaurant', JSON.stringify(restaurant));
        // Redirect to details page
        navigate(`/restaurantDetails/${restaurant.id}`);
    };

    
    return (
        <section>
            <header className="foi-header landing-header bg_header" style={{paddingTop:'80px'}}>
                <div className="container-fluid ">
                    <nav className="navbar navbar-expand-lg navbar-light foi-navbar" style={{padding:'10px'}}>
                        <Link className="navbar-brand" to={'/foodHome'}>
                            <img src={foodlogo} alt="Vasudha Connect" width="70px"/>
                        </Link>
                        <button className="navbar-toggler d-lg-none" type="button" data-toggle="collapse" data-target="#collapsibleNavId_1" aria-controls="collapsibleNavId_1" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="collapsibleNavId_1">
                            <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
                                <li className="nav-item">
                                    <Link className="d-flex align-items-center">
                                        <img src={require('../assets/images/logistic.png')} alt="Location" width="30px" style={{ marginRight: '5px' }} />
                                        <span style={{ color: '#6206a8', textDecoration:'underline', paddingTop:'10px', fontWeight:'700' }}>Gachibowli</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link style={{color:'#000'}} className="nav-link" to={'/services'}>Offers</Link>
                                </li>
                                <li className="nav-item">
                                    <Link style={{color:'#000'}} className="nav-link" to={'/features'}>Help</Link>
                                </li>
                            </ul>
                            <ul className="navbar-nav mt-2 mt-lg-0">
                                <li className="nav-item">
                                    <form className="form-inline my-2 my-lg-0">
                                        <input className="form-control mr-sm-2" type="search" placeholder="Search for restaurant, cuisine or a dish" aria-label="Search"/>
                                        <button className="btn btn-outline-primary my-2 my-sm-0" type="submit">Search</button>
                                    </form>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </header>
            {/* Slider section */}
            <section style={{paddingTop:'30px'}}>
                <div className="container">
                    <Slider {...settings}>
                        <div>
                            <img src={require('../assets/images/food_1.png')} alt="Slack" width="1000" className="img-fluid mx-auto d-block"/>
                        </div>
                        <div>
                            <img src={require('../assets/images/food_2.png')} alt="Slack" width="1000" className="img-fluid mx-auto d-block"/>
                        </div>
                        <div>
                            <img src={require('../assets/images/food_3.png')} alt="Slack" width="1000" className="img-fluid mx-auto d-block"/>
                        </div>
                        <div>
                            <img src={require('../assets/images/food_4.png')} alt="Slack" width="1000" className="img-fluid mx-auto d-block"/>
                        </div>
                    </Slider>
                </div>
            </section>
            {/* AliceCarousel section */}
            <section style={{paddingTop:'50px',paddingBottom:'50px'}}>
                <div className="container" style={{ position: 'relative', borderBottom:'3px solid #f0f0f5' }}>
                {/* AliceCarousel section */}
                    <h2>What's on your mind?</h2>
                    <p className="text-muted mb-5">Savor the excellence of our food service, where delectable flavors meet impeccable service for an unparalleled dining experience.</p>
                    <AliceCarousel mouseTracking items={renderCarouselItems()} responsive={responsive} renderPrevButton={renderPrevButton} renderNextButton={renderNextButton} disableDotsControls={true} />
                </div>
            </section>
            {/* AliceCarousel section */}
            <section style={{paddingTop:'50px',paddingBottom:'50px'}}>
                <div className="container" style={{ position: 'relative', borderBottom:'3px solid #f0f0f5' }}>
                {/* AliceCarousel section */}
                    <h2>Top restaurant chains in Hyderabad</h2>
                    <p className="text-muted mb-5">Hyderabad boasts a diverse culinary landscape with a myriad of restaurant chains that cater to various palates. From traditional Hyderabadi delicacies to global cuisines, these top restaurant chains in the city offer a delightful dining experience, blending flavors, ambiance, and hospitality to create memorable moments for patrons.</p>
                    <AliceCarousel mouseTracking items={renderCarouselItems_restaurant()} responsive={responsive_restaurant} renderPrevButton={renderPrevButton_1} renderNextButton={renderNextButton_1} disableDotsControls={true} />
                </div>
            </section>
            <section className="page-about container">
                <div className="container">
                    <h5>Restaurants with online food delivery in Hyderabad</h5>
                    <section className="foi-page-section mb-5 container">
                        <div className="category-filter">
                            {categories.map((category, index) => (
                                <button key={index} type="button" style={{marginRight:'10px', marginBottom:'10px'}} className={`btn ${selectedCategory === category ? 'btn-food-active' : 'btn-food'}`} onClick={() => handleCategoryChange(category)}>
                                    {category}
                                </button>
                            ))}
                        </div>
                        <div className="item container">
                            <div className="row">
                                {filteredItems.map(item => (
                                    <div className="col-md-4 col-lg-3" key={item.id}>
                                        <div className="team-member-card">
                                        <Link
                                            to={{
                                                pathname: `/restaurantDetails/${item.id}`,
                                                state: { restaurant: item, types: item.types } // Pass the restaurant object and its types as state
                                            }}
                                            onClick={() => {
                                                // Store restaurant details and types in session storage
                                                sessionStorage.setItem('selectedRestaurant', JSON.stringify(item));
                                                sessionStorage.setItem('selectedRestaurantTypes', JSON.stringify(item.types));
                                            }}
                                            className="restaurant_items"
                                        >
                                                <img src={item.image} alt={item.name} className="team-member-avatar_2" />
                                                <h6 style={{ color: '#414449', textAlign: 'center', paddingBottom: '5px' }} className="mb-0">{item.name}</h6>
                                                <h6 style={{ color: '#414449', textAlign: 'center', fontSize: '14px', paddingBottom: '5px' }} className="mb-0">
                                                    <img src={require('../assets/images/food/restaurant/star.png')} alt="star" width="14px" className="blog-post-thumbnail mr-md-1" />
                                                    <span style={{ display: 'inline-block', marginLeft: '5px' }}>{item.rating}</span>
                                                    <span style={{ marginLeft: '10px' }}><img src={require('../assets/images/food/restaurant/time.png')} alt="star" width="14px" className="blog-post-thumbnail mr-md-1" />{item.time}</span>
                                                </h6>
                                                <h6 style={{ fontWeight: '300', color: '#414449', textAlign: 'center', paddingBottom: '5px' }} className="mb-0">{item.avilable}</h6>
                                                <h6 style={{ fontWeight: '300', color: '#414449', textAlign: 'center', paddingBottom: '5px' }} className="mb-0">{item.location}</h6>
                                            </Link>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>
                </div>
            </section>
        </section>
    );
}

export default FoodHome;
