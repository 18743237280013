import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import { API_URL } from '../../constants';
import '../../external';

const About = () => {
    

    return (
        <main class="page-about">
            <div class="container" style={{paddingTop:'50px'}}>
                <section class="page-header">
                    <h1>About</h1>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb foi-breadcrumb">
                            <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                            <li class="breadcrumb-item active" aria-current="page">About</li>
                        </ol>
                    </nav>
                </section>
                <section class="foi-page-section pt-0">
                    <div class="row">
                        <div class="col-md-6 mb-4 mb-md-0 pr-lg-0">
                            <h2 class="about-section-one-title">
                                With more apps than iOS, Android puts <span class="font-weight-normal">a world of options at your fingertips.</span>
                            </h2>
                            <div class="about-section-one-content">
                                <p style={{textAlign:'justify'}}>Explore the boundless possibilities with Android, where a vast ecosystem of apps awaits to cater to your every need.</p>

                                <p style={{textAlign:'justify'}}> With an extensive library surpassing iOS, Android empowers you to customize your digital experience, offering a world of options right at your fingertips.</p>
                                
                                <p style={{textAlign:'justify'}} class="mb-0">Whether it's productivity, entertainment, or innovation, Android opens the door to a diverse and dynamic app landscape, ensuring you have the tools and choices to shape your mobile journey.</p>
                            </div>
                        </div>
                        <div class="col-md-6 pl-lg-0 d-flex align-items-center align-items-lg-end">
                            <img src={require('../assets/images/about_img_1.png')} alt="about" class="img-fluid" width="448px"/>
                        </div>
                    </div>
                </section>
                <section class="foi-page-section">
                    <div class="row">
                        <div class="col-md-6 mb-5 mb-md-0">
                            <img src={require('../assets/images/about_img_2.png')} alt="about 2" class="w-100 img-fluid" width="437px"/>
                        </div>
                        <div class="col-md-6">
                            <h2 class="about-section-two-title">We create the best <span class="font-weight-normal">solution for you and your business.</span></h2>
                            <div class="about-section-two-content">
                                <p style={{textAlign:'justify'}}>In our relentless pursuit of excellence, we have crafted an unparalleled solution designed exclusively for you and your business needs. By leveraging cutting-edge technologies and innovative strategies, we ensure that our solution stands out as the epitome of efficiency and effectiveness. </p>
                                <p style={{textAlign:'justify'}}>Backed by a team of industry experts, we pride ourselves on delivering a tailored approach that not only addresses your current challenges but also propels your business towards unprecedented success. Trust us to be your strategic partner in navigating the complexities of the modern business landscape, as we are committed to providing the best-in-class solution that sets you apart from the competition.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="foi-page-section mb-5">
                    <h5 class="text-center font-os mb-0">The amazing team</h5>
                    <h2 class="team-section-title">Our Creative Minds</h2>
                    <div class="row">
                        <div class="col-md-3 mb-5">
                            <div class="team-member-card">
                                <img src={require('../assets/img/man.png')} alt="Cardarion Hart" class="team-member-avatar"/>
                                <h6 class="mb-0">Pavan Gali</h6>
                                <p class="team-member-designation">CEO</p>
                            </div>
                        </div>
                        <div class="col-md-3 mb-5">
                            <div class="team-member-card">
                                <img src={require('../assets/img/woman.png')} alt="Gauthier Drewitt" class="team-member-avatar"/>
                                <h6 class="mb-0">Swarna Latha</h6>
                                <p class="team-member-designation">Co-Director</p>
                            </div>
                        </div>
                        <div class="col-md-3 mb-5">
                            <div class="team-member-card">
                                <img src={require('../assets/img/woman.png')} alt="Jontray Arnold" class="team-member-avatar"/>
                                <h6 class="mb-0">Sravanthi</h6>
                                <p class="team-member-designation">Co-Director</p>
                            </div>
                        </div>
                        <div class="col-md-3 mb-5">
                            <div class="team-member-card">
                                <img src={require('../assets/img/woman.png')} alt="Onyama Limba" class="team-member-avatar"/>
                                <h6 class="mb-0">Haritha</h6>
                                <p class="team-member-designation">HR Manager</p>
                            </div>
                        </div>
                        <div class="col-md-3 mb-5">
                            <div class="team-member-card">
                                <img src={require('../assets/img/man.png')} alt="Labeeba Al Amer" class="team-member-avatar"/>
                                <h6 class="mb-0">Lingeshwar</h6>
                                <p class="team-member-designation">Assosiate Software Engineer</p>
                            </div>
                        </div>
                        <div class="col-md-3 mb-5">
                            <div class="team-member-card">
                                <img src={require('../assets/img/man.png')} alt="Athar Malakooti" class="team-member-avatar"/>
                                <h6 class="mb-0">Shishir</h6>
                                <p class="team-member-designation">Assosiate Software Engineer</p>
                            </div>
                        </div>
                        <div class="col-md-3 mb-5">
                            <div class="team-member-card">
                                <img src={require('../assets/img/man.png')} alt="Kung Jiyeon" class="team-member-avatar"/>
                                <h6 class="mb-0">Sagar</h6>
                                <p class="team-member-designation">Assosiate Software Engineer</p>
                            </div>
                        </div>
                        <div class="col-md-3 mb-5">
                            <div class="team-member-card">
                                <img src={require('../assets/img/man.png')} alt="Mathijn Agter" class="team-member-avatar"/>
                                <h6 class="mb-0">Sri Krishna</h6>
                                <p class="team-member-designation">Assosiate Software Engineer</p>
                            </div>
                        </div>
                        
                    </div>
                </section>
            </div>
        </main>
    );
}

export default About;
