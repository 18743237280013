import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Confirmation = () => {
    const navigate = useNavigate();

    // State to manage form inputs
    const [relationship, setRelationship] = useState("");
    const [gender, setGender] = useState("");
    const [patientName, setPatientName] = useState("");
    const [patientDOB, setPatientDOB] = useState("");

    // Retrieve selected date and time from session storage
    const selectedDate = sessionStorage.getItem("selectedAppointmentDate");
    const selectedTimeSlot = sessionStorage.getItem("selectedAppointmentTime");

    // Retrieve doctor details from session
    const doctorDetails = sessionStorage.getItem("selectedDoctorDetails");
    const selectedDoctorDetails = doctorDetails
        ? JSON.parse(doctorDetails)
        : null;
    // Retrieve label name from session
    const labelName = sessionStorage.getItem("selectedCardData");
    const selectedCardData = labelName ? JSON.parse(labelName) : null;

    useEffect(() => {
        const checkLoginStatus = () => {
            // Check if the user is not logged in
            const userId = sessionStorage.getItem("userId");
            const firstName = sessionStorage.getItem("firstName");
            const phone = sessionStorage.getItem("phone");

            if (!userId || !firstName) {
                // Redirect to the sign-in page
                navigate("/signin");
            }
        };

        // Check the login status on component mount
        checkLoginStatus();

        // Set up a timer to periodically check the login status
        const intervalId = setInterval(() => {
            checkLoginStatus();
        }, 500); // Refresh every 2 seconds

        // Clean up the timer when the component unmounts
        return () => clearInterval(intervalId);
    }, [navigate]);

    const handleSubmit = (e) => {
        e.preventDefault();

        // Store form data in session
        sessionStorage.setItem("relationship", relationship);
        sessionStorage.setItem("gender", gender);
        sessionStorage.setItem("patientname", patientName);
        sessionStorage.setItem("patientdob", patientDOB);

        // Redirect to the next page
        navigate("/consultationfee");
    };

    return (
        <main className="page-blog-post" style={{ paddingTop: "30px" }}>
            <div className="container" style={{paddingTop:'100px'}}>
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <section className="blog-post-author-info-section">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h5 className="blog-post-author-info-section-title">
                                        Booking Details
                                    </h5>
                                    <div className="blog-post-author-info media">
                                        <img src={selectedDoctorDetails.image} alt={`Doctor ${selectedDoctorDetails.doctor}`} className="align-self-sm-center blog-post-author-avatar"/>
                                        <div className="media-body">
                                            <div className="d-flex flex-wrap align-items-center">
                                                <h6 className="mr-3 mb-0">{selectedDoctorDetails.doctor}</h6>
                                                <p class="blog-post-date mr-3 mb-0">{selectedCardData ? selectedCardData.name: "New"}</p>
                                            </div>
                                            <p className="author-description">
                                                <img className="blog-post-excerpt" src={require("../assets/img/location.png")} alt="blog" width="18px" />{" "} {selectedDoctorDetails.location}
                                            </p>
                                            <div className="d-flex flex-wrap align-items-center">
                                                <p class="blog-post-date mr-3 mb-0">{selectedDate} {selectedTimeSlot}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="container">
                                <h3>Whom are you booking for?</h3>
                                <section class="contact-form-wrapper">
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Select Relationship{" "}<sup>*</sup></label>
                                                    <select id="relation" className="form-control" required value={relationship} onChange={(e) =>setRelationship(e.target.value)}>
                                                        <option value="">Select</option>
                                                        <option value="self">Self</option>
                                                        <option value="other">Other</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Gender <sup>*</sup></label>
                                                    <select id="gender" className="form-control" required value={gender} onChange={(e) => setGender(e.target.value)}>
                                                        <option value="">Select Gender</option>
                                                        <option value="male">Male</option>
                                                        <option value="female">Female</option>
                                                        <option value="prefer not to say">Prefer Not to Say</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="form-group col-md-6">
                                                <label>Enter Patient Name{" "}<sup>*</sup></label>
                                                <input type="text" class="form-control" id="name" name="patientname" placeholder="Enter Patient Name *" required value={patientName} onChange={(e) =>setPatientName(e.target.value)}/>
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label>Date of Birth <sup>*</sup></label>
                                                <input type="date" class="form-control" id="dob" name="patientdob" placeholder="Date of Birth" required value={patientDOB} onChange={(e) =>setPatientDOB(e.target.value)}/>
                                            </div>
                                        </div>
                                        <h3>You will be contacted on</h3>
                                        <div class="row">
                                            <div class="form-group col-md-6">
                                                <label>Mobile <sup>*</sup></label>
                                                <input type="number" class="form-control" id="phone" name="phone" placeholder="Mobile *" value={sessionStorage.getItem("phone")} readOnly/>
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label>Email <sup>*</sup></label>
                                                <input type="email" class="form-control" id="email" name="email" placeholder="Email" value={sessionStorage.getItem("email")} readOnly/>
                                            </div>
                                        </div>
                                        <div class="row text-center">
                                            <div class="form-group col-md-6 d-flex align-items-center justify-content-center">
                                                <h6>Total Price: {selectedDoctorDetails.consultationFee}</h6>
                                            </div>
                                            <div class="form-group col-md-6 d-flex flex-column align-items-center justify-content-center">
                                                <button type="submit" class="btn btn-primary mb-4">Proceed</button>
                                                <p class="form-footer-text">We'll get back to you.</p>
                                            </div>
                                        </div>
                                    </form>
                                </section>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default Confirmation;