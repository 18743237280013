import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import '../../external';


const Features = () => {
    const usenavigate = useNavigate();
    const [customerlist, listupdate] = useState(null);
   
   

    return (
        <main>
        <div class="container" style={{paddingTop:'50px'}}>
            <section class="page-header">
                <h2>Amazing Features</h2>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb foi-breadcrumb">
                        <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Features</li>
                    </ol>
                </nav>
            </section>
            <section class="foi-page-section">
                <div class="row pt-5">
                        <div class="col-md-4 foi-feature">
                            <img src={require('../assets/img/secure.gif')} alt="icon" width="100px" />
                            <h5 class="feature-title">Secure Data</h5>
                            <p class="feature-content">Ensure data integrity and confidentiality with our reliable solutions, offering a fortified defense against potential threats and vulnerabilities to keep your information secure.</p>
                        </div>
                        <div class="col-md-4 foi-feature">
                            <img src={require('../assets/img/qa.gif')} alt="icon" width="100px" />
                            <h5 class="feature-title">Live Chat</h5>
                            <p class="feature-content">Experience real-time customer engagement with our Live Chat service, providing instant support and fostering meaningful connections to enhance user satisfaction.</p>
                        </div>
                        <div class="col-md-4 foi-feature">
                            <img src={require('../assets/img/payment.gif')} alt="icon" width="100px" />
                            <h5 class="feature-title">Online Payment</h5>
                            <p class="feature-content">Simplify transactions with our secure online payment services, ensuring seamless and hassle-free financial transactions for your business and customers.</p>
                        </div>
                        <div class="col-md-4 foi-feature">
                            <img src={require('../assets/img/work.gif')} alt="icon" width="100px" />
                            <h5 class="feature-title">Fully functional</h5>
                            <p class="feature-content">Tailored support: Our services are customized to meet your specific needs, ensuring a seamless and effective experience for every client</p>
                        </div>
                        <div class="col-md-4 foi-feature">
                            <img src={require('../assets/img/dashboard.gif')} alt="icon" width="100px" />
                            <h5 class="feature-title">Powerful dashboard</h5>
                            <p class="feature-content">Access real-time insights effortlessly with our powerful dashboard, providing a centralized hub for data visualization and strategic decision-making.</p>
                        </div>
                        <div class="col-md-4 foi-feature">
                            <img src={require('../assets/img/features.gif')} alt="icon" width="100px" />
                            <h5 class="feature-title">Unlimited Features</h5>
                            <p class="feature-content">Experience the freedom of limitless possibilities with our services, offering an array of unlimited features designed to enhance your user experience and meet diverse needs.</p>
                        </div>
                </div>
            </section>
        </div>
    </main>
    );
}

export default Features;