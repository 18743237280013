import React, { useState } from "react";

const PatientAddressDetailsForm = ({ onClose, onSave }) => {
    const [Name, setPatientName] = useState("");
    const [Mail, setPatientMail] = useState("");
    const [Mobile, setPatientMobile] = useState("");
    const [Pincode, setPatientPincode] = useState("");
    const [Locality, setPatientLocality] = useState("");
    const [AddressType, setAddressType] = useState("");
    const [Address, setAddress] = useState("");
    const [state, setState] = useState("");

    const [formErrors, setFormErrors] = useState({
        Name: "",
        Mail: "",
        Mobile: "",
        Pincode: "",
        Locality: "",
        AddressType: "",
        Address: "",
        state: "",
      });

  const handleSave = () => {
    // Basic validations
    const errors = {};
    if (!Name) errors.Name = "Name is required.";
    if (!Mail) errors.Mail = "Mail is required.";
    if (!Mobile) errors.Mobile = "Mobile is required.";
    if (!Pincode) errors.Pincode = "Pincode is required.";
    if (!Locality) errors.Locality = "Locality is required.";
    if (!AddressType) errors.AddressType = "Please select an Address Type.";
    if (!Address) errors.Address = "Address is required.";
    if (!state) errors.state = "Please select a State.";

    setFormErrors(errors);

    // Check if there are any errors
    if (Object.values(errors).some((error) => error !== "")) {
      return;
    }

    // Validate other conditions if needed

    const details = {
      Name,
      Mail,
      Mobile,
      Locality,
      AddressType,
      Address,
      state,
    };

    onSave(details);
  };

  return (
    <main>
      <div class="container">
        <section class="contact-form-wrapper">
          <form>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <select id="AddressType" name="AddressType" className="form-control" style={{ borderRadius: "10px" }} onChange={(e) => setAddressType(e.target.value)}>
                    <option value="Select" selected>Select Type</option>
                    <option value="Home" >Home</option>
                    <option value="Work">Work</option>
                    <option value="Other">Other</option>
                  </select>
                  <div className="invalid-feedback">{formErrors.AddressType}</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-6">
                <input style={{ borderRadius: "10px" }} type="text" class="form-control" id="name" name="Name" placeholder="Name *" value={Name} onChange={(e) => setPatientName(e.target.value)} required/>
              </div>
              <div class="form-group col-md-6">
                <input style={{ borderRadius: "10px" }} type="number" class="form-control" id="mobile" name="Mobile" placeholder="Mobile *" value={Mobile} onChange={(e) => setPatientMobile(e.target.value)} required/>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-12">
                <input style={{ borderRadius: "10px" }} type="mail" class="form-control" id="mail" name="Mail" placeholder="Mail *" value={Mail} onChange={(e) => setPatientMail(e.target.value)} required/>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-6">
                <input style={{ borderRadius: "10px" }} type="number" class="form-control" id="pincode" name="Pincode" placeholder="Pincode *" value={Pincode} onChange={(e) => setPatientPincode(e.target.value)} required/>
              </div>
              <div class="form-group col-md-6">
                <input style={{ borderRadius: "10px" }} type="text" class="form-control" id="locality" name="Locality" placeholder="Locality *" value={Locality} onChange={(e) => setPatientLocality(e.target.value)} required/>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-12">
                <textarea style={{ borderRadius: "10px" }} type="text" rows="7" class="form-control" id="Address" name="Address" placeholder="Address *" value={Address} onChange={(e) => setAddress(e.target.value)} required/>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <select style={{ borderRadius: "10px" }} id="state" className="form-control" onChange={(e) => setState(e.target.value)}>
                    <option value="State" selected>Select State</option>
                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                    <option value="Assam">Assam</option>
                    <option value="Bihar">Bihar</option>
                    <option value="Chhattisgarh">Chhattisgarh</option>
                    <option value="Goa">Goa</option>
                    <option value="Gujarat">Gujarat</option>
                    <option value="Haryana">Haryana</option>
                    <option value="Himachal Pradesh">Himachal Pradesh</option>
                    <option value="Jharkhand">Jharkhand</option>
                    <option value="Karnataka">Karnataka</option>
                    <option value="Kerala">Kerala</option>
                    <option value="Madhya Pradesh">Madhya Pradesh</option>
                    <option value="Maharashtra">Maharashtra</option>
                    <option value="Manipur">Manipur</option>
                    <option value="Meghalaya">Meghalaya</option>
                    <option value="Mizoram">Mizoram</option>
                    <option value="Nagaland">Nagaland</option>
                    <option value="Odisha">Odisha</option>
                    <option value="Punjab">Punjab</option>
                    <option value="Rajasthan">Rajasthan</option>
                    <option value="Sikkim">Sikkim</option>
                    <option value="Tamil NaduTN">Tamil Nadu</option>
                    <option value="Telangana">Telangana</option>
                    <option value="Tripura">Tripura</option>
                    <option value="Uttarakhand">Uttarakhand</option>
                    <option value="Uttar Pradesh">Uttar Pradesh</option>
                    <option value="West Bengal">West Bengal</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-6">
                <button className="btn btn-primary btn-rounded" onClick={onClose}>
                  Cancel
                </button>
              </div>
              <div class="form-group col-md-6">
                <button className="btn btn-primary btn-rounded" onClick={handleSave}>
                  Add Address
                </button>
              </div>
            </div>
          </form>
        </section>
      </div>
    </main>
  );
};

export default PatientAddressDetailsForm;
