    import React, { useEffect, useState } from "react";
    import { useNavigate } from "react-router-dom";

    const Labhome = () => {
        const navigate = useNavigate();
        const [selectedFilter, setSelectedFilter] = useState("all");

        useEffect(() => {
            const checkLoginStatus = () => {
                const userId = sessionStorage.getItem('userId');
                const firstName = sessionStorage.getItem('firstName');

                if (!userId || !firstName) {
                    navigate('/signin');
                }
            };

            checkLoginStatus();

            const intervalId = setInterval(() => {
                checkLoginStatus();
            }, 500);

            return () => clearInterval(intervalId);
        }, [navigate]);

        const checkupPackages = [
            {
                name: "Popular",
                title: "Preventions Checkup",
                price: 800.00,
                details: "Avoiding certain medicines and/or supplements. Be sure to talk to your provider about what you are currently taking,including over-the-counter medicines, vitamins, and supplements.",
                tests: [
                    "Iron, Urea, Uric Acid - Serum",
                    "Vitamin D & B12..."
                ],
                number:88,
                prerequisites:"An early morning specimen is preferred, Minimum 8 - 10 hours Fasting is mandatory Plain Water is acceptable",
                availableAt: "Home",
                testnames: [
                    {
                        title:"Complete Blood Count (CBC with P.Smear - EDTA Whole Blood - 25 Tests",
                        1:"Absolute Basophils Count",
                        2:"Absolute Eosinophil Count (AEC) - EDTA Whole Blood",
                        3:"Absolute Lymphocytes Count",
                        4:"Absolute Monocytes Count",
                        5:"Absolute Neutrophils Count",
                        6:"Basophils",
                        7:"Eosinophils",
                        8:"Hemoglobin - EDTA Whole Blood",
                        9:"Lymphocytes",
                        10:"MCH",
                        11:"MCHC",
                        12:"MCV",
                        13:"Mixed Cells",
                        14:"Monocytes",
                        15:"MPV",
                        16:"Neutrophils",
                        17:"P-LCR",
                        18:"P/S Examination",
                        19:"PCT",
                        20:"PDW",
                        21:"Platelet Count - EDTA Whole Blood",
                        22:"R.B.C Count",
                        23:"RDW-CV",
                        24:"RDW-SD",
                        25:"Total WBC Count (TLC)",
                    },
                    {
                        title:"Complete Urine Examination (CUE) - Spot Urine - 20 Tests",
                        1:"Absolute Basophils Count",
                        2:"Absolute Eosinophil Count (AEC) - EDTA Whole Blood",
                        3:"Absolute Lymphocytes Count",
                        4:"Absolute Monocytes Count",
                        5:"Absolute Neutrophils Count",
                        6:"Basophils",
                        7:"Eosinophils",
                        8:"Hemoglobin - EDTA Whole Blood",
                        9:"Lymphocytes",
                        10:"MCH",
                        11:"MCHC",
                        12:"MCV",
                        13:"Mixed Cells",
                        14:"Monocytes",
                        15:"MPV",
                        16:"Neutrophils",
                        17:"P-LCR",
                        18:"P/S Examination",
                        19:"PCT",
                        20:"PDW",
                    },
                    {
                        title:"Liver Function Test (LFT) - Serum - 12 Tests",
                        1:"Absolute Basophils Count",
                        2:"Absolute Eosinophil Count (AEC) - EDTA Whole Blood",
                        3:"Absolute Lymphocytes Count",
                        4:"Absolute Monocytes Count",
                        5:"Absolute Neutrophils Count",
                        6:"Basophils",
                        7:"Eosinophils",
                        8:"Hemoglobin - EDTA Whole Blood",
                        9:"Lymphocytes",
                        10:"MCH",
                        11:"MCHC",
                        12:"MCV",
                    }, 
                    {
                        title:"Thyroid Profile (T3,T4 & TSH) - Serum - 3 Tests",
                        1:"Absolute Basophils Count",
                        2:"Absolute Eosinophil Count (AEC) - EDTA Whole Blood",
                        3:"Absolute Lymphocytes Count",
                    },
                    {
                        title:"Glycosylated Hemoglobin (HBA1c) -EDTA Whole Blood - 2 Tests",
                        1:"Absolute Basophils Count",
                        2:"Absolute Eosinophil Count (AEC) - EDTA Whole Blood",
                    },
                    {
                        title:"Lipid Profile - Serum - 9 Tests",
                        1:"Absolute Basophils Count",
                        2:"Absolute Eosinophil Count (AEC) - EDTA Whole Blood",
                        3:"Absolute Lymphocytes Count",
                        4:"Absolute Monocytes Count",
                        5:"Absolute Neutrophils Count",
                        6:"Basophils",
                        7:"Eosinophils",
                        8:"Hemoglobin - EDTA Whole Blood",
                        9:"Lymphocytes",
                    }, 
                    {
                        title:"Others - 17 Tests",
                        1:"Absolute Basophils Count",
                        2:"Absolute Eosinophil Count (AEC) - EDTA Whole Blood",
                        3:"Absolute Lymphocytes Count",
                        4:"Absolute Monocytes Count",
                        5:"Absolute Neutrophils Count",
                        6:"Basophils",
                        7:"Eosinophils",
                        8:"Hemoglobin - EDTA Whole Blood",
                        9:"Lymphocytes",
                        10:"MCH",
                        11:"MCHC",
                        12:"MCV",
                        13:"Mixed Cells",
                        14:"Monocytes",
                        15:"MPV",
                        16:"Neutrophils",
                        17:"P-LCR",
                    },
                ],
            },
            {
                name: "Popular",
                title: "Basic Health Checkup",
                price: 1200.00,
                details:"Our comprehensive health check-up lab test package aims to provide you with crucial insights into your overall health and well-being. This package includes a Complete Blood Count (CBC) to assess various blood components, a Complete Urine Examination (CUE) to evaluate urinary health, and a Kidney Function Test (KFT) to determine the proper functioning of your kidneys. By booking this health check, you can proactively monitor your vital indicators, identify potential health issues, and take necessary steps towards maintaining or improving your overall health.",
                tests: [
                    "Complete Blood Count",
                    "Kidney Function Test (KFT)..."
                ],
                number:59,
                prerequisites:"An early morning specimen is preferred, Minimum 8 - 10 hours Fasting is mandatory Plain Water is acceptable",
                availableAt: "Home",
                testnames: [
                    {
                        title:"Complete Blood Count (CBC with P.Smear - EDTA Whole Blood - 25 Tests",
                        1:"Absolute Basophils Count",
                        2:"Absolute Eosinophil Count (AEC) - EDTA Whole Blood",
                        3:"Absolute Lymphocytes Count",
                        4:"Absolute Monocytes Count",
                        5:"Absolute Neutrophils Count",
                        6:"Basophils",
                        7:"Eosinophils",
                        8:"Hemoglobin - EDTA Whole Blood",
                        9:"Lymphocytes",
                        10:"MCH",
                        11:"MCHC",
                        12:"MCV",
                        13:"Mixed Cells",
                        14:"Monocytes",
                        15:"MPV",
                        16:"Neutrophils",
                        17:"P-LCR",
                        18:"P/S Examination",
                        19:"PCT",
                        20:"PDW",
                        21:"Platelet Count - EDTA Whole Blood",
                        22:"R.B.C Count",
                        23:"RDW-CV",
                        24:"RDW-SD",
                        25:"Total WBC Count (TLC)",
                    },
                    {
                        title:"Complete Urine Examination (CUE) - Spot Urine - 20 Tests",
                        1:"Absolute Basophils Count",
                        2:"Absolute Eosinophil Count (AEC) - EDTA Whole Blood",
                        3:"Absolute Lymphocytes Count",
                        4:"Absolute Monocytes Count",
                        5:"Absolute Neutrophils Count",
                        6:"Basophils",
                        7:"Eosinophils",
                        8:"Hemoglobin - EDTA Whole Blood",
                        9:"Lymphocytes",
                        10:"MCH",
                        11:"MCHC",
                        12:"MCV",
                        13:"Mixed Cells",
                        14:"Monocytes",
                        15:"MPV",
                        16:"Neutrophils",
                        17:"P-LCR",
                        18:"P/S Examination",
                        19:"PCT",
                        20:"PDW",
                    },
                    {
                        title:"Liver Function Test (LFT) - Serum - 12 Tests",
                        1:"Absolute Basophils Count",
                        2:"Absolute Eosinophil Count (AEC) - EDTA Whole Blood",
                        3:"Absolute Lymphocytes Count",
                        4:"Absolute Monocytes Count",
                        5:"Absolute Neutrophils Count",
                        6:"Basophils",
                        7:"Eosinophils",
                        8:"Hemoglobin - EDTA Whole Blood",
                        9:"Lymphocytes",
                        10:"MCH",
                        11:"MCHC",
                        12:"MCV",
                    }, 
                    {
                        title:"Thyroid Profile (T3,T4 & TSH) - Serum - 3 Tests",
                        1:"Absolute Basophils Count",
                        2:"Absolute Eosinophil Count (AEC) - EDTA Whole Blood",
                        3:"Absolute Lymphocytes Count",
                    },
                    {
                        title:"Glycosylated Hemoglobin (HBA1c) -EDTA Whole Blood - 2 Tests",
                        1:"Absolute Basophils Count",
                        2:"Absolute Eosinophil Count (AEC) - EDTA Whole Blood",
                    },
                    {
                        title:"Lipid Profile - Serum - 9 Tests",
                        1:"Absolute Basophils Count",
                        2:"Absolute Eosinophil Count (AEC) - EDTA Whole Blood",
                        3:"Absolute Lymphocytes Count",
                        4:"Absolute Monocytes Count",
                        5:"Absolute Neutrophils Count",
                        6:"Basophils",
                        7:"Eosinophils",
                        8:"Hemoglobin - EDTA Whole Blood",
                        9:"Lymphocytes",
                    }, 
                    {
                        title:"Others - 17 Tests",
                        1:"Absolute Basophils Count",
                        2:"Absolute Eosinophil Count (AEC) - EDTA Whole Blood",
                        3:"Absolute Lymphocytes Count",
                        4:"Absolute Monocytes Count",
                        5:"Absolute Neutrophils Count",
                        6:"Basophils",
                        7:"Eosinophils",
                        8:"Hemoglobin - EDTA Whole Blood",
                        9:"Lymphocytes",
                        10:"MCH",
                        11:"MCHC",
                        12:"MCV",
                        13:"Mixed Cells",
                        14:"Monocytes",
                        15:"MPV",
                        16:"Neutrophils",
                        17:"P-LCR",
                    },
                ],
            },
            {
                name: "Popular",
                title: "Essential Checkup",
                price: 1800.00,
                details:"Avoiding certain medicines and/or supplements. Be sure to talk to your provider about what you are currently taking,including over-the-counter medicines, vitamins, and supplements.",
                tests: [
                    "Urine Examination",
                    "Kidney & Liver Function..."
                ],
                number:64,
                prerequisites:"An early morning specimen is preferred, Minimum 8 - 10 hours Fasting is mandatory Plain Water is acceptable",
                availableAt: "Home",
                testnames: [
                    {
                        title:"Complete Blood Count (CBC with P.Smear - EDTA Whole Blood - 25 Tests",
                        1:"Absolute Basophils Count",
                        2:"Absolute Eosinophil Count (AEC) - EDTA Whole Blood",
                        3:"Absolute Lymphocytes Count",
                        4:"Absolute Monocytes Count",
                        5:"Absolute Neutrophils Count",
                        6:"Basophils",
                        7:"Eosinophils",
                        8:"Hemoglobin - EDTA Whole Blood",
                        9:"Lymphocytes",
                        10:"MCH",
                        11:"MCHC",
                        12:"MCV",
                        13:"Mixed Cells",
                        14:"Monocytes",
                        15:"MPV",
                        16:"Neutrophils",
                        17:"P-LCR",
                        18:"P/S Examination",
                        19:"PCT",
                        20:"PDW",
                        21:"Platelet Count - EDTA Whole Blood",
                        22:"R.B.C Count",
                        23:"RDW-CV",
                        24:"RDW-SD",
                        25:"Total WBC Count (TLC)",
                    },
                    {
                        title:"Complete Urine Examination (CUE) - Spot Urine - 20 Tests",
                        1:"Absolute Basophils Count",
                        2:"Absolute Eosinophil Count (AEC) - EDTA Whole Blood",
                        3:"Absolute Lymphocytes Count",
                        4:"Absolute Monocytes Count",
                        5:"Absolute Neutrophils Count",
                        6:"Basophils",
                        7:"Eosinophils",
                        8:"Hemoglobin - EDTA Whole Blood",
                        9:"Lymphocytes",
                        10:"MCH",
                        11:"MCHC",
                        12:"MCV",
                        13:"Mixed Cells",
                        14:"Monocytes",
                        15:"MPV",
                        16:"Neutrophils",
                        17:"P-LCR",
                        18:"P/S Examination",
                        19:"PCT",
                        20:"PDW",
                    },
                    {
                        title:"Liver Function Test (LFT) - Serum - 12 Tests",
                        1:"Absolute Basophils Count",
                        2:"Absolute Eosinophil Count (AEC) - EDTA Whole Blood",
                        3:"Absolute Lymphocytes Count",
                        4:"Absolute Monocytes Count",
                        5:"Absolute Neutrophils Count",
                        6:"Basophils",
                        7:"Eosinophils",
                        8:"Hemoglobin - EDTA Whole Blood",
                        9:"Lymphocytes",
                        10:"MCH",
                        11:"MCHC",
                        12:"MCV",
                    }, 
                    {
                        title:"Thyroid Profile (T3,T4 & TSH) - Serum - 3 Tests",
                        1:"Absolute Basophils Count",
                        2:"Absolute Eosinophil Count (AEC) - EDTA Whole Blood",
                        3:"Absolute Lymphocytes Count",
                    },
                    {
                        title:"Glycosylated Hemoglobin (HBA1c) -EDTA Whole Blood - 2 Tests",
                        1:"Absolute Basophils Count",
                        2:"Absolute Eosinophil Count (AEC) - EDTA Whole Blood",
                    },
                    {
                        title:"Lipid Profile - Serum - 9 Tests",
                        1:"Absolute Basophils Count",
                        2:"Absolute Eosinophil Count (AEC) - EDTA Whole Blood",
                        3:"Absolute Lymphocytes Count",
                        4:"Absolute Monocytes Count",
                        5:"Absolute Neutrophils Count",
                        6:"Basophils",
                        7:"Eosinophils",
                        8:"Hemoglobin - EDTA Whole Blood",
                        9:"Lymphocytes",
                    }, 
                    {
                        title:"Others - 17 Tests",
                        1:"Absolute Basophils Count",
                        2:"Absolute Eosinophil Count (AEC) - EDTA Whole Blood",
                        3:"Absolute Lymphocytes Count",
                        4:"Absolute Monocytes Count",
                        5:"Absolute Neutrophils Count",
                        6:"Basophils",
                        7:"Eosinophils",
                        8:"Hemoglobin - EDTA Whole Blood",
                        9:"Lymphocytes",
                        10:"MCH",
                        11:"MCHC",
                        12:"MCV",
                        13:"Mixed Cells",
                        14:"Monocytes",
                        15:"MPV",
                        16:"Neutrophils",
                        17:"P-LCR",
                    },
                ],
            },
            {
                name: "Popular",
                title: "Vasudha Full Body Health Check Up",
                price: 1399.00,
                details:"Full body health check package which helps in the overall assessment of all the organs and systems of the body such as Heart, Liver, Kidneys, Thyroid, Urinary track infections, Diabetes, etc....",
                tests: [
                    "Thyroid & Lipid Profile",
                    "Kidney & Liver Function..."
                ],
                number:81,
                prerequisites:"An early morning specimen is preferred, Minimum 8 - 10 hours Fasting is mandatory Plain Water is acceptable",
                availableAt: "Home",
                testnames: [
                    {
                        title:"Complete Blood Count (CBC with P.Smear - EDTA Whole Blood - 25 Tests",
                        1:"Absolute Basophils Count",
                        2:"Absolute Eosinophil Count (AEC) - EDTA Whole Blood",
                        3:"Absolute Lymphocytes Count",
                        4:"Absolute Monocytes Count",
                        5:"Absolute Neutrophils Count",
                        6:"Basophils",
                        7:"Eosinophils",
                        8:"Hemoglobin - EDTA Whole Blood",
                        9:"Lymphocytes",
                        10:"MCH",
                        11:"MCHC",
                        12:"MCV",
                        13:"Mixed Cells",
                        14:"Monocytes",
                        15:"MPV",
                        16:"Neutrophils",
                        17:"P-LCR",
                        18:"P/S Examination",
                        19:"PCT",
                        20:"PDW",
                        21:"Platelet Count - EDTA Whole Blood",
                        22:"R.B.C Count",
                        23:"RDW-CV",
                        24:"RDW-SD",
                        25:"Total WBC Count (TLC)",
                    },
                    {
                        title:"Complete Urine Examination (CUE) - Spot Urine - 20 Tests",
                        1:"Absolute Basophils Count",
                        2:"Absolute Eosinophil Count (AEC) - EDTA Whole Blood",
                        3:"Absolute Lymphocytes Count",
                        4:"Absolute Monocytes Count",
                        5:"Absolute Neutrophils Count",
                        6:"Basophils",
                        7:"Eosinophils",
                        8:"Hemoglobin - EDTA Whole Blood",
                        9:"Lymphocytes",
                        10:"MCH",
                        11:"MCHC",
                        12:"MCV",
                        13:"Mixed Cells",
                        14:"Monocytes",
                        15:"MPV",
                        16:"Neutrophils",
                        17:"P-LCR",
                        18:"P/S Examination",
                        19:"PCT",
                        20:"PDW",
                    },
                    {
                        title:"Liver Function Test (LFT) - Serum - 12 Tests",
                        1:"Absolute Basophils Count",
                        2:"Absolute Eosinophil Count (AEC) - EDTA Whole Blood",
                        3:"Absolute Lymphocytes Count",
                        4:"Absolute Monocytes Count",
                        5:"Absolute Neutrophils Count",
                        6:"Basophils",
                        7:"Eosinophils",
                        8:"Hemoglobin - EDTA Whole Blood",
                        9:"Lymphocytes",
                        10:"MCH",
                        11:"MCHC",
                        12:"MCV",
                    }, 
                    {
                        title:"Thyroid Profile (T3,T4 & TSH) - Serum - 3 Tests",
                        1:"Absolute Basophils Count",
                        2:"Absolute Eosinophil Count (AEC) - EDTA Whole Blood",
                        3:"Absolute Lymphocytes Count",
                    },
                    {
                        title:"Glycosylated Hemoglobin (HBA1c) -EDTA Whole Blood - 2 Tests",
                        1:"Absolute Basophils Count",
                        2:"Absolute Eosinophil Count (AEC) - EDTA Whole Blood",
                    },
                    {
                        title:"Lipid Profile - Serum - 9 Tests",
                        1:"Absolute Basophils Count",
                        2:"Absolute Eosinophil Count (AEC) - EDTA Whole Blood",
                        3:"Absolute Lymphocytes Count",
                        4:"Absolute Monocytes Count",
                        5:"Absolute Neutrophils Count",
                        6:"Basophils",
                        7:"Eosinophils",
                        8:"Hemoglobin - EDTA Whole Blood",
                        9:"Lymphocytes",
                    }, 
                    {
                        title:"Others - 17 Tests",
                        1:"Absolute Basophils Count",
                        2:"Absolute Eosinophil Count (AEC) - EDTA Whole Blood",
                        3:"Absolute Lymphocytes Count",
                        4:"Absolute Monocytes Count",
                        5:"Absolute Neutrophils Count",
                        6:"Basophils",
                        7:"Eosinophils",
                        8:"Hemoglobin - EDTA Whole Blood",
                        9:"Lymphocytes",
                        10:"MCH",
                        11:"MCHC",
                        12:"MCV",
                        13:"Mixed Cells",
                        14:"Monocytes",
                        15:"MPV",
                        16:"Neutrophils",
                        17:"P-LCR",
                    },
                ],
            },
            {
                name: "Diabetes",
                title: "Vasudha Diabetes Screening",
                price: 1599.00,
                details:"Diabetes Screening which helps in the overall assessment of all the organs and systems of the body such as Heart, Liver, Kidneys, Urinary track infections, Diabetes etc.",
                tests: [
                    "Glucose Fasting (FBS)",
                    "Triglycerides - Serum..."
                ],
                number:54,
                prerequisites:"An early morning specimen is preferred, Minimum 8 - 10 hours Fasting is mandatory Plain Water is acceptable",
                availableAt: "Home",
                testnames: [
                    {
                        title:"Complete Blood Count (CBC with P.Smear - EDTA Whole Blood - 25 Tests",
                        1:"Absolute Basophils Count",
                        2:"Absolute Eosinophil Count (AEC) - EDTA Whole Blood",
                        3:"Absolute Lymphocytes Count",
                        4:"Absolute Monocytes Count",
                        5:"Absolute Neutrophils Count",
                        6:"Basophils",
                        7:"Eosinophils",
                        8:"Hemoglobin - EDTA Whole Blood",
                        9:"Lymphocytes",
                        10:"MCH",
                        11:"MCHC",
                        12:"MCV",
                        13:"Mixed Cells",
                        14:"Monocytes",
                        15:"MPV",
                        16:"Neutrophils",
                        17:"P-LCR",
                        18:"P/S Examination",
                        19:"PCT",
                        20:"PDW",
                        21:"Platelet Count - EDTA Whole Blood",
                        22:"R.B.C Count",
                        23:"RDW-CV",
                        24:"RDW-SD",
                        25:"Total WBC Count (TLC)",
                    },
                    {
                        title:"Complete Urine Examination (CUE) - Spot Urine - 20 Tests",
                        1:"Absolute Basophils Count",
                        2:"Absolute Eosinophil Count (AEC) - EDTA Whole Blood",
                        3:"Absolute Lymphocytes Count",
                        4:"Absolute Monocytes Count",
                        5:"Absolute Neutrophils Count",
                        6:"Basophils",
                        7:"Eosinophils",
                        8:"Hemoglobin - EDTA Whole Blood",
                        9:"Lymphocytes",
                        10:"MCH",
                        11:"MCHC",
                        12:"MCV",
                        13:"Mixed Cells",
                        14:"Monocytes",
                        15:"MPV",
                        16:"Neutrophils",
                        17:"P-LCR",
                        18:"P/S Examination",
                        19:"PCT",
                        20:"PDW",
                    },
                    {
                        title:"Liver Function Test (LFT) - Serum - 12 Tests",
                        1:"Absolute Basophils Count",
                        2:"Absolute Eosinophil Count (AEC) - EDTA Whole Blood",
                        3:"Absolute Lymphocytes Count",
                        4:"Absolute Monocytes Count",
                        5:"Absolute Neutrophils Count",
                        6:"Basophils",
                        7:"Eosinophils",
                        8:"Hemoglobin - EDTA Whole Blood",
                        9:"Lymphocytes",
                        10:"MCH",
                        11:"MCHC",
                        12:"MCV",
                    }, 
                    {
                        title:"Thyroid Profile (T3,T4 & TSH) - Serum - 3 Tests",
                        1:"Absolute Basophils Count",
                        2:"Absolute Eosinophil Count (AEC) - EDTA Whole Blood",
                        3:"Absolute Lymphocytes Count",
                    },
                    {
                        title:"Glycosylated Hemoglobin (HBA1c) -EDTA Whole Blood - 2 Tests",
                        1:"Absolute Basophils Count",
                        2:"Absolute Eosinophil Count (AEC) - EDTA Whole Blood",
                    },
                    {
                        title:"Lipid Profile - Serum - 9 Tests",
                        1:"Absolute Basophils Count",
                        2:"Absolute Eosinophil Count (AEC) - EDTA Whole Blood",
                        3:"Absolute Lymphocytes Count",
                        4:"Absolute Monocytes Count",
                        5:"Absolute Neutrophils Count",
                        6:"Basophils",
                        7:"Eosinophils",
                        8:"Hemoglobin - EDTA Whole Blood",
                        9:"Lymphocytes",
                    }, 
                    {
                        title:"Others - 17 Tests",
                        1:"Absolute Basophils Count",
                        2:"Absolute Eosinophil Count (AEC) - EDTA Whole Blood",
                        3:"Absolute Lymphocytes Count",
                        4:"Absolute Monocytes Count",
                        5:"Absolute Neutrophils Count",
                        6:"Basophils",
                        7:"Eosinophils",
                        8:"Hemoglobin - EDTA Whole Blood",
                        9:"Lymphocytes",
                        10:"MCH",
                        11:"MCHC",
                        12:"MCV",
                        13:"Mixed Cells",
                        14:"Monocytes",
                        15:"MPV",
                        16:"Neutrophils",
                        17:"P-LCR",
                    },
                ],
            },
            {
                name: "Heart",
                title: "Vasudha Cardiac Health Package With ECG - Basic",
                price: 1199.00,
                details:"Medibuddy Cardiac Health package with ECG which helps in the overall assessment of all the organs and systems of the body such as Heart, Liver, Kidneys, Diabetes etc....",
                tests: [
                    "Complete Bloo Count(CBC)",
                    "Glycosylated HemoGlobin..."
                ],
                number:52,
                prerequisites:"An early morning specimen is preferred, Minimum 8 - 10 hours Fasting is mandatory Plain Water is acceptable",
                availableAt: "Home",
                testnames: [
                    {
                        title:"Complete Blood Count (CBC with P.Smear - EDTA Whole Blood - 25 Tests",
                        1:"Absolute Basophils Count",
                        2:"Absolute Eosinophil Count (AEC) - EDTA Whole Blood",
                        3:"Absolute Lymphocytes Count",
                        4:"Absolute Monocytes Count",
                        5:"Absolute Neutrophils Count",
                        6:"Basophils",
                        7:"Eosinophils",
                        8:"Hemoglobin - EDTA Whole Blood",
                        9:"Lymphocytes",
                        10:"MCH",
                        11:"MCHC",
                        12:"MCV",
                        13:"Mixed Cells",
                        14:"Monocytes",
                        15:"MPV",
                        16:"Neutrophils",
                        17:"P-LCR",
                        18:"P/S Examination",
                        19:"PCT",
                        20:"PDW",
                        21:"Platelet Count - EDTA Whole Blood",
                        22:"R.B.C Count",
                        23:"RDW-CV",
                        24:"RDW-SD",
                        25:"Total WBC Count (TLC)",
                    },
                    {
                        title:"Complete Urine Examination (CUE) - Spot Urine - 20 Tests",
                        1:"Absolute Basophils Count",
                        2:"Absolute Eosinophil Count (AEC) - EDTA Whole Blood",
                        3:"Absolute Lymphocytes Count",
                        4:"Absolute Monocytes Count",
                        5:"Absolute Neutrophils Count",
                        6:"Basophils",
                        7:"Eosinophils",
                        8:"Hemoglobin - EDTA Whole Blood",
                        9:"Lymphocytes",
                        10:"MCH",
                        11:"MCHC",
                        12:"MCV",
                        13:"Mixed Cells",
                        14:"Monocytes",
                        15:"MPV",
                        16:"Neutrophils",
                        17:"P-LCR",
                        18:"P/S Examination",
                        19:"PCT",
                        20:"PDW",
                    },
                    {
                        title:"Liver Function Test (LFT) - Serum - 12 Tests",
                        1:"Absolute Basophils Count",
                        2:"Absolute Eosinophil Count (AEC) - EDTA Whole Blood",
                        3:"Absolute Lymphocytes Count",
                        4:"Absolute Monocytes Count",
                        5:"Absolute Neutrophils Count",
                        6:"Basophils",
                        7:"Eosinophils",
                        8:"Hemoglobin - EDTA Whole Blood",
                        9:"Lymphocytes",
                        10:"MCH",
                        11:"MCHC",
                        12:"MCV",
                    }, 
                    {
                        title:"Thyroid Profile (T3,T4 & TSH) - Serum - 3 Tests",
                        1:"Absolute Basophils Count",
                        2:"Absolute Eosinophil Count (AEC) - EDTA Whole Blood",
                        3:"Absolute Lymphocytes Count",
                    },
                    {
                        title:"Glycosylated Hemoglobin (HBA1c) -EDTA Whole Blood - 2 Tests",
                        1:"Absolute Basophils Count",
                        2:"Absolute Eosinophil Count (AEC) - EDTA Whole Blood",
                    },
                    {
                        title:"Lipid Profile - Serum - 9 Tests",
                        1:"Absolute Basophils Count",
                        2:"Absolute Eosinophil Count (AEC) - EDTA Whole Blood",
                        3:"Absolute Lymphocytes Count",
                        4:"Absolute Monocytes Count",
                        5:"Absolute Neutrophils Count",
                        6:"Basophils",
                        7:"Eosinophils",
                        8:"Hemoglobin - EDTA Whole Blood",
                        9:"Lymphocytes",
                    }, 
                    {
                        title:"Others - 17 Tests",
                        1:"Absolute Basophils Count",
                        2:"Absolute Eosinophil Count (AEC) - EDTA Whole Blood",
                        3:"Absolute Lymphocytes Count",
                        4:"Absolute Monocytes Count",
                        5:"Absolute Neutrophils Count",
                        6:"Basophils",
                        7:"Eosinophils",
                        8:"Hemoglobin - EDTA Whole Blood",
                        9:"Lymphocytes",
                        10:"MCH",
                        11:"MCHC",
                        12:"MCV",
                        13:"Mixed Cells",
                        14:"Monocytes",
                        15:"MPV",
                        16:"Neutrophils",
                        17:"P-LCR",
                    },
                ],
            },
            {
                name: "Heart",
                title: "Vasudha Cardiac Health Package With ECG - Comprehensive",
                price: 2199.00,
                details:"Medibuddy Cardiac comprehensive Health package with ECG which helps in the overall assessment of all the organs and systems of the body such as Heart, Liver, Kidneys, Diabetes etc....",
                tests: [
                    "Complete Bloo Count(CBC)",
                    "Glycosylated HemoGlobin..."
                ],
                number:58,
                prerequisites:"An early morning specimen is preferred, Minimum 8 - 10 hours Fasting is mandatory Plain Water is acceptable",
                availableAt: "Home",
                testnames: [
                    {
                        title:"Complete Blood Count (CBC with P.Smear - EDTA Whole Blood - 25 Tests",
                        1:"Absolute Basophils Count",
                        2:"Absolute Eosinophil Count (AEC) - EDTA Whole Blood",
                        3:"Absolute Lymphocytes Count",
                        4:"Absolute Monocytes Count",
                        5:"Absolute Neutrophils Count",
                        6:"Basophils",
                        7:"Eosinophils",
                        8:"Hemoglobin - EDTA Whole Blood",
                        9:"Lymphocytes",
                        10:"MCH",
                        11:"MCHC",
                        12:"MCV",
                        13:"Mixed Cells",
                        14:"Monocytes",
                        15:"MPV",
                        16:"Neutrophils",
                        17:"P-LCR",
                        18:"P/S Examination",
                        19:"PCT",
                        20:"PDW",
                        21:"Platelet Count - EDTA Whole Blood",
                        22:"R.B.C Count",
                        23:"RDW-CV",
                        24:"RDW-SD",
                        25:"Total WBC Count (TLC)",
                    },
                    {
                        title:"Complete Urine Examination (CUE) - Spot Urine - 20 Tests",
                        1:"Absolute Basophils Count",
                        2:"Absolute Eosinophil Count (AEC) - EDTA Whole Blood",
                        3:"Absolute Lymphocytes Count",
                        4:"Absolute Monocytes Count",
                        5:"Absolute Neutrophils Count",
                        6:"Basophils",
                        7:"Eosinophils",
                        8:"Hemoglobin - EDTA Whole Blood",
                        9:"Lymphocytes",
                        10:"MCH",
                        11:"MCHC",
                        12:"MCV",
                        13:"Mixed Cells",
                        14:"Monocytes",
                        15:"MPV",
                        16:"Neutrophils",
                        17:"P-LCR",
                        18:"P/S Examination",
                        19:"PCT",
                        20:"PDW",
                    },
                    {
                        title:"Liver Function Test (LFT) - Serum - 12 Tests",
                        1:"Absolute Basophils Count",
                        2:"Absolute Eosinophil Count (AEC) - EDTA Whole Blood",
                        3:"Absolute Lymphocytes Count",
                        4:"Absolute Monocytes Count",
                        5:"Absolute Neutrophils Count",
                        6:"Basophils",
                        7:"Eosinophils",
                        8:"Hemoglobin - EDTA Whole Blood",
                        9:"Lymphocytes",
                        10:"MCH",
                        11:"MCHC",
                        12:"MCV",
                    }, 
                    {
                        title:"Thyroid Profile (T3,T4 & TSH) - Serum - 3 Tests",
                        1:"Absolute Basophils Count",
                        2:"Absolute Eosinophil Count (AEC) - EDTA Whole Blood",
                        3:"Absolute Lymphocytes Count",
                    },
                    {
                        title:"Glycosylated Hemoglobin (HBA1c) -EDTA Whole Blood - 2 Tests",
                        1:"Absolute Basophils Count",
                        2:"Absolute Eosinophil Count (AEC) - EDTA Whole Blood",
                    },
                    {
                        title:"Lipid Profile - Serum - 9 Tests",
                        1:"Absolute Basophils Count",
                        2:"Absolute Eosinophil Count (AEC) - EDTA Whole Blood",
                        3:"Absolute Lymphocytes Count",
                        4:"Absolute Monocytes Count",
                        5:"Absolute Neutrophils Count",
                        6:"Basophils",
                        7:"Eosinophils",
                        8:"Hemoglobin - EDTA Whole Blood",
                        9:"Lymphocytes",
                    }, 
                    {
                        title:"Others - 17 Tests",
                        1:"Absolute Basophils Count",
                        2:"Absolute Eosinophil Count (AEC) - EDTA Whole Blood",
                        3:"Absolute Lymphocytes Count",
                        4:"Absolute Monocytes Count",
                        5:"Absolute Neutrophils Count",
                        6:"Basophils",
                        7:"Eosinophils",
                        8:"Hemoglobin - EDTA Whole Blood",
                        9:"Lymphocytes",
                        10:"MCH",
                        11:"MCHC",
                        12:"MCV",
                        13:"Mixed Cells",
                        14:"Monocytes",
                        15:"MPV",
                        16:"Neutrophils",
                        17:"P-LCR",
                    },
                ],
            }
        ];


        // Extract unique package names
        const uniquePackageNames = Array.from(new Set(checkupPackages.map(pkg => pkg.name)));

        const filteredPackages = selectedFilter === "all"
            ? checkupPackages
            : checkupPackages.filter(pkg => pkg.name === selectedFilter);

        const handleDetailsClick = (pkg) => {
            // Navigate to details page with package information as URL parameters
            navigate(`/details/${encodeURIComponent(pkg.name)}/${encodeURIComponent(pkg.title)}/${encodeURIComponent(pkg.details)}/${encodeURIComponent(pkg.price)}/${encodeURIComponent(pkg.prerequisites)}/${encodeURIComponent(pkg.number)}/${encodeURIComponent(JSON.stringify(pkg.testnames))}`);
        };
        return (
            <main className="page-about">
                <section style={{paddingTop:'100px'}}>
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div id="landingClientCarousel" class="carousel slide landing-client-carousel" data-ride="carousel">
                                    <div class="carousel-inner" role="listbox">
                                        <div class="carousel-item active">
                                            <div class="d-flex flex-wrap justify-content-center">
                                                <img style={{borderRadius:'20px'}} src={require('../assets/img/slide-1.png')} alt="Slack" width="1000" class="img-fluid"/>
                                            </div>
                                        </div>
                                        <div class="carousel-item">
                                            <div class="d-flex flex-wrap justify-content-center">
                                                <img style={{borderRadius:'20px'}} src={require('../assets/img/slide-2.png')} alt="Slack" width="1000" class="img-fluid"/>
                                            </div>
                                        </div>
                                        <div class="carousel-item">
                                            <div class="d-flex flex-wrap justify-content-center">
                                                <img style={{borderRadius:'20px'}} src={require('../assets/img/slide-3.png')} alt="Slack" width="1000" class="img-fluid"/>
                                            </div>
                                        </div>
                                        <div class="carousel-item">
                                            <div class="d-flex flex-wrap justify-content-center">
                                                <img style={{borderRadius:'20px'}} src={require('../assets/img/slide-4.png')} alt="Slack" width="1000" class="img-fluid"/>
                                            </div>
                                        </div>
                                    </div>
                                    <ol class="carousel-indicators">
                                        <li data-target="#landingClientCarousel" data-slide-to="0" class="active"></li>
                                        <li data-target="#landingClientCarousel" data-slide-to="1"></li>
                                        <li data-target="#landingClientCarousel" data-slide-to="2"></li>
                                        <li data-target="#landingClientCarousel" data-slide-to="3"></li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section style={{ paddingTop: '10px' }}>
                    <div className="container">
                        <div>
                            <h2>Book Health Check Up Packages</h2>
                            <p className="text-muted mb-5">Stay one step ahead by reducing the risk of diseases. Get your health check-up done now.</p>
                        </div>
                        <div className="mb-4">
                            {/* Dynamically generate buttons based on unique package names */}
                            <button style={{marginRight:'10px'}} className={`btn btn-secondary ${selectedFilter === "all" ? "active" : ""}`} onClick={() => setSelectedFilter("all")}>
                                All
                            </button>
                            {uniquePackageNames.map((packageName, index) => (
                                <button style={{marginRight:'10px'}} key={index} className={`btn btn-secondary ${selectedFilter === packageName ? "active" : ""}`} onClick={() => setSelectedFilter(packageName)}>
                                    {packageName}
                                </button>
                            ))}
                        </div>

                        {/* Display filtered packages */}
                        <div className="row">
                            {filteredPackages.map((pkg, index) => (
                                <div key={index} className="col-lg-4 mb-4">
                                    <div className="card pricing-card_2 active">
                                        <div className="card-body">
                                            <h6>{pkg.title}</h6>
                                            <h5 className="text-primary"><span style={{ fontFamily: 'sans-serif' }}>₹</span>{pkg.price.toFixed(2)}<span style={{ fontSize: '14px', color: '#000' }}> onwards</span></h5>
                                            <p>{pkg.title} with Smart Report</p>
                                            <textarea value={pkg.details} hidden></textarea>
                                            <p style={{ color: '#000',fontWeight:'800' }}>{pkg.number} Tests</p>
                                            <ul style={{ color: '#77838f', fontSize: '14px' }}>
                                                {pkg.tests.map((test, i) => (
                                                    <li key={i}>{test}</li>
                                                ))}
                                            </ul>
                                            <p>Available at: <img src={require('../assets/img/home.png')} alt="user" width="15px" className="avatar" /> {pkg.availableAt}</p>
                                            <button onClick={() => handleDetailsClick(pkg)} className="btn btn-primary btn-rounded">Details</button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </main>
        );
    }

    export default Labhome;
