import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";


const Home = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const checkLoginStatus = () => {
            // Check if the user is not logged in
            const userId = sessionStorage.getItem('userId');
            const firstName = sessionStorage.getItem('firstName');

            if (!userId || !firstName) {
                // Redirect to the sign-in page
                navigate('/signin');
            }
        };

        // Check the login status on component mount
        checkLoginStatus();

        // Set up a timer to periodically check the login status
        const intervalId = setInterval(() => {
            checkLoginStatus();
        }, 500); // Refresh every 2 seconds

        // Clean up the timer when the component unmounts
        return () => clearInterval(intervalId);
    }, [navigate]);

    return (
        
    <main class="page-about">
        <section style={{paddingTop:"100px"}}>
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div id="landingClientCarousel" class="carousel slide landing-client-carousel" data-ride="carousel">
                            <div class="carousel-inner" role="listbox">
                                <div class="carousel-item active">
                                    <div class="d-flex flex-wrap justify-content-center">
                                        <img style={{borderRadius:'20px'}} src={require('../assets/img/slide-1.png')} alt="Slack" width="1000" class="img-fluid"/>
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <div class="d-flex flex-wrap justify-content-center">
                                        <img style={{borderRadius:'20px'}} src={require('../assets/img/slide-2.png')} alt="Slack" width="1000" class="img-fluid"/>
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <div class="d-flex flex-wrap justify-content-center">
                                        <img style={{borderRadius:'20px'}} src={require('../assets/img/slide-3.png')} alt="Slack" width="1000" class="img-fluid"/>
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <div class="d-flex flex-wrap justify-content-center">
                                        <img style={{borderRadius:'20px'}} src={require('../assets/img/slide-4.png')} alt="Slack" width="1000" class="img-fluid"/>
                                    </div>
                                </div>
                            </div>
                            <ol class="carousel-indicators">
                                <li data-target="#landingClientCarousel" data-slide-to="0" class="active"></li>
                                <li data-target="#landingClientCarousel" data-slide-to="1"></li>
                                <li data-target="#landingClientCarousel" data-slide-to="2"></li>
                                <li data-target="#landingClientCarousel" data-slide-to="3"></li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section >
            <div className="container">
                <p className="text-muted mb-5">Experience unparalleled expertise and personalized solutions as we deliver top-notch services designed to meet your unique needs, ensuring a seamless and impactful journey towards success.</p>
                <div className="row">
                    <div className="col-lg-4 mb-4">
                        <div className="card pricing-card_1 active">
                            <div className="card-body">
                                <h6 className="mb-1">Book Dr. Appointment</h6>
                                <h3 className="mb-1 text-warning">
                                    <img src={require('../assets/img/healthcare.gif')} alt="user" width="80px" className="avatar"/>
                                </h3>
                                <Link className="btn btn-primary btn-rounded" to={'/category'}>Book Now</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-4">
                        <div className="card pricing-card_1 active">
                            <div className="card-body">
                                <h6 className="mb-1">Buy Medicines</h6>
                                <h3 className="text-primary">
                                    <img src={require('../assets/img/medicine.gif')} alt="user" width="80px" className="avatar"/>
                                </h3>
                                <Link className="btn btn-primary btn-rounded" to={'/medicinehome'}>Buy Now</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-4">
                        <div className="card pricing-card_1 active">
                            <div className="card-body">
                                <h6 className="mb-1">Lab Tests</h6>
                                <h3 className="text-primary">
                                    <img src={require('../assets/img/labtest.gif')} alt="user" width="80px" className="avatar"/>
                                </h3>
                                <Link to='/labhome' className="btn btn-primary btn-rounded">Get Started</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="container">
            <section class="foi-page-section">
                <h5 class="text-center font-os mb-0">Experts</h5>
                <h2 style={{textAlign:'center',marginBottom:'20px'}}>Consult For Women</h2>
                <div class="row">
                    <div class="col-md-2 mb-5">
                        <Link className="team-member-link">
                            <div class="team-member-card team-member-link">
                                <img src={require('../assets/img/gynecologist.png')} alt="Cardarion Hart" class="team-member-avatar"/>
                                <h6 style={{fontSize:'14px',color:'black'}} class="mb-0">Gynecologist</h6>
                            </div>
                        </Link>
                    </div>
                    <div class="col-md-2 mb-5">
                        <Link className="team-member-link">
                            <div class="team-member-card team-member-link">
                                <img src={require('../assets/img/Obstetrician.png')} alt="Cardarion Hart" class="team-member-avatar"/>
                                <h6 style={{fontSize:'14px',color:'black'}} class="mb-0">Obstetrician</h6>
                            </div>
                        </Link>
                    </div>
                    <div class="col-md-2 mb-5">
                        <Link className="team-member-link">
                            <div class="team-member-card team-member-link">
                                <img src={require('../assets/img/dermatologist.png')} alt="Cardarion Hart" class="team-member-avatar"/>
                                <h6 style={{fontSize:'14px',color:'black'}} class="mb-0">Dermatologist</h6>
                            </div>
                        </Link>
                    </div>
                    <div class="col-md-2 mb-5">
                        <Link className="team-member-link">
                            <div class="team-member-card team-member-link">
                                <img src={require('../assets/img/Ophthalmologist.png')} alt="Cardarion Hart" class="team-member-avatar"/>
                                <h6 style={{fontSize:'14px',color:'black'}} class="mb-0">Ophthalmologist</h6>
                            </div>
                        </Link>
                    </div>
                    <div class="col-md-2 mb-5">
                        <Link className="team-member-link">
                            <div class="team-member-card team-member-link">
                                <img src={require('../assets/img/Orthopedist.png')} alt="Cardarion Hart" class="team-member-avatar"/>
                                <h6 style={{fontSize:'14px',color:'black'}} class="mb-0">Orthopedist</h6>
                            </div>
                        </Link>
                    </div>
                    <div class="col-md-2 mb-5">
                        <Link className="team-member-link">
                            <div class="team-member-card team-member-link">
                                <img src={require('../assets/img/Breast Care.png')} alt="Cardarion Hart" class="team-member-avatar"/>
                                <h6 style={{fontSize:'14px',color:'black'}} class="mb-0">Breast Care</h6>
                            </div>
                        </Link>
                    </div>
                </div>
            </section>
        </div>
    </main>
    );
}

export default Home;
