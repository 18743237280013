import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import { API_URL } from '../../constants';
import '../../external'
import Registerimage from '../assets/images/Register.png'
const Signup = () => {
    const [firstName, firstNamechange] = useState("");
    const [lastName, lastNamechange] = useState("");
    const [email, emailchange] = useState("");
    const [phone, phonechange] = useState("");
    const [password, passwordchange] = useState("");
    const [dob, dobchange] = useState("");
    const [gender, genderchange] = useState("");

    const usenavigate = useNavigate();

    const IsValidate = () => {
        let isproceed = true;
        let errormessage = 'Please enter the value in ';
    
        if (firstName === null || firstName === '') {
            isproceed = false;
            errormessage += ' First Name';
        } else if (lastName === null || lastName === '') {
            isproceed = false;
            errormessage += ' Last Name';
        } else if (email === null || email === '') {
            isproceed = false;
            errormessage += ' Email';
        } else if (!/^[^@\s]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
            isproceed = false;
            errormessage = 'Please enter a valid email';
        } 
        else if (password === null || password === '') {
            isproceed = false;
            errormessage += ' password';
        }else if (phone === null || phone === '') {
            isproceed = false;
            errormessage += ' phone';
        }  else if (dob === null || dob === '') {
            isproceed = false;
            errormessage += ' dob';
        } else if (gender === null || gender === '') {
            isproceed = false;
            errormessage += ' gender';
        }
    
        if (!isproceed) {
            Swal.fire({
                icon: 'warning',
                title: 'Validation Error',
                text: errormessage
            });
        }
    
        return isproceed;
    };
    

    const handlesubmit = (e) => {
        e.preventDefault();
        const regObj = {
            firstName,
            lastName,
            email,
            phone,
            password,
            dob,
            gender,
        };

        if (IsValidate()) {
            fetch(`${API_URL}/register`, {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(regObj)
            })
                .then(response => response.json())
                .then(data => {
                    if (data.status === 200) {
                        // Extracting user data from the response
                        const userData = data.data;
                        const userId = userData._id;
                        const firstName = userData.firstName;

                        // Storing user information in session storage
                        sessionStorage.setItem('userId', userId);
                        sessionStorage.setItem('firstName', firstName);
                        Swal.fire({
                            icon: 'success',
                            title: 'Registered Successfully',
                            showConfirmButton: false,
                            timer: 1500
                        });
                        usenavigate('/dashboard');
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Registration Failed',
                            text: data.message
                        });
                    }
                })
                .catch((err) => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Registration Failed',
                        text: err.message
                    });
                });
        }
    };

    return (
        <main className="page-auth" style={{paddingTop:'50px'}}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <section className="auth-wrapper">
                            <div className="row">
                                <div className="col-md-6 mb-4 mb-md-0">
                                    <h2 className="auth-section-title">Create account</h2>
                                    <p className="auth-section-subtitle">Create your account to continue.</p>
                                    <form onSubmit={handlesubmit}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label >First Name <sup>*</sup></label>
                                                    <input value={firstName} onChange={e => firstNamechange(e.target.value)} type="text" className="form-control" id="firstName" name="firstName" placeholder="First Name" autoComplete="firstName"/>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label >Last Name <sup>*</sup></label>
                                                    <input value={lastName} onChange={e => lastNamechange(e.target.value)} type="text" className="form-control" id="lastName" name="lastName" placeholder="Last Name" autoComplete="lastName"/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label >Email <sup>*</sup></label>
                                            <input value={email} onChange={e => emailchange(e.target.value)} type="email" className="form-control" id="email" name="email" placeholder="Email" autoComplete="email"/>
                                        </div>

                                        <div className="form-group">
                                            <label >Password <sup>*</sup></label>
                                            <input value={password} onChange={e => passwordchange(e.target.value)} type="password" className="form-control" id="password" name="password" placeholder="Password" autoComplete="password"/>
                                        </div>

                                        <div className="form-group">
                                            <label >Phone Number <sup>*</sup></label>
                                            <input value={phone} onChange={e => phonechange(e.target.value)} type="number" className="form-control" id="phone" name="phone" placeholder="Phone Number"/>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label >Date of Birth <sup>*</sup></label>
                                                    <input value={dob} onChange={e => dobchange(e.target.value)} type="date" className="form-control" id="dob" name="dob" placeholder="Date of Birth"/>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                <label >Gender <sup>*</sup></label>
                                                    <select id="gender" value={gender} onChange={(e) => genderchange(e.target.value)} className="form-control">
                                                        <option value="" disabled>Select Gender</option>
                                                        <option value="male">Male</option>
                                                        <option value="female">Female</option>
                                                        <option value="prefer not to say">Prefer Not to Say</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>


                                        <button className="btn btn-primary btn-auth-submit" type="submit">Create account</button>
                                    </form>
                                    <p className="mb-0">
                                        <Link to={'/signin'} className="text-dark font-weight-bold">Already have an acocunt? Sign in</Link>
                                    </p>
                                </div>
                                <div className="col-md-6 d-flex align-items-center">
                                    <img src={Registerimage} alt="login" className="img-fluid"/>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </main>

    );
}

export default Signup;
