import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import '../../external'
import logo from '../assets/images/logo.gif'


const Appheader = () => {
    const usenavigate = useNavigate();
    const [isLoggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        // Check the user's login status on component mount
        const userId = sessionStorage.getItem('userId');
        setLoggedIn(!!userId); // Set isLoggedIn to true if userId is present

        // Set up a timer to periodically check the user's login status
        const intervalId = setInterval(() => {
            const updatedUserId = sessionStorage.getItem('userId');
            setLoggedIn(!!updatedUserId); // Update isLoggedIn based on the latest userId
        }, 500); // Refresh every 2 seconds

        // Clean up the timer when the component unmounts
        return () => clearInterval(intervalId);
    }, []);

    const handleLogout = () => {
        // Perform logout logic, e.g., clear session storage
        sessionStorage.clear();
        setLoggedIn(false);
        usenavigate('/signin');
    };

    return (
        <header className="foi-header landing-header bg_header">
            <div className="container-fluid bg_navbar">
                <nav className="navbar navbar-expand-lg navbar-light foi-navbar">
                    <Link className="navbar-brand">
                        <img src={logo} alt="Vasudha Connect" width="70px"/>
                    </Link>
                    <button className="navbar-toggler d-lg-none" type="button" data-toggle="collapse" data-target="#collapsibleNavId" aria-controls="collapsibleNavId" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="collapsibleNavId">
                        {isLoggedIn ? (
                            <>
                                <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
                                    <li className="nav-item">
                                        <Link className="nav-link" to={'/dashboard'}>Home</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to={'/services'}>Services</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to={'/features'}>Features</Link>
                                    </li>
                                    
                                    <li className="nav-item">
                                        <Link className="nav-link" to={'/contact'}>contact</Link>
                                    </li>
                                </ul>
                                <ul className="navbar-nav mt-2 mt-lg-0" >
                                    <li className="nav-item mr-2 mb-3 mb-lg-0">
                                        <Link className="nav-link" to={'/dashboard'}>
                                            {sessionStorage.getItem('userId') && (<li style={{color:"#fff",fontWeight:"800",fontSize:"24px",textTransform:"capitalize"}}>Welcome {sessionStorage.getItem('firstName')}!</li>)}
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link onClick={handleLogout} className="btn btn-secondary" to={'/signin'}>
                                            Sign Out
                                        </Link>
                                    </li>
                                </ul>
                            </>
                                
                            
                        ) : (
                            <>
                                <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
                                    <li className="nav-item">
                                        <Link className="nav-link"  to={'/'}>Home</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to={'/about'}>About</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to={'/features'}>Features</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to={'/services'}>Services</Link>
                                    </li>
                                    
                                    <li className="nav-item">
                                        <Link className="nav-link" to={'/contact'}>contact</Link>
                                    </li>
                                </ul>
                                <ul className="navbar-nav mt-2 mt-lg-0">
                                    <li className="nav-item mr-2 mb-3 mb-lg-0">
                                        <Link className="btn btn-secondary" to={'/signup'}>
                                            Sign Up
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="btn btn-secondary" to={'/signin'}>
                                            Sign In
                                        </Link>
                                    </li>
                                </ul>
                            </>
                        )}
                    </div>
                </nav>
            </div>
        </header>
    );
}

export default Appheader;
