import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import '../../external';


const Services = () => {
    const usenavigate = useNavigate();
    const [customerlist, listupdate] = useState(null);
   
   

    return (
        <section className="py-5 mb-5">
            <div className="container"  style={{paddingTop:'70px'}}>
                <h2>Our Services</h2>
                <p className="text-muted mb-5">Experience unparalleled expertise and personalized solutions as we deliver top-notch services designed to meet your unique needs, ensuring a seamless and impactful journey towards success.</p>
                <div className="row">
                    <div className="col-lg-4 mb-4">
                        <div className="card pricing-card active">
                            <div className="card-body">
                                <h3 className="mb-1">Travel</h3>
                                <h3 className="mb-1 text-warning">
                                    <img src={require('../assets/img/travel.gif')} alt="user" width="100px" className="avatar"/>
                                </h3>
                                <p className="mb-4">Embark on unforgettable journeys with our premium travel service, offering seamless experiences tailored to your wanderlust.</p>
                                <Link className="btn btn-primary btn-rounded" to={'/travel'}>Get Started</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-4">
                        <div className="card pricing-card active">
                            <div className="card-body">
                                <h3 className="mb-1">Health Care</h3>
                                <h3 className="text-primary">
                                    <img src={require('../assets/img/health.gif')} alt="user" width="100px" className="avatar"/>
                                </h3>
                                <p className="mb-4">Empowering wellness through compassionate and comprehensive healthcare services tailored to meet individual needs.</p>
                                <Link className="btn btn-primary btn-rounded" to={'/healthindex'}>Get Started</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-4">
                        <div className="card pricing-card active">
                            <div className="card-body">
                                <h3 className="mb-1">Shopping</h3>
                                <h3 className="text-primary">
                                    <img src={require('../assets/img/shopping.gif')} alt="user" width="100px" className="avatar"/>
                                </h3>
                                <p className="mb-4">Effortless shopping made delightful - our service brings convenience to your fingertips for a seamless and enjoyable retail experience.</p>
                                <button className="btn btn-primary btn-rounded">Get Started</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4 mb-4">
                        <div className="card pricing-card active">
                            <div className="card-body">
                                <h3 className="mb-1">Food</h3>
                                <h3 className="text-primary">
                                    <img src={require('../assets/img/food.gif')} alt="user" width="100px" className="avatar"/>
                                </h3>
                                <p className="mb-4">Savor the excellence of our food service, where delectable flavors meet impeccable service for an unparalleled dining experience</p>
                                <Link className="btn btn-primary btn-rounded" to={'/foodHome'}>Get Started</Link>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 mb-4">
                        <div className="card pricing-card active">
                            <div className="card-body">
                                <h3 className="mb-1">Entertainment</h3>
                                <h3 className="text-primary">
                                    <img src={require('../assets/img/entertainment.gif')} alt="user" width="100px" className="avatar"/>
                                </h3>
                                <p className="mb-4">Immerse yourself in endless entertainment with our captivating and diverse range of services, tailored to delight and inspire</p>
                                <Link to={'/homeEntertain'} className="btn btn-primary btn-rounded">Get Started</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-4">
                        <div className="card pricing-card active">
                            <div className="card-body">
                                <h3 className="mb-1">Education</h3>
                                <h3 className="mb-1 text-warning">
                                    <img src={require('../assets/img/education.gif')} alt="user" width="100px" className="avatar"/>
                                </h3>
                                <p className="mb-4">Empower minds with our education services, fostering a dynamic learning environment for academic excellence.</p>
                                <button className="btn btn-primary btn-rounded">Get Started</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Services;