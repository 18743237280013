// CityModal.js
import React from "react";
import { Modal } from "react-bootstrap";

const CityModal = ({ show, onHide, onSelectCity }) => {
    // Array of cities
    const cities = [
        { name: "Hyderabad", image: require('../assets/images/entertainment/cities/hyderabad.png') },
        { name: "Mumbai", image: require('../assets/images/entertainment/cities/mumbai.png') },
        { name: "Delhi", image: require('../assets/images/entertainment/cities/delhi.png') },
        { name: "Bangalore", image: require('../assets/images/entertainment/cities/bangalore.png') },
        { name: "Pune", image: require('../assets/images/entertainment/cities/pune.png') },
    ];

    // Function to handle city selection
    const handleCitySelect = (cityName) => {
        onSelectCity(cityName); // Call the function passed from the parent component
        onHide(); // Hide the modal
    };

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton={false}>
                <Modal.Title>Select City</Modal.Title>
                <button className="close" onClick={onHide}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </Modal.Header>
            <Modal.Body style={{ display: 'flex', flexWrap: 'wrap' }}>
                {cities.map((city, index) => (
                    <div
                        key={index}
                        style={{
                            marginRight: '30px',
                            marginBottom: '20px',
                            textAlign: 'center',
                            cursor: 'pointer',
                        }}
                        onClick={() => handleCitySelect(city.name)}
                    >
                        <img src={city.image} width="50px" alt={city.name} style={{ marginBottom: '10px' }} />
                        <div style={{ color: '#4b4b4b', textAlign: 'center' }}>{city.name}</div>
                    </div>
                ))}
            </Modal.Body>
        </Modal>
    );
};

export default CityModal;
