import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import PatientDetailsForm from "./PatientDetailsForm";
import ReactDOM from "react-dom";

const Testcart = () => {
  const usenavigate = useNavigate();
  const location = useLocation();
  const [cartItems, setCartItems] = useState(location.state?.cartItems || []);
  const [patientDetails, setPatientDetails] = useState(
    JSON.parse(localStorage.getItem("patientDetails")) || []
  );
  const [showAddPatientButton, setShowAddPatientButton] = useState(true); // State to control button visibility
  const [showAddAddressButton, setShowAddAddressButton] = useState(false); // State to control address button visibility

  const totalPrice = cartItems.reduce((total, item) => total + item.price, 0);

  useEffect(() => {
    // Check for patient details in localStorage when the component mounts
    const storedPatientDetails = JSON.parse(localStorage.getItem("patientDetails"));
    if (storedPatientDetails && storedPatientDetails.length > 0) {
      setPatientDetails(storedPatientDetails);
      setShowAddPatientButton(false);
      setShowAddAddressButton(true);
    }
  }, []); // Empty dependency array ensures this effect runs once when the component mounts

  useEffect(() => {
    // Save patientDetails to localStorage whenever it changes
    localStorage.setItem("patientDetails", JSON.stringify(patientDetails));
  }, [patientDetails]);

  const removeFromCart = (item) => {
    const updatedCart = cartItems.filter((cartItem) => cartItem.title !== item.title);
    setCartItems(updatedCart);
      // Check if the cart is empty after removing an item
      if (updatedCart.length === 0) {
        // If the cart is empty, reset patientDetails and hide the Add Address button
        setPatientDetails([]);
        setShowAddPatientButton(true);
        setShowAddAddressButton(false);
      }
    
  };

  const openAddressForm = () => {
    usenavigate('/testAddress', { state: { cartItems } })
  };

  const openPatientDetailsForm = () => {
    const container = document.createElement("div");
    document.body.appendChild(container);

    ReactDOM.render(
      <PatientDetailsForm onClose={closePatientDetailsForm} onSave={savePatientDetails} />,
      container
    );

    Swal.fire({
      title: "Add Patient Details",
      html: container,
      showConfirmButton: false,
      willClose: () => {
        ReactDOM.unmountComponentAtNode(container);
        container.remove();
      },
    });
  };

  const closePatientDetailsForm = () => {
    Swal.close();
  };

  const savePatientDetails = (details) => {
    setPatientDetails((prevDetails) => [...prevDetails, details]);
    Swal.fire("Success!", "Patient details saved successfully!", "success");
    closePatientDetailsForm();

    setShowAddPatientButton(false);
    setShowAddAddressButton(true);
  };


  if (cartItems.length === 0) {
    return (
      <main className="page-blog-post" style={{paddingTop:'50px'}}>
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <section className="blog-post-content">
                <div className="row">
                  <div className="col-lg-10 offset-lg-1">
                    <blockquote>
                      <p>Your Cart</p>
                      <p className="blog-post-author-info-section-title">No items in the cart</p>
                    </blockquote>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </main>
    );
  }
  return (
    <main className="page-blog-post">
      <div className="container" style={{ paddingTop: '120px' }}>
        <div className="row">
          <div className="col-12 col-md-3 mb-2">
            <p style={{ color: '#396ce8', fontWeight: '600' }}>
              <img src={require("../assets/img/radio-button.png")} width="25px" alt="monitor" /> Cart--------------------
            </p>
          </div>
          <div className="col-12 col-md-3 mb-2">
            <p style={{ color: '#bfbfbf', fontWeight: '600' }}>
              <img src={require("../assets/img/radio-button-inactive.png")} width="25px" alt="monitor" /> Address-----------------
            </p>
          </div>
          <div className="col-12 col-md-3 mb-2">
            <p style={{ color: '#bfbfbf', fontWeight: '600' }}>
              <img src={require("../assets/img/radio-button-inactive.png")} width="25px" alt="monitor" /> Date & Time--------------
            </p>
          </div>
         
          <div className="col-12 col-md-3">
            <p style={{ color: '#bfbfbf', fontWeight: '600' }}>
              <img src={require("../assets/img/radio-button-inactive.png")} width="25px" alt="monitor" /> Payment-----------------
            </p>
          </div>
        </div>
      </div>
  
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <section className="blog-post-content">
              <div className="row">
                <div className="col-lg-4">
                  <blockquote>
                    <p>Your Cart</p>
                    <h5 className="blog-post-author-info-section-title">{cartItems.length} Cart Items</h5>
                  </blockquote>
                </div>
                <div className="col-md-4">
                  <p></p>
                  <p
                    style={{
                      background: "#a9cbf2",
                      fontSize: "18px",
                      paddingLeft: "10px",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      borderRadius: "20px",
                    }}
                    className="blog-post-author-info-section-title"
                  >
                    <img src={require("../assets/img/home.png")} width="25px" alt="monitor" /> Home Sample Collection
                  </p>
                </div>
                <div className="col-md-4">
                  <p></p>
                  {showAddPatientButton && (
                    <button className="btn btn-primary btn-rounded" onClick={openPatientDetailsForm}>
                      Add Patient Details
                    </button>
                  )}
                  {showAddAddressButton && (
                    <button className="btn btn-primary btn-rounded"  onClick={openAddressForm}>
                      Continue
                    </button>
                  )}
                </div>
              </div>
            </section>
            <section className="blog-post-author-info-section">
              <div className="row">
                <div className="col-lg-7" style={{ border: '4px solid #d3d3d3', borderRadius: '20px', marginRight:'10px' }}>
                  <table className="table" >
                    <tbody>
                      {cartItems.map((item, index) => (
                        <tr key={index}>
                          <td  style={{paddingTop:'20px'}}>{item.title}</td>
                          <td  style={{paddingTop:'20px'}}><span style={{ fontFamily: 'sans-serif' }}>₹</span>{item.price.toFixed(2)}</td>
                          <td >
                            <button className="btn btn-danger btn-sm" onClick={() => removeFromCart(item)}>
                              Remove
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="col-lg-4" style={{ border: '4px solid #d3d3d3', borderRadius: '20px' }}>
                  <div className="blog-post-author-info_1 media mt-4">
                    <div className="media-body">
                      <div className="d-flex flex-wrap align-items-center">
                        <h6 className="mr-3 mb-0" style={{fontWeight:'500'}}>MRP Total</h6>
                        <p className="author-description ml-auto"><span style={{ fontFamily: 'sans-serif' }}>₹</span>{totalPrice.toFixed(2)}</p>
                      </div>
                      <div className="d-flex flex-wrap align-items-center">
                        <h6 className="mr-3 mb-0" style={{fontWeight:'800'}}>Total Amount</h6>
                        <p className="author-description ml-auto" style={{fontWeight:'800'}}><span style={{ fontFamily: 'sans-serif' }}>₹</span>{totalPrice.toFixed(2)}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {patientDetails.length > 0 && (
              <main className="page-blog-post" style={{ paddingTop: '20px' }}>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                      <section className="blog-post-author-info-section">
                        <div className="row">
                          <div className="col-lg-12">
                            <h5 className="blog-post-author-info-section-title">Patient Details</h5>
                            <div className="blog-post-author-info media">
                              <div className="media-body">
                                <div className="d-flex flex-wrap align-items-center">
                                  {patientDetails.map((patient, index) => (
                                    <p key={index} className="mr-3 mb-0">
                                      <strong>Patient Name:</strong> {patient.patientName},<br />
                                      <strong>Relationship:</strong>{" "} {patient.relationship},<br />
                                      <strong>DOB:</strong> {patient.dob},<br />
                                      <strong>Gender:</strong> {patient.gender}
                                    </p>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </main>
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default Testcart;
