import React, { useState } from "react";
import { Modal } from "react-bootstrap"; // Assuming you're using Bootstrap for modals

const FoodAddress = ({ show, handleClose, handleFormSubmit }) => {
    const [formData, setFormData] = useState({
        // Initialize your form fields here
        // For example:
        name:"",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        postalCode: "",
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
        // Clear the error message when the user starts typing again
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: ""
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Basic form validation
        const newErrors = {};
        if (!formData.name) {
            newErrors.name = "Name is required";
        }
        if (!formData.addressLine1) {
            newErrors.addressLine1 = "Address Line 1 is required";
        }
        if (!formData.addressLine2) {
            newErrors.addressLine2 = "Address Line 2 is required";
        }
        if (!formData.city) {
            newErrors.city = "City is required";
        }
        if (!formData.state) {
            newErrors.state = "State is required";
        }
        if (!formData.postalCode) {
            newErrors.postalCode = "Postal Code is required";
        }
        // Check if there are any errors
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            // No errors, submit the form
            handleFormSubmit(formData);
            // Reset form data
            setFormData({
                name:"",
                addressLine1: "",
                addressLine2: "",
                city: "",
                state: "",
                postalCode: ""
            });
        }
    };

    return (
        <Modal show={show} onHide={handleClose} style={{ paddingTop: '70px' }}>
            <Modal.Header>
                <h5>Add New Address</h5>
                <button type="button" className="close" onClick={handleClose}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit}>
                    {/* Input fields */}
                    <div className="form-group">
                        <input type="text" placeholder="Name" className="form-control" name="name" value={formData.name} onChange={handleChange} />
                        {errors.name && <div className="text-danger">{errors.name}</div>}
                    </div>
                    <div className="form-group">
                        <input type="text" placeholder="Address Line 1" className="form-control" name="addressLine1" value={formData.addressLine1} onChange={handleChange} />
                        {errors.addressLine1 && <div className="text-danger">{errors.addressLine1}</div>}
                    </div>
                    <div className="form-group">
                        <input type="text" placeholder="Address Line 2" className="form-control" name="addressLine2" value={formData.addressLine2} onChange={handleChange} />
                        {errors.addressLine2 && <div className="text-danger">{errors.addressLine2}</div>}
                    </div>
                    <div className="form-group">
                        <div className="row"> 
                            <div className="col-md-6">
                                <input placeholder="City" type="text" className="form-control" name="city" value={formData.city} onChange={handleChange}/>
                                {errors.city && <div className="text-danger">{errors.city}</div>}
                            </div>
                            <div className="col-md-6">
                                <input placeholder="Postal Code" type="number" className="form-control" name="postalCode" value={formData.postalCode} onChange={handleChange}/>
                                {errors.postalCode && <div className="text-danger">{errors.postalCode}</div>}
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <select className="form-control" name="state" value={formData.state} onChange={handleChange}>
                            <option value="">Select State</option>
                            <option value="Andhra Pradesh">Andhra Pradesh</option>
                            <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                            <option value="Assam">Assam</option>
                            <option value="Bihar">Bihar</option>
                            <option value="Chhattisgarh">Chhattisgarh</option>
                            <option value="Goa">Goa</option>
                            <option value="Gujarat">Gujarat</option>
                            <option value="Haryana">Haryana</option>
                            <option value="Himachal Pradesh">Himachal Pradesh</option>
                            <option value="Jharkhand">Jharkhand</option>
                            <option value="Karnataka">Karnataka</option>
                            <option value="Kerala">Kerala</option>
                            <option value="Madhya Pradesh">Madhya Pradesh</option>
                            <option value="Maharashtra">Maharashtra</option>
                            <option value="Manipur">Manipur</option>
                            <option value="Meghalaya">Meghalaya</option>
                            <option value="Mizoram">Mizoram</option>
                            <option value="Nagaland">Nagaland</option>
                            <option value="Odisha">Odisha</option>
                            <option value="Punjab">Punjab</option>
                            <option value="Rajasthan">Rajasthan</option>
                            <option value="Sikkim">Sikkim</option>
                            <option value="Tamil NaduTN">Tamil Nadu</option>
                            <option value="Telangana">Telangana</option>
                            <option value="Tripura">Tripura</option>
                            <option value="Uttarakhand">Uttarakhand</option>
                            <option value="Uttar Pradesh">Uttar Pradesh</option>
                            <option value="West Bengal">West Bengal</option>
                        </select>
                        {errors.state && <div className="text-danger">{errors.state}</div>}
                    </div>

                    <center><button type="submit" className="btn btn-food">Submit</button></center>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default FoodAddress;
