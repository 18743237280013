import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";

const DetailsPage = ({ addToCart, removeFromCart }) => {
  const {
    packageName,
    packageTitle,
    packageDetail,
    packagePrice,
    packagePrerequisites,
    packageNumber,
    packagesTestnames,
  } = useParams();

  const packageDetails = {
    name: packageName,
    title: packageTitle,
    details: packageDetail,
    price: parseFloat(packagePrice),
    prerequisites: packagePrerequisites,
    testnumber: packageNumber,
    testnames: JSON.parse(packagesTestnames),
  };

  const [activeFAQ, setActiveFAQ] = useState(null);
  const [cartItems, setCartItems] = useState([]);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const isItemInCart = cartItems.some((item) => item.title === packageDetails.title);
  const handleFAQClick = (index) => {
    setActiveFAQ(index === activeFAQ ? null : index);
  };
const handleAddToCart = () => {
  const updatedCart = [...cartItems, packageDetails];
  setCartItems(updatedCart);
  setIsCartOpen(true);
  // Save cart items to localStorage
  localStorage.setItem("cartItems", JSON.stringify(updatedCart));
};

const handleRemoveFromCart = () => {
  // Only remove from cart if the item is actually in the cart
  if (isItemInCart) {
    const updatedCart = cartItems.filter((item) => item.title !== packageDetails.title);
    setCartItems(updatedCart);
    // Save updated cart items to localStorage
    localStorage.setItem("cartItems", JSON.stringify(updatedCart));
  }
};


  const handleOpenCart = () => {
    navigate("/testcart", { state: { cartItems } });
  };
  const totalItemsInCart = cartItems.length;

  useEffect(() => {
    // Check if there are cart items in localStorage
    const storedCartItems = localStorage.getItem("cartItems");
    if (storedCartItems) {
      setCartItems(JSON.parse(storedCartItems));
    }
  }, []);


  return (
    <section>
        <section class="py-5 mb-5 Bg_1">
            <div class="container" style={{paddingTop:'70px'}}>
                <h4>{packageDetails.title}</h4>
                <p class="text-muted" style={{ fontSize: "24px", fontWeight: "800" }}>
                    <span style={{ fontFamily: "sans-serif" }}>₹</span>
                    {packageDetails.price.toFixed(2)}
                </p>
                <div class="row">
                    <div class="col-lg-12 ">
                        <div class="card pricing-card_1" style={{ background: "#ebebeb" }}>
                            <div class="card-body">
                                <section>
                                    <section class="contact-content" style={{ justifyContent: "center", alignItems: "center", borderBottom: "1px solid #e0e0e0",}}>
                                        <div class="contact-widget media">
                                            <img src={require("../assets/img/home.png")} width="25px" alt="monitor" />
                                            <div class="media-body">
                                                <h6 class="widget-title">Home Sample Collection</h6>
                                            </div>
                                        </div>
                                        <div class="contact-widget media">
                                            <div class="media-body">
                                                <h6 class="widget-title">Prescription Not Required</h6>
                                            </div>
                                        </div>
                                    </section>
                                    <section class="contact-content" style={{ justifyContent: "center", alignItems: "center", paddingTop: "10px", }}>
                                        <div class="media-body">
                                            <li class="widget-title">Unisex</li>
                                        </div>
                                        <div class="media-body">
                                            <li class="widget-title">All Age Groups</li>
                                        </div>
                                        <div class="media-body">
                                            <li class="widget-title">Fasting: 10-12 Hrs</li>
                                        </div>
                                        <div class="media-body">
                                            <li class="widget-title">Reports in 8-10 Hrs</li>
                                        </div>
                                    </section>
                                </section><br/>
                                {isItemInCart ? (
                                    <button className="btn btn-danger btn-rounded btn-sm mt-3" onClick={handleRemoveFromCart}>
                                      Remove from Cart
                                    </button>
                                  ) : (
                                    <button className="btn btn-primary btn-rounded btn-sm mt-3" onClick={handleAddToCart}>
                                      Add to Cart
                                    </button>
                                  )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <main class="page-blog-post">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <section class="blog-post-author-info-section">
                            <div class="row">
                                <div class="col-lg-10 offset-lg-1">
                                    <div class="blog-post-author-info media">
                                        <div class="media-body">
                                            <h5>About the Package</h5>
                                            <p class="author-description" style={{ textAlign: "justify" }}>
                                                {packageDetails.details}
                                            </p>
                                            <br />
                                            <h5>Prerequisites</h5>
                                            <p class="author-description" style={{ textAlign: "justify" }}>
                                                {packageDetails.prerequisites}
                                            </p>
                                            <br />
                                            <h6>{packageDetails.testnumber} Lab Tests{" "}<span style={{ fontWeight: "500" }}>in this package</span></h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </main>
        <section>
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        {packageDetails.testnames.map((test, index) => (
                        <div key={index} class="card mb-3 landing-faq-card card-header bg-white" style={{ borderRadius: "20px" }}>
                            <button class="btn btn-link_1 d-flex align-items-center" type="button" onClick={() => handleFAQClick(index)} >
                                <h6 class="mb-0" style={{ fontSize: "14px" }}>{test.title}</h6>{" "}
                                <i class={`far fa-plus-square ml-auto ${index === activeFAQ ? "rotate-icon" : ""}`}></i>
                            </button>
                            <div class={`collapse ${index === activeFAQ ? "show" : ""}`} aria-labelledby={`faqTitle${index}`}>
                                <div class="card-body">
                                    <ul>{Object.entries(test).map(([key, value]) => (
                                        <li key={key} style={{ fontSize: "14px", color: "#989898" }}>{value}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
        {totalItemsInCart > 0 && (
            <div className="shopping-cart fixed-bottom-right">
              <span className="cart-icon" onClick={handleOpenCart}>
                <img
                  src={require("../assets/img/shopping-cart.gif")}
                  alt="blog"
                  width="45px"
                  className="blog-post-thumbnail"
                />{" "}
                {totalItemsInCart}
              </span>
            </div>
          )}
    </section>
  );
};

export default DetailsPage;
