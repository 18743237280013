import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";


const Cart = () => {
    const navigate = useNavigate();

    // Load cart data from sessionStorage on component mount
    const [cart, setCart] = useState(() => {
        const storedCart = sessionStorage.getItem('cart');
        return storedCart ? JSON.parse(storedCart) : [];
    });

    useEffect(() => {
        const checkLoginStatus = () => {
            // Check if the user is not logged in
            const userId = sessionStorage.getItem('userId');
            const firstName = sessionStorage.getItem('firstName');

            if (!userId || !firstName) {
                // Redirect to the sign-in page
                navigate('/signin');
            }
        };

        // Check the login status on component mount
        checkLoginStatus();

        // Set up a timer to periodically check the login status
        const intervalId = setInterval(() => {
            checkLoginStatus();
        }, 500); // Refresh every 2 seconds

        // Clean up the timer when the component unmounts
        return () => clearInterval(intervalId);
    }, [navigate]);

    // Filter added items
    const addedItems = cart.filter((item) => item.count > 0);

    // Calculate the total price of added items in the cart
    const totalPrice = addedItems.reduce((total, item) => total + item.price * item.count, 0);

    // Function to handle increasing the count of an item in the cart
    const increaseItemCount = (itemId) => {
        const updatedCart = cart.map((item) =>
            item.id === itemId ? { ...item, count: item.count + 1 } : item
        );
        setCart(updatedCart);
        sessionStorage.setItem('cart', JSON.stringify(updatedCart));

        // Notify the Medicinehome page about the change
        window.dispatchEvent(new Event('cartUpdated'));
    };
    // Function to handle decreasing the count of an item in the cart
    const decreaseItemCount = (itemId) => {
        const updatedCart = cart.map((item) =>
            item.id === itemId && item.count > 0 ? { ...item, count: item.count - 1 } : item
        );
        setCart(updatedCart);
        sessionStorage.setItem('cart', JSON.stringify(updatedCart));

        // Notify the Medicinehome page about the change
        window.dispatchEvent(new Event('cartUpdated'));
    };


    return (
        <main>
            <div className="container" style={{paddingTop:'50px'}}>
                <div className="row">
                    <div className="col-lg-12">
                        <section className="page-header">
                            <h2>Cart Details</h2>
                        </section>
                        <section className="privacy-policy-section">
                            <div className="row">
                                <div className="col-md-4 position-relative">
                                    <nav className="nav privay-policy-nav">
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-lg-12 mb-4">
                                                    <div class="card pricing-card">
                                                        <div class="card-body">
                                                            <h3 class="mb-1">Total Price</h3>
                                                            <h3 class="mb-1 text-warning">₹{totalPrice}</h3>
                                                        </div>
                                                        <div class="card-body" style={{paddingTop:'20px'}}>
                                                            <center><Link to='/address' className="btn btn-primary">Checkout</Link></center>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                        
                                    </nav>
                                </div>
                                <div className="col-md-8">
                                    <div className="privay-policy-card card">
                                        <div className="card-body">
                                            <div className="col-lg-12">
                                                <section className="blog-post-author-info-section">
                                                    <div className="col-lg-10">
                                                        <h5 className="blog-post-author-info-section-title">
                                                            Your Cart
                                                        </h5>
                                                        <div className="blog-post-author-info media media_1">
                                                            {addedItems.length > 0 ? (
                                                                <ul className="cart-list list-unstyled">
                                                                    {addedItems.map((item, index) => (
                                                                        <li key={item.id} className="cart-item">
                                                                            <div className="media">
                                                                                <img src={item.imgSrc} alt={item.name} className="mr-3 align-self-center" style={{ width: "50px", height: "50px", borderRadius: "50%" }} />
                                                                                <div className="media-body">
                                                                                    <h6 className="mt-0">{item.name} - ₹{item.price}</h6>
                                                                                    <h6 className="mt-0">{item.count} * ₹{item.price} = ₹{item.price * item.count}</h6>
                                                                                    <div className="cart-controls">
                                                                                        <button className="btn btn-primary mr-2" onClick={() => decreaseItemCount(item.id)}>-</button>
                                                                                        <span className="btn ">{item.count}</span>
                                                                                        <button className="btn btn-primary ml-2" onClick={() => increaseItemCount(item.id)}>+</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            ) : (
                                                                <h3 class="mb-1">Your cart is empty.</h3>
                                                            )}
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Cart;