import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import PatientAddressDetailsForm from "./PatientAddressDetailsForm";
import ReactDOM from "react-dom";

const TestAddress = () => {
  // React hooks to manage state
  const usenavigate = useNavigate();
  const location = useLocation();
  const [cartItems, setCartItems] = useState(location.state?.cartItems || []);
  const [patientDetails, setPatientDetails] = useState(
    JSON.parse(localStorage.getItem("patientDetails")) || []
  );
  const [patientAddressDetails, setPatientAddressDetails] = useState(
    JSON.parse(localStorage.getItem("patientAddressDetails")) || []
  );
  const {
    selectedDate,
    selectedTimeSlot,
} = location.state || {};
  // States to control button visibility
  const [showPaymentButton, setShowPaymentButton] = useState(false);
  const [showAddAddressButton, setShowAddAddressButton] = useState(true);

  // Calculate total price of cart items
  const totalPrice = cartItems.reduce((total, item) => total + item.price, 0);

  
    


  // useEffect to run when the component mounts
  useEffect(() => {
    // Check for patient details in localStorage when the component mounts
    const storedPatientDetails = JSON.parse(localStorage.getItem("patientDetails"));
    if (storedPatientDetails && storedPatientDetails.length > 0) {
      setPatientDetails(storedPatientDetails);
      setShowPaymentButton(false);
      setShowAddAddressButton(true);
    }

    // Check for patient address details in localStorage
    const storedPatientAddressDetails = JSON.parse(localStorage.getItem("patientAddressDetails"));
    if (storedPatientAddressDetails && storedPatientAddressDetails.length > 0) {
      setPatientAddressDetails(storedPatientAddressDetails);
      setShowPaymentButton(true);
      setShowAddAddressButton(false);
    }
  }, []); // Empty dependency array ensures this effect runs once when the component mounts

  // useEffect to save patientAddressDetails to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("patientAddressDetails", JSON.stringify(patientAddressDetails));
  }, [patientAddressDetails]);

  // Function to remove an item from the cart
  const removeFromCart = (item) => {
    const updatedCart = cartItems.filter((cartItem) => cartItem.title !== item.title);
    setCartItems(updatedCart);

    // Check if the cart is empty after removing an item
    if (updatedCart.length === 0) {
      // If the cart is empty, reset patientDetails and hide the Add Address button
      setPatientDetails([]);
      setPatientAddressDetails([]);
      setShowPaymentButton(false);
      setShowAddAddressButton(true);
    }
  };

  // Function to navigate to the payment form
  const openPaymentForm = () => {
    usenavigate('/testPaymentSelection', {
        state: {
            selectedDate,
            selectedTimeSlot,
            cartItems,
            patientDetails,
            patientAddressDetails,
        },
    });
  };

  // Function to open the patient address details form
  const openPatientAddressDetailsForm = () => {
    const container = document.createElement("div");
    document.body.appendChild(container);

    ReactDOM.render(
      <PatientAddressDetailsForm onClose={closePatientAddressDetailsForm} onSave={savePatientAddressDetails} />,
      container
    );

    // Display the form using Swal (SweetAlert2)
    Swal.fire({
      title: "Add Address Details",
      html: container,
      showConfirmButton: false,
      willClose: () => {
        ReactDOM.unmountComponentAtNode(container);
        container.remove();
      },
    });
  };

  // Function to close the patient address details form
  const closePatientAddressDetailsForm = () => {
    Swal.close();
  };

  // Function to save patient address details
  const savePatientAddressDetails = (details) => {
    setPatientAddressDetails((prevDetails) => [...prevDetails, details]);
    Swal.fire("Success!", "Address details saved successfully!", "success");
    closePatientAddressDetailsForm();

    setShowPaymentButton(true);
    setShowAddAddressButton(false);
  };


  if (cartItems.length === 0) {
    return (
      <main className="page-blog-post" style={{paddingTop:'50px'}}>
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <section className="blog-post-content">
                <div className="row">
                  <div className="col-lg-10 offset-lg-1">
                    <blockquote>
                      <p>Your Cart</p>
                      <p className="blog-post-author-info-section-title">No items in the cart</p>
                    </blockquote>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </main>
    );
  }
  return (
    <main className="page-blog-post">
      <div className="container"  style={{ paddingTop: '120px' }}>
        <div className="row">
          <div className="col-12 col-md-3 mb-2">
            <p style={{ color: '#00ba00', fontWeight: '600' }}>
              <img src={require("../assets/img/check.png")} width="25px" alt="monitor" /> Cart--------------------
            </p>
          </div>
          <div className="col-12 col-md-3 mb-2">
            <p style={{ color: '#00ba00', fontWeight: '600' }}>
              <img src={require("../assets/img/check.png")} width="25px" alt="monitor" /> Address-----------------
            </p>
          </div>
          <div className="col-12 col-md-3 mb-2">
            <p style={{ color: '#396ce8', fontWeight: '600' }}>
              <img src={require("../assets/img/radio-button.png")} width="25px" alt="monitor" /> Date & Time--------------
            </p>
          </div>
         
          <div className="col-12 col-md-3">
            <p style={{ color: '#bfbfbf', fontWeight: '600' }}>
              <img src={require("../assets/img/radio-button-inactive.png")} width="25px" alt="monitor" /> Payment-----------------
            </p>
          </div>
        </div>
      </div>
  
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
          <section className="blog-post-content">
            <div className="row">
              
              <div className="col-md-5">
                <p></p>
                <p
                  style={{
                    background: "#a9cbf2",
                    fontSize: "18px",
                    paddingLeft: "10px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    borderRadius: "20px",
                  }}
                  className="blog-post-author-info-section-title"
                >
                  <img src={require("../assets/img/home.png")} width="25px" alt="monitor" /> Home Sample Collection
                </p>
              </div>
              <div className="col-md-4">
                <p></p>
                {showPaymentButton && (
                  <button className="btn btn-primary btn-rounded" onClick={openPaymentForm}>
                    Continue to Payment
                  </button>
                )}
              </div>
            </div>
          </section>
            <section className="blog-post-author-info-section">
            <h6>Appointment Details</h6>
                <div className="row" style={{marginBottom:'20px'}}>
                <div className="col-lg-11" style={{ border: '2px solid #d3d3d3', borderRadius: '20px', marginRight:'10px' }}>
                  <table className="table" >
                  <tbody>
                    <div  className="d-flex align-items-center mb-3">
                      <p className="mb-0" style={{padding:'30px'}}>
                        {patientAddressDetails.map((patient, index) => (
                            <div key={index} >
                                <p>
                                    <img src={require("../assets/img/call.png")} width="20px" alt="monitor" />
                                    <span style={{paddingRight:'60px'}}> {patient.Mobile}</span>
                                </p>
                                <p>
                                    <img  src={require("../assets/img/open-mail.png")} width="20px" alt="monitor"  />
                                    <span> {patient.Mail}</span> <br />
                                </p>
                            </div>
                        ))}

                        <div >
                            <p>
                                <img src={require("../assets/img/calendar.png")} width="20px" alt="monitor" />
                                <span style={{paddingRight:'25px'}}> {selectedDate}</span> 
                            </p>
                            <p>
                                <img  src={require("../assets/img/clock.png")} width="20px" alt="monitor"  />
                                <span style={{paddingBottom:'20px'}}> {selectedTimeSlot}</span> <br />
                            </p>
                        </div>
                        
                        {patientAddressDetails.map((patient, index) => (
                            <div key={index}>
                                <p>
                                    <img src={require("../assets/img/location (1).png")} width="20px" alt="monitor" />
                                    <span style={{paddingRight:'60px',color:'b5b5b5'}}> {patient.Address}</span> 
                                </p>
                                
                            </div>
                        ))}
                      </p>
                    </div>
                  
                </tbody>
                
                </table>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-7" style={{ border: '2px solid #d3d3d3', borderRadius: '20px', marginRight:'10px' }}>
                  <table className="table" >
                    <tbody>
                      {cartItems.map((item, index) => (
                        <tr key={index}>
                          <td  style={{paddingTop:'25px'}}>{item.title}</td>
                          <td  style={{paddingTop:'25px'}}><span style={{ fontFamily: 'sans-serif' }}>₹</span>{item.price.toFixed(2)}</td>
                          <td >
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="col-lg-4" style={{ border: '4px solid #d3d3d3', borderRadius: '20px' }}>
                  <div className="blog-post-author-info_1 media mt-4">
                    <div className="media-body">
                      <div className="d-flex flex-wrap align-items-center">
                        <h6 className="mr-3 mb-0" style={{fontWeight:'500'}}>MRP Total</h6>
                        <p className="author-description ml-auto"><span style={{ fontFamily: 'sans-serif' }}>₹</span>{totalPrice.toFixed(2)}</p>
                      </div>
                      <div className="d-flex flex-wrap align-items-center">
                        <h6 className="mr-3 mb-0" style={{fontWeight:'800'}}>Total Amount</h6>
                        <p className="author-description ml-auto" style={{fontWeight:'800'}}><span style={{ fontFamily: 'sans-serif' }}>₹</span>{totalPrice.toFixed(2)}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </main>
  );
};

export default TestAddress;
