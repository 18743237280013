import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Orderconfirm = () => {
    const navigate = useNavigate();

    // Retrieve cart data from session storage
    const cart = JSON.parse(sessionStorage.getItem('cart'));

     // Retrieve address details from session storage
     const addressDetails = JSON.parse(sessionStorage.getItem('addressDetails'));

    useEffect(() => {
        const checkLoginStatus = () => {
            // Check if the user is not logged in
            const userId = sessionStorage.getItem('userId');
            const firstName = sessionStorage.getItem('firstName');

            if (!userId || !firstName) {
                // Redirect to the sign-in page
                navigate('/signin');
            }
        };

        // Check the login status on component mount
        checkLoginStatus();

        // Set up a timer to periodically check the login status
        const intervalId = setInterval(() => {
            checkLoginStatus();
        }, 500); // Refresh every 2 seconds

        // Clean up the timer when the component unmounts
        return () => clearInterval(intervalId);
    }, [navigate]);

    const orderedItems = cart.filter(item => item.count > 0);

    return (
        <main>
        <div class="container" style={{paddingTop:'50px'}}>
            <div class="row">
                <div class="col-lg-12">
                    <section class="page-header">
                        <h5><img src={require('../assets/img/check-mark.png')} alt="app" width="50px" class="img-fluid"/>Your order placed successfully.</h5>
                    </section>
                    <section class="page-header" style={{paddingTop:'0px'}}>
                        <div class="card career-card">
                            <div class="card-body">
                                <div>
                                    <h5 class="position-title">Delivery Address</h5>
                                    <p class="mb-1" style={{fontWeight:'800',paddingTop:'10px',fontSize:'14px'}}>{addressDetails.name},</p>
                                    <p class="mb-1" style={{fontSize:'14px'}}>{addressDetails.address}</p>
                                    <p class="mb-1" style={{fontSize:'14px'}}>{addressDetails.locality},</p>
                                    <p class="mb-1" style={{fontSize:'14px'}}>{addressDetails.city},</p>
                                    <p class="mb-1" style={{fontSize:'14px'}}>{addressDetails.state},</p>
                                    <p class="mb-1" style={{fontSize:'14px'}}>{addressDetails.pincode}.</p>
                                    <p class="mb-1" style={{fontSize:'14px'}}>Phone number: {addressDetails.phone}.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class="career-section">
                        <div class="card career-card">
                        {orderedItems.length > 0 ? (
                            <div class="card-body">
                                <ul class="list-group list-group-flush careers-list-group">
                                {orderedItems.map(item => (
                                    <li class="list-group-item">
                                        <div>
                                            <h5 class="position-title"><img src={item.imgSrc} alt={item.name} className="mr-3 align-self-center" style={{ width: "50px", height: "50px", borderRadius: "50%" }} /> {item.name}</h5>
                                        </div>
                                        <p class="position-location apply-btn"><p style={{paddingLeft:'50px'}}>{item.count} - Price: ₹{item.price * item.count}</p></p>
                                    </li>
                                    ))}
                                </ul>
                            </div>
                            ) : (
                                <p>No items in the order.</p>
                            )}
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </main>
    );
};

export default Orderconfirm;
