import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const TestOrder = () => {
    const navigate = useNavigate();

    // Retrieve cart data from session storage
    const cart = JSON.parse(sessionStorage.getItem('cart'));

     // Retrieve address details from session storage
     const addressDetails = JSON.parse(sessionStorage.getItem('addressDetails'));

    useEffect(() => {
        const checkLoginStatus = () => {
            // Check if the user is not logged in
            const userId = sessionStorage.getItem('userId');
            const firstName = sessionStorage.getItem('firstName');

            if (!userId || !firstName) {
                // Redirect to the sign-in page
                navigate('/signin');
            }
        };

        // Check the login status on component mount
        checkLoginStatus();

        // Set up a timer to periodically check the login status
        const intervalId = setInterval(() => {
            checkLoginStatus();
        }, 500); // Refresh every 2 seconds

        // Clean up the timer when the component unmounts
        return () => clearInterval(intervalId);
    }, [navigate]);

    const orderedItems = cart ? cart.filter(item => item.count > 0) : [];

    return (
        <main>
            <div class="container" style={{paddingTop:'50px'}}>
                <div class="row">
                    <div class="col-lg-12">
                        <section class="page-header">
                            <h5><img src={require('../assets/img/check-mark.png')} alt="app" width="50px" class="img-fluid"/>Your order placed successfully.</h5>
                        </section>
                    
                        <section class="career-section">
                            <div class="card career-card">
                            
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default TestOrder;
