import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import { API_URL } from '../../constants';
import '../../external';
import loginimage from '../assets/images/login.png';


const usersData = require('../users/users.json');

const Signin = () => {
// Import users.json dynamically

  const [email, emailupdate] = useState('');
  const [password, passwordupdate] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    sessionStorage.clear();
  }, []);

  const ProceedLogin = (e) => {
    e.preventDefault();

    if (validate()) {
      // Find user in the usersData array
      const user = usersData.find(u => u.email === email && u.password === password);

      if (user) {
        // Display success alert
        Swal.fire({
          icon: 'success',
          title: 'Login Successful',
          showConfirmButton: false,
          timer: 1500
        });

        // Extract user data
        const { _id, firstName, email, phone } = user;

        // Store user information in session storage
        sessionStorage.setItem('userId', _id.$oid);
        sessionStorage.setItem('firstName', firstName);
        sessionStorage.setItem('email', email);
        sessionStorage.setItem('phone', phone);

        // Redirect to the desired page
        navigate('/dashboard');
      } else {
        // Display error alert if login fails
        Swal.fire({
          icon: 'error',
          title: 'Login Failed',
          text: 'Invalid email or password'
        });
      }
    }
  };

  const validate = () => {
    let isValid = true;

    if (!email.trim()) {
      Swal.fire({
        icon: 'warning',
        title: 'Please Enter Email'
      });
      isValid = false;
    }

    if (!password.trim()) {
      Swal.fire({
        icon: 'warning',
        title: 'Please Enter Password'
      });
      isValid = false;
    }

    return isValid;
  };
    return (
        <main className="page-auth" style={{paddingTop:'50px'}}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <section className="auth-wrapper">
                            <div className="row">
                                <div className="col-md-6 mb-4 mb-md-0">
                                    <h2 className="auth-section-title">Log In</h2>
                                    <p className="auth-section-subtitle">Sign in to your account to continue.</p>
                                    <form onSubmit={ProceedLogin}>
                                        <div className="form-group">
                                            <label >Email <sup>*</sup></label>
                                            <input value={email} onChange={e => emailupdate(e.target.value)} type="email" className="form-control" id="email" name="email" placeholder="Email *"/>
                                        </div>
                                        <div className="form-group">
                                            <label >Password <sup>*</sup></label>
                                            <input value={password} onChange={e => passwordupdate(e.target.value)} type="password" className="form-control" id="password" name="password" placeholder="Password *"  autoComplete="password"/>
                                        </div>
                                        <div className="form-actions-wrapper d-flex flex-wrap align-items-center justify-content-between">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="keepLogin"/>
                                                <label className="form-check-label" >
                                                    keep me login
                                                </label>
                                            </div>
                                            <Link  className="forgot-password-link">Forgot password?</Link>
                                        </div>
                                        <button className="btn btn-primary btn-auth-submit" type="submit">Submit</button>
                                    </form>
                                    <p className="mb-0">
                                        <Link to={'/signup'} className="text-dark font-weight-bold">New User? Sign Up</Link>
                                    </p>
                                </div>
                                <div className="col-md-6 d-flex align-items-center">
                                    <img src={loginimage} alt="login" className="img-fluid"/>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Signin;
