import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import '../../external';

const containerStyle = {
    width: '100%',
    height: '250px',
};

const center = {
    lat: 17.447052623830057,
    lng: 78.36428807920888,
};

const Payment = () => {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [customerlist, setCustomerList] = useState(null);

    useEffect(() => {
        // Simulate checking for captain
        const captainCheckTimeout = setTimeout(() => {
            setLoading(false);
        }, 60000); // 60000 milliseconds = 1 minute

        // Clear the timeout on component unmount
        return () => clearTimeout(captainCheckTimeout);
    }, []);


    useEffect(() => {
        // Retrieve the selected card data from session
        const selectedCardData = sessionStorage.getItem("selectedCardData");

        if (selectedCardData) {
            // Parse the JSON data
            const parsedData = JSON.parse(selectedCardData);

            // Update the state with the selected card data
            setCustomerList(parsedData);
        }
    }, []);

    return (
        <section className="py-5 mb-5">
            <div className="container" style={{paddingTop:"70px"}}>
                <h2>Choose A Ride</h2>
                <h5>"Embark on a journey through the picturesque landscapes, where every twist and turn of the road unveils new adventures and memories waiting to be created."</h5>
                <div className="row">
                    {customerlist && (
                        <div className="col-lg-4 mb-4">
                            <div className="card pricing-card border-warning">
                                <div className="card-body">
                                    <h3 className="mb-1">{customerlist.name}</h3>
                                    <h3 className="mb-1 text-warning">{customerlist.price}</h3>
                                    <p className="mb-4"><img src={customerlist.img} alt="Card" width="100px" /></p>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="col-lg-4 mb-4">
                        <div className="card pricing-card border-primary">
                            <div className="">
                                {/* Google Map Component */}
                                <LoadScript googleMapsApiKey="AIzaSyBRk3hp-XsOGAkECIX0QGFjRPYc6DomGwg">
                                    <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={8} >
                                        <Marker position={center} />
                                    </GoogleMap>
                                </LoadScript>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-4" >
                        <div className="card pricing-card border-success" >
                            <div className="card-body" style={{minHeight:'250px'}}>
                                {loading ? (
                                    <p>Loading... Checking for Captain</p>
                                ) : (
                                    <>
                                        <h3>Ride Confirmed</h3>
                                        <p>Your captain is on the way!</p>
                                        <button className="btn btn-outline-success btn-rounded">Continue</button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Payment;
