import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const Doctors = () => {
    const navigate = useNavigate();
    // Retrieve label name from session
    const labelName = sessionStorage.getItem('selectedCardData');
    const selectedCardData = labelName ? JSON.parse(labelName) : null;

    const handleBookVisit = (doctorDetails) => {
        // Store doctor details in session
        sessionStorage.setItem('selectedDoctorDetails', JSON.stringify(doctorDetails));
        // Redirect to the appointment page
        navigate('/appointment');
    };

    useEffect(() => {
        const checkLoginStatus = () => {
            // Check if the user is not logged in
            const userId = sessionStorage.getItem('userId');
            const firstName = sessionStorage.getItem('firstName');

            if (!userId || !firstName) {
                // Redirect to the sign-in page
                navigate('/signin');
            }
        };

        // Check the login status on component mount
        checkLoginStatus();

        // Set up a timer to periodically check the login status
        const intervalId = setInterval(() => {
            checkLoginStatus();
        }, 500); // Refresh every 2 seconds

        // Clean up the timer when the component unmounts
        return () => clearInterval(intervalId);
    }, [navigate]);

    return (
        <main class="page-blog" >
        <div class="container" style={{paddingTop:'50px'}}>
            <div class="row">
                <div class="col-lg-12 offset-lg-1">
                    <section class="page-header">
                        <h1>Doctors List</h1>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb foi-breadcrumb">
                                <li class="breadcrumb-item">Book Dr. Appointment</li>
                                <li class="breadcrumb-item active" aria-current="page">Doctors List</li>
                            </ol>
                        </nav>
                    </section>
                    <section class="foi-page-section pt-0">
                        <div class="row">
                            <div class="col-md-6">
                                <article class="blog-post media">
                                    <div class="blog-post-thumbnail-wrapper">
                                        <img src={require('../assets/img/person-1.png')} alt="blog" width="155px" class="blog-post-thumbnail mr-md-3"/>
                                        <Link to={'/appointment'} onClick={() => handleBookVisit({
                                            doctor: "Dr. Ethan",
                                            experience: "Since 14 Years",
                                            location: "Apollo, Gachibowli",
                                            consultationFee: "₹550.00",
                                            image: require('../assets/img/person-1.png'),
                                        })}>
                                            <span class="btn btn-primary btn-rounded blog-post-badge">Book Visit</span>
                                        </Link>
                                    </div>
                                    <div class="media-body">
                                        <p class="blog-post-date">{selectedCardData ? selectedCardData.name : "New"}</p>
                                        <h5 class="blog-post-title">Dr. Ethan</h5>
                                        <p class="blog-post-excerpt"><img class="blog-post-excerpt" src={require('../assets/img/first-aid-kit.png')} alt="blog" width="18px" /> Since 14 Years</p>
                                        <p class="blog-post-excerpt"><img class="blog-post-excerpt" src={require('../assets/img/location.png')} alt="blog" width="18px" /> Apollo, Gachibowli</p>
                                        <p class="blog-post-excerpt">Consultation fees <span style={{color:'black',fontWeight:'800'}}>₹550.00</span></p>
                                    </div>
                                </article>
                            </div>

                            <div class="col-md-6">
                                <article class="blog-post media">
                                    <div class="blog-post-thumbnail-wrapper">
                                        <img src={require('../assets/img/person-2.png')} alt="blog" width="155px" class="blog-post-thumbnail mr-md-3"/>
                                        <Link to={'/appointment'} onClick={() => handleBookVisit({
                                            doctor: "Dr. Haritha",
                                            experience: "Since 14 Years",
                                            location: "Medplus, Gachibowli",
                                            consultationFee: "₹700.00",
                                            image: require('../assets/img/person-2.png'),
                                        })}>
                                            <span class="btn btn-primary btn-rounded blog-post-badge">Book Visit</span>
                                        </Link>
                                    </div>
                                    <div class="media-body">
                                        <p class="blog-post-date">{selectedCardData ? selectedCardData.name : "New"}</p>
                                        <h5 class="blog-post-title">Dr. Haritha</h5>
                                        <p class="blog-post-excerpt"><img class="blog-post-excerpt" src={require('../assets/img/first-aid-kit.png')} alt="blog" width="18px" /> Since 14 Years</p>
                                        <p class="blog-post-excerpt"><img class="blog-post-excerpt" src={require('../assets/img/location.png')} alt="blog" width="18px" /> Medplus, Gachibowli</p>
                                        <p class="blog-post-excerpt">Consultation fees <span style={{color:'black',fontWeight:'800'}}>₹700.00</span></p>
                                    </div>
                                </article>
                            </div>

                            <div class="col-md-6">
                                <article class="blog-post media">
                                    <div class="blog-post-thumbnail-wrapper">
                                        <img src={require('../assets/img/person-3.png')} alt="blog" width="155px" class="blog-post-thumbnail mr-md-3"/>
                                        <Link to={'/appointment'} onClick={() => handleBookVisit({
                                            doctor: "Dr. Bora",
                                            experience: "Since 14 Years",
                                            location: "Tenet Diagnostics, Gachibowli",
                                            consultationFee: "₹650.00",
                                            image: require('../assets/img/person-3.png'),
                                        })}>
                                            <span class="btn btn-primary btn-rounded blog-post-badge">Book Visit</span>
                                        </Link>
                                    </div>
                                    <div class="media-body">
                                        <p class="blog-post-date">{selectedCardData ? selectedCardData.name : "New"}</p>
                                        <h5 class="blog-post-title">Dr. Bora</h5>
                                        <p class="blog-post-excerpt"><img class="blog-post-excerpt" src={require('../assets/img/first-aid-kit.png')} alt="blog" width="18px" /> Since 14 Years</p>
                                        <p class="blog-post-excerpt"><img class="blog-post-excerpt" src={require('../assets/img/location.png')} alt="blog" width="18px" /> Tenet Diagnostics, Gachibowli</p>
                                        <p class="blog-post-excerpt">Consultation fees <span style={{color:'black',fontWeight:'800'}}>₹650.00</span></p>
                                    </div>
                                </article>
                            </div>

                            <div class="col-md-6">
                                <article class="blog-post media">
                                    <div class="blog-post-thumbnail-wrapper">
                                        <img src={require('../assets/img/person-4.png')} alt="blog" width="155px" class="blog-post-thumbnail mr-md-3"/>
                                        <Link to={'/appointment'} onClick={() => handleBookVisit({
                                            doctor: "Dr. Shishir",
                                            experience: "Since 14 Years",
                                            location: "Kakathiya Hospitals, Kondapur",
                                            consultationFee: "₹750.00",
                                            image: require('../assets/img/person-4.png'),
                                        })}>
                                            <span class="btn btn-primary btn-rounded blog-post-badge">Book Visit</span>
                                        </Link>
                                    </div>
                                    <div class="media-body">
                                        <p class="blog-post-date">{selectedCardData ? selectedCardData.name : "New"}</p>
                                        <h5 class="blog-post-title">Dr. Shishir</h5>
                                        <p class="blog-post-excerpt"><img class="blog-post-excerpt" src={require('../assets/img/first-aid-kit.png')} alt="blog" width="18px" /> Since 14 Years</p>
                                        <p class="blog-post-excerpt"><img class="blog-post-excerpt" src={require('../assets/img/location.png')} alt="blog" width="18px" /> Kakathiya Hospitals, Kondapur</p>
                                        <p class="blog-post-excerpt">Consultation fees <span style={{color:'black',fontWeight:'800'}}>₹750.00</span></p>
                                    </div>
                                </article>
                            </div>

                            <div class="col-md-6">
                                <article class="blog-post media">
                                    <div class="blog-post-thumbnail-wrapper">
                                        <img src={require('../assets/img/person-5.png')} alt="blog" width="155px" class="blog-post-thumbnail mr-md-3"/>
                                        <Link to={'/appointment'} onClick={() => handleBookVisit({
                                            doctor: "Dr. Latha",
                                            experience: "Since 14 Years",
                                            location: "AIG, Hitech-City",
                                            consultationFee: "₹600.00",
                                            image: require('../assets/img/person-5.png'),
                                        })}>
                                            <span class="btn btn-primary btn-rounded blog-post-badge">Book Visit</span>
                                        </Link>
                                    </div>
                                    <div class="media-body">
                                        <p class="blog-post-date">{selectedCardData ? selectedCardData.name : "New"}</p>
                                        <h5 class="blog-post-title">Dr. Latha</h5>
                                        <p class="blog-post-excerpt"><img class="blog-post-excerpt" src={require('../assets/img/first-aid-kit.png')} alt="blog" width="18px" /> Since 14 Years</p>
                                        <p class="blog-post-excerpt"><img class="blog-post-excerpt" src={require('../assets/img/location.png')} alt="blog" width="18px" />  AIG, Hitech-City</p>
                                        <p class="blog-post-excerpt">Consultation fees <span style={{color:'black',fontWeight:'800'}}>₹600.00</span></p>
                                    </div>
                                </article>
                            </div>

                            <div class="col-md-6">
                                <article class="blog-post media">
                                    <div class="blog-post-thumbnail-wrapper">
                                        <img src={require('../assets/img/person-6.png')} alt="blog" width="155px" class="blog-post-thumbnail mr-md-3"/>
                                        <Link to={'/appointment'} onClick={() => handleBookVisit({
                                            doctor: "Dr. Pavan",
                                            experience: "Since 14 Years",
                                            location: "Yashodha Hospitals, JBS",
                                            consultationFee: "₹750.00",
                                            image: require('../assets/img/person-6.png'),
                                        })}>
                                            <span class="btn btn-primary btn-rounded blog-post-badge">Book Visit</span>
                                        </Link>
                                    </div>
                                    <div class="media-body">
                                        <p class="blog-post-date">{selectedCardData ? selectedCardData.name : "New"}</p>
                                        <h5 class="blog-post-title">Dr. Pavan</h5>
                                        <p class="blog-post-excerpt"><img class="blog-post-excerpt" src={require('../assets/img/first-aid-kit.png')} alt="blog" width="18px" /> Since 14 Years</p>
                                        <p class="blog-post-excerpt"><img class="blog-post-excerpt" src={require('../assets/img/location.png')} alt="blog" width="18px" />  Yashodha Hospitals, JBS</p>
                                        <p class="blog-post-excerpt">Consultation fees <span style={{color:'black',fontWeight:'800'}}>₹750.00</span></p>
                                    </div>
                                </article>
                            </div>
                            
                            <div class="col-md-6">
                                <article class="blog-post media">
                                    <div class="blog-post-thumbnail-wrapper">
                                        <img src={require('../assets/img/person-7.png')} alt="blog" width="155px" class="blog-post-thumbnail mr-md-3"/>
                                        <Link to={'/appointment'} onClick={() => handleBookVisit({
                                            doctor: "Dr. Lingeshwar",
                                            experience: "Since 14 Years",
                                            location: "MediCover, Lingampally",
                                            consultationFee: "₹600.00",
                                            image: require('../assets/img/person-7.png'),
                                        })}>
                                            <span class="btn btn-primary btn-rounded blog-post-badge">Book Visit</span>
                                        </Link>
                                    </div>
                                    <div class="media-body">
                                        <p class="blog-post-date">{selectedCardData ? selectedCardData.name : "New"}</p>
                                        <h5 class="blog-post-title">Dr. Lingeshwar</h5>
                                        <p class="blog-post-excerpt"><img class="blog-post-excerpt" src={require('../assets/img/first-aid-kit.png')} alt="blog" width="18px" /> Since 14 Years</p>
                                        <p class="blog-post-excerpt"><img class="blog-post-excerpt" src={require('../assets/img/location.png')} alt="blog" width="18px" />  MediCover, Lingampally</p>
                                        <p class="blog-post-excerpt">Consultation fees <span style={{color:'black',fontWeight:'800'}}>₹600.00</span></p>
                                    </div>
                                </article>
                            </div>


                            <div class="col-md-6">
                                <article class="blog-post media">
                                    <div class="blog-post-thumbnail-wrapper">
                                        <img src={require('../assets/img/person-8.png')} alt="blog" width="155px" class="blog-post-thumbnail mr-md-3"/>
                                        <Link to={'/appointment'} onClick={() => handleBookVisit({
                                            doctor: "Dr. Shalini",
                                            experience: "Since 14 Years",
                                            location: "Siddartha Hospitals, Chandanagar",
                                            consultationFee: "₹800.00",
                                            image: require('../assets/img/person-8.png'),
                                        })}>
                                            <span class="btn btn-primary btn-rounded blog-post-badge">Book Visit</span>
                                        </Link>
                                    </div>
                                    <div class="media-body">
                                        <p class="blog-post-date">{selectedCardData ? selectedCardData.name : "New"}</p>
                                        <h5 class="blog-post-title">Dr. Shalini</h5>
                                        <p class="blog-post-excerpt"><img class="blog-post-excerpt" src={require('../assets/img/first-aid-kit.png')} alt="blog" width="18px" /> Since 14 Years</p>
                                        <p class="blog-post-excerpt"><img class="blog-post-excerpt" src={require('../assets/img/location.png')} alt="blog" width="18px" />  Siddartha Hospitals, Chandanagar</p>
                                        <p class="blog-post-excerpt">Consultation fees <span style={{color:'black',fontWeight:'800'}}>₹800.00</span></p>
                                    </div>
                                </article>
                            </div>


                            <div class="col-md-6">
                              <article class="blog-post media">
                                  <div class="blog-post-thumbnail-wrapper">
                                      <img src={require('../assets/img/person-9.png')} alt="blog" width="155px" class="blog-post-thumbnail mr-md-3"/>
                                      <Link to={'/appointment'} onClick={() => handleBookVisit({
                                        doctor: "Dr. Madhu",
                                          experience: "Since 14 Years",
                                          location: "GGH, Madinaguda",
                                          consultationFee: "₹600.00",
                                          image: require('../assets/img/person-9.png'),
                                      })}>
                                          <span class="btn btn-primary btn-rounded blog-post-badge">Book Visit</span>
                                      </Link>
                                  </div>
                                  <div class="media-body">
                                      <p class="blog-post-date">{selectedCardData ? selectedCardData.name : "New"}</p>
                                      <h5 class="blog-post-title">Dr. Madhu</h5>
                                      <p class="blog-post-excerpt"><img class="blog-post-excerpt" src={require('../assets/img/first-aid-kit.png')} alt="blog" width="18px" /> Since 14 Years</p>
                                      <p class="blog-post-excerpt"><img class="blog-post-excerpt" src={require('../assets/img/location.png')} alt="blog" width="18px" />  GGH, Madinaguda</p>
                                      <p class="blog-post-excerpt">Consultation fees <span style={{color:'black',fontWeight:'800'}}>₹600.00</span></p>
                                  </div>
                              </article>
                            </div>

                            <div class="col-md-6">
                            <article class="blog-post media">
                                <div class="blog-post-thumbnail-wrapper">
                                    <img src={require('../assets/img/person-10.png')} alt="blog" width="155px" class="blog-post-thumbnail mr-md-3"/>
                                    <Link to={'/appointment'} onClick={() => handleBookVisit({
                                        doctor: "Dr. Asha",
                                        experience: "Since 14 Years",
                                        location: "HSN Hospitals, Hafizpet",
                                        consultationFee: "₹500.00",
                                        image: require('../assets/img/person-10.png'),
                                    })}>
                                        <span class="btn btn-primary btn-rounded blog-post-badge">Book Visit</span>
                                    </Link>
                                </div>
                                <div class="media-body">
                                    <p class="blog-post-date">{selectedCardData ? selectedCardData.name : "New"}</p>
                                    <h5 class="blog-post-title">Dr. Asha</h5>
                                    <p class="blog-post-excerpt"><img class="blog-post-excerpt" src={require('../assets/img/first-aid-kit.png')} alt="blog" width="18px" /> Since 14 Years</p>
                                    <p class="blog-post-excerpt"><img class="blog-post-excerpt" src={require('../assets/img/location.png')} alt="blog" width="18px" />  HSN Hospitals, Hafizpet</p>
                                    <p class="blog-post-excerpt">Consultation fees <span style={{color:'black',fontWeight:'800'}}>₹500.00</span></p>
                                </div>
                            </article>
                          </div>
                            
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </main>
    );
}

export default Doctors;
