import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import '../../external';


const Footer = () => {
    const usenavigate = useNavigate();
    const [customerlist, listupdate] = useState(null);
   
   

    return (
        <footer className="">
          
        </footer>
    );
}

export default Footer;