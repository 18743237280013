import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const Address = () => {
    const navigate = useNavigate();

    // Load cart data from sessionStorage on component mount
    const [cart, setCart] = useState(() => {
        const storedCart = sessionStorage.getItem('cart');
        return storedCart ? JSON.parse(storedCart) : [];
    });

    const [formData, setFormData] = useState({
        name: "",
        phone: "",
        pincode: "",
        locality: "",
        address: "",
        city: "",
        state: "",
        landmark: "",
        alternativePhone: "",
    });

    const [formErrors, setFormErrors] = useState({
        name: "",
        phone: "",
        pincode: "",
        locality: "",
        address: "",
        city: "",
        state: "",
        landmark: "",
        alternativePhone: "",
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        // Clear the error message when the user starts typing in a field
        setFormErrors({ ...formErrors, [e.target.name]: "" });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Check for empty fields
        let errors = {};
        Object.keys(formData).forEach((key) => {
            if (!formData[key] && key !== "alternativePhone" && key !== "landmark") {
                errors[key] = "Please fill out this field.";
            }
        });

        // Display errors if any
        setFormErrors(errors);

        // If there are errors, prevent form submission
        if (Object.keys(errors).length > 0) {
            return;
        }

        // Store form data in session
        sessionStorage.setItem('addressDetails', JSON.stringify(formData));

        // Navigate to the next page
        navigate('/checkout'); // Replace '/nextPage' with the actual path of the next page
    };

    useEffect(() => {
        const checkLoginStatus = () => {
            // Check if the user is not logged in
            const userId = sessionStorage.getItem('userId');
            const firstName = sessionStorage.getItem('firstName');

            if (!userId || !firstName) {
                // Redirect to the sign-in page
                navigate('/signin');
            }
        };

        // Check the login status on component mount
        checkLoginStatus();

        // Set up a timer to periodically check the login status
        const intervalId = setInterval(() => {
            checkLoginStatus();
        }, 500); // Refresh every 2 seconds

        // Clean up the timer when the component unmounts
        return () => clearInterval(intervalId);
    }, [navigate]);

    // Filter added items
    const addedItems = cart.filter((item) => item.count > 0);

    // Calculate the total price of added items in the cart
    const totalPrice = addedItems.reduce((total, item) => total + item.price * item.count, 0);

    // Function to handle increasing the count of an item in the cart
    const increaseItemCount = (itemId) => {
        const updatedCart = cart.map((item) =>
            item.id === itemId ? { ...item, count: item.count + 1 } : item
        );
        setCart(updatedCart);
        sessionStorage.setItem('cart', JSON.stringify(updatedCart));

        // Notify the Medicinehome page about the change
        window.dispatchEvent(new Event('cartUpdated'));
    };
    // Function to handle decreasing the count of an item in the cart
    const decreaseItemCount = (itemId) => {
        const updatedCart = cart.map((item) =>
            item.id === itemId && item.count > 0 ? { ...item, count: item.count - 1 } : item
        );
        setCart(updatedCart);
        sessionStorage.setItem('cart', JSON.stringify(updatedCart));

        // Notify the Medicinehome page about the change
        window.dispatchEvent(new Event('cartUpdated'));
    };

    return (
        <main>
            <div className="container" style={{paddingTop:'50px'}}>
                <div className="row">
                    <div className="col-lg-12">
                        <section className="page-header">
                            <h2>Add Address</h2>
                        </section>
                        <section className="privacy-policy-section">
                            <div className="row">
                                <div className="col-md-4 position-relative">
                                    <nav className="nav privay-policy-nav">
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-lg-12 mb-4">
                                                    <div class="card pricing-card">
                                                        <div class="card-body">
                                                            <h3 class="mb-1">Total Price</h3>
                                                            <h3 class="mb-1 text-warning">₹{totalPrice}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </nav>
                                </div>
                                <div class="col-md-8">
                                    <section class="contact-form-wrapper">
                                        <form onSubmit={handleSubmit}>
                                            <div class="row">
                                                <div class="form-group col-md-6">
                                                    <label for="name">Your Name <sup>*</sup></label>
                                                    <input type="text" class="form-control" id="name" name="name" placeholder="Name *" onChange={handleChange} value={formData.name} required />
                                                    <div className="text-danger">{formErrors.name}</div>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <label for="phone">10 Digit Mobile Number <sup>*</sup></label>
                                                    <input type="number" class="form-control" id="phone" name="phone" placeholder="987-654-3210" onChange={handleChange} value={formData.phone} required />
                                                    <div className="text-danger">{formErrors.phone}</div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="form-group col-md-6">
                                                    <label for="pincode">Pincode <sup>*</sup></label>
                                                    <input type="number" class="form-control" id="pincode" name="pincode" placeholder="Pincode *" onChange={handleChange} value={formData.pincode} required />
                                                    <div className="text-danger">{formErrors.pincode}</div>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <label for="locality">Locality <sup>*</sup></label>
                                                    <input type="text" class="form-control" id="locality" name="locality" placeholder="Locality" onChange={handleChange} value={formData.locality} required />
                                                    <div className="text-danger">{formErrors.locality}</div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="form-group col-12">
                                                    <label for="address">Address<sup>*</sup></label>
                                                    <textarea name="address" id="address" class="form-control" rows="7" placeholder="Address ..." onChange={handleChange} value={formData.address} required></textarea>
                                                    <div className="text-danger">{formErrors.address}</div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="form-group col-md-6">
                                                    <label for="city">City/District/Town <sup>*</sup></label>
                                                    <input type="text" class="form-control" id="city" name="city" placeholder="City/District/Town " onChange={handleChange} value={formData.city} required />
                                                    <div className="text-danger">{formErrors.city}</div>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <label for="state">State <sup>*</sup></label>
                                                    <select class="form-control" id="state" name="state" onChange={handleChange} value={formData.state} required>
                                                        <option value="">Select State</option>
                                                        <option value="Andhra Pradesh">Andhra Pradesh</option>
                                                        <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                                        <option value="Assam">Assam</option>
                                                        <option value="Bihar">Bihar</option>
                                                        <option value="Chhattisgarh">Chhattisgarh</option>
                                                        <option value="Goa">Goa</option>
                                                        <option value="Gujarat">Gujarat</option>
                                                        <option value="Haryana">Haryana</option>
                                                        <option value="Himachal Pradesh">Himachal Pradesh</option>
                                                        <option value="Jharkhand">Jharkhand</option>
                                                        <option value="Karnataka">Karnataka</option>
                                                        <option value="Kerala">Kerala</option>
                                                        <option value="Madhya Pradesh">Madhya Pradesh</option>
                                                        <option value="Maharashtra">Maharashtra</option>
                                                        <option value="Manipur">Manipur</option>
                                                        <option value="Meghalaya">Meghalaya</option>
                                                        <option value="Mizoram">Mizoram</option>
                                                        <option value="Nagaland">Nagaland</option>
                                                        <option value="Odisha">Odisha</option>
                                                        <option value="Punjab">Punjab</option>
                                                        <option value="Rajasthan">Rajasthan</option>
                                                        <option value="Sikkim">Sikkim</option>
                                                        <option value="Tamil NaduTN">Tamil Nadu</option>
                                                        <option value="Telangana">Telangana</option>
                                                        <option value="Tripura">Tripura</option>
                                                        <option value="Uttarakhand">Uttarakhand</option>
                                                        <option value="Uttar Pradesh">Uttar Pradesh</option>
                                                        <option value="West Bengal">West Bengal</option>
                                                    </select>
                                                    <div className="text-danger">{formErrors.state}</div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="form-group col-md-6">
                                                    <label for="landmark">Landmark <sup>*</sup></label>
                                                    <input type="text" class="form-control" id="landmark" name="landmark" placeholder="Landmark (Optional)" onChange={handleChange} value={formData.landmark} />
                                                    <div className="text-danger">{formErrors.landmark}</div>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <label for="alternativephone">Alternative Phone</label>
                                                    <input type="number" class="form-control" id="alternativephone" name="alternativePhone" placeholder="Alternative Phone (Optional)" onChange={handleChange} value={formData.alternativePhone} />
                                                    <div className="text-danger">{formErrors.alternativePhone}</div>
                                                </div>
                                            </div>
                                            <div class="text-center">
                                                <button type="submit" class="btn btn-primary mb-4">Save and Deliver Here</button>
                                            </div>
                                        </form>
                                    </section>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Address;
