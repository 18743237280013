import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import '../../external';

const Fare = () => {
    const [pickupName, setPickupName] = useState(sessionStorage.getItem("pickupName") || "");
    const [dropoffName, setDropoffName] = useState(sessionStorage.getItem("dropoffName") || "");
    const [departureTime, setDepartureTime] = useState(getFutureTime(3));
    const [selectedCard, setSelectedCard] = useState("auto"); // Set 'auto' as the default selected card
    const [submitButtonName, setSubmitButtonName] = useState("Book VC Auto"); // Default button text
    const navigate = useNavigate();

    useEffect(() => {
        // Update the departure time every minute
        const intervalId = setInterval(() => {
            setDepartureTime(getFutureTime(3));
        }, 60000); // 60000 milliseconds = 1 minute

        // Clear the interval on component unmount
        return () => clearInterval(intervalId);
    }, []); // Empty dependency array ensures that this effect runs only once on mount

    // Function to get the future time
    function getFutureTime(minutes) {
        const currentDate = new Date();
        const futureDate = new Date(currentDate.getTime() + minutes * 60000); // 60000 milliseconds = 1 minute
        return futureDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }

    // Function to handle card selection
    const handleCardSelection = (cardId) => {
        setSelectedCard(cardId);
        // Dynamically set the submit button name based on the selected card
        setSubmitButtonName(`Book ${getCardName(cardId)}`);
    };


   // Function to handle form submission
   const handleFormSubmit = (event) => {
        event.preventDefault();

        // Get the selected card data based on the selectedCard value
        const selectedCardData = {
            name: getCardName(selectedCard),
            price: getCardPrice(selectedCard),
            img: getCardImage(selectedCard),
        };

        // Store the selected card data in session
        sessionStorage.setItem("selectedCardData", JSON.stringify(selectedCardData));

        // Navigate to the next page
        navigate("/search"); // Replace "/payment" with the actual path of your next page
    };

    // Function to get the card name based on the card ID
    const getCardName = (cardId) => {
        switch (cardId) {
            case "auto":
                return "VC Auto";
            case "ubergo":
                return "VC Go";
            case "motorbike":
                return "VC Moto";
            case "premier":
                return "VC Premier";
            case "sedan":
                return "VC sedan";
            case "sedanXL":
                return "VC XL";
            default:
                return "";
        }
    };

    // Function to get the card price based on the card ID
    const getCardPrice = (cardId) => {
        switch (cardId) {
            case "auto":
                return "₹125.00";
            case "ubergo":
                return "₹195.00";
            case "motorbike":
                return "₹75.00";
            case "premier":
                    return "₹255.00";
            case "sedan":
                    return "₹300.00";
            case "sedanXL":
                    return "₹450.00";
            default:
                return "";
        }
    };

    // Function to get the card image source based on the card ID
    const getCardImage = (cardId) => {
        switch (cardId) {
            case "auto":
                return require('../assets/img/auto.png');
            case "ubergo":
                return require('../assets/img/ubergo.png');
            case "motorbike":
                return require('../assets/img/motorbike.png');
            case "premier":
                return require('../assets/img/premier.png');
            case "sedan":
                return require('../assets/img/sedan.png');
            case "sedanXL":
                return require('../assets/img/sedanXL.png');
            default:
                return "";
        }
    };

    

    return (
        <main>
            <div className="container" style={{paddingTop:"70px"}}>
                <div className="row">
                    <div className="col-lg-12 offset-lg-1">
                        <section className="page-header">
                            <h2>Choose A Ride</h2>
                            <h5>"Embark on a journey through the picturesque landscapes, where every twist and turn of the road unveils new adventures and memories waiting to be created."</h5>
                        </section>
                        <form onSubmit={handleFormSubmit}>
                            <section className="privacy-policy-section">
                                <div className="row">
                                    <div className="col-md-4 position-relative">
                                        <nav className="nav privay-policy-nav">
                                            <div className="row">
                                                <div className="form-group col-md-12">
                                                    <label>Pickup Location <sup>*</sup></label>
                                                    <input type="text" className="form-control" id="pickupLocation" name="pickupLocation" placeholder="Pickup Location *" value={pickupName} readOnly />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-md-12">
                                                    <label>Dropoff Location <sup>*</sup></label>
                                                    <input type="text" className="form-control" id="dropoffLocation" name="dropoffLocation" placeholder="Dropoff Location *" value={dropoffName} readOnly />
                                                </div>
                                            </div>
                                            <div className="text-center" id="submit" style={{marginTop:'20px'}}>
                                                <div className="media landing-feature" style={{direction:'flex',justifyContent:'center',alignItems:'center'}}>
                                                    
                                                    <div className="media-body">
                                                        <button type="submit" className="btn btn-primary mb-4"> 
                                                            {submitButtonName}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </nav>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="privay-policy-card card">
                                            <div className="card-body">
                                                <h2 className="policy-title">Choose a ride</h2>
                                                <h4 style={{marginBottom:"50px"}} className="policy-title">Recommended</h4>
                                                <div id="auto" className={`highlight-card ${selectedCard === 'auto' ? 'selected' : ''}`} onClick={() => handleCardSelection('auto')}>
                                                    <div className="media landing-feature" style={{direction:'flex',justifyContent:'center',alignItems:'center'}}>
                                                        <span>
                                                            <img style={{ paddingRight: '10px' }} src={require('../assets/img/auto.png')} alt="user" width="100px" className="avatar" />
                                                        </span>
                                                        <div className="media-body" style={{ marginLeft: '20px' }}>
                                                            <h5 style={{fontWeight:'800'}}>VC Auto <span style={{ marginLeft: '20px' }}><img src={require('../assets/img/user.png')} alt="user" width="18px" className="avatar" />3</span></h5>
                                                            <span>3 mins away<br/> {`${departureTime}`} <p> No bargaining, doorstep pick-up</p></span>
                                                            
                                                        </div>
                                                        <div className="media-body">
                                                            <h5 style={{color:'#87c66c'}}><img style={{ paddingRight: '10px' }} src={require('../assets/img/tag.png')} alt="user" width="40px" className="avatar" />25% Off</h5>
                                                            <span style={{fontSize:'26px',fontWeight:'800'}} id="auto_price">
                                                                ₹ 125.00
                                                                <p style={{ textDecoration: 'line-through' }}>₹175.00</p>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="ubergo" className={`highlight-card ${selectedCard === 'ubergo' ? 'selected' : ''}`} onClick={() => handleCardSelection('ubergo')} style={{marginTop:"10px"}}>
                                                    <div className="media landing-feature" style={{direction:'flex',justifyContent:'center',alignItems:'center'}}>
                                                        <span>
                                                            <img style={{ paddingRight: '10px' }} src={require('../assets/img/ubergo.png')} alt="user" width="100px" className="avatar" />
                                                        </span>
                                                        <div className="media-body" style={{ marginLeft: '20px' }}>
                                                            <h5 style={{fontWeight:'800'}}>VC Go<span style={{ marginLeft: '20px' }}><img src={require('../assets/img/user.png')} alt="user" width="18px" className="avatar" />4</span></h5>
                                                            <span>3 mins away<br/> {`${departureTime}`} <p> Affordable, compact</p></span>
                                                            
                                                        </div>
                                                        <div className="media-body">
                                                            <h5 style={{color:'#87c66c'}}><img style={{ paddingRight: '10px' }} src={require('../assets/img/tag.png')} alt="user" width="40px" className="avatar" />20% Off</h5>
                                                            <span style={{fontSize:'26px',fontWeight:'800'}} id="ubergo_price">
                                                                ₹ 195.00
                                                                <p style={{ textDecoration: 'line-through' }}>₹255.00</p>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="motorbike" className={`highlight-card ${selectedCard === 'motorbike' ? 'selected' : ''}`} onClick={() => handleCardSelection('motorbike')}>
                                                    <div className="media landing-feature" style={{direction:'flex',justifyContent:'center',alignItems:'center',justifyItem:'center'}}>
                                                        <span>
                                                            <img style={{ paddingRight: '10px' }} src={require('../assets/img/motorbike.png')} alt="user" width="100px" className="avatar" />
                                                        </span>
                                                        <div className="media-body" style={{ marginLeft: '20px' }}>
                                                            <h5 style={{fontWeight:'800'}}>VC Moto<span style={{ marginLeft: '20px' }}><img src={require('../assets/img/user.png')} alt="user" width="18px" className="avatar" />1</span></h5>
                                                            <span>3 mins away<br/> {`${departureTime}`} <p> Affordable, motorcycle</p></span>
                                                            
                                                        </div>
                                                        <div className="media-body">
                                                            <h5 style={{color:'#87c66c'}}><img style={{ paddingRight: '10px' }} src={require('../assets/img/tag.png')} alt="user" width="40px" className="avatar" />10% Off</h5>
                                                            <span style={{fontSize:'26px',fontWeight:'800'}} id="motorbike_price">
                                                                ₹ 75.00
                                                                <p style={{ textDecoration: 'line-through' }}>₹125.00</p>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <h2 style={{marginTop:"20px"}} className="policy-title">Economy</h2>
                                                <div id="premier" className={`highlight-card ${selectedCard === 'premier' ? 'selected' : ''}`} onClick={() => handleCardSelection('premier')}>
                                                    <div className="media landing-feature" style={{direction:'flex',justifyContent:'center',alignItems:'center'}}>
                                                        <span>
                                                            <img style={{ paddingRight: '10px' }} src={require('../assets/img/premier.png')} alt="user" width="100px" className="avatar" />
                                                        </span>
                                                        <div className="media-body" style={{ marginLeft: '20px' }}>
                                                            <h5 style={{fontWeight:'800'}}>VC Premier <span style={{ marginLeft: '20px' }}><img src={require('../assets/img/user.png')} alt="user" width="18px" className="avatar" />4</span></h5>
                                                            <span>3 mins away<br/> {`${departureTime}`} <p> Comfortable sedans, top-quality drivers</p></span>
                                                            
                                                        </div>
                                                        <div className="media-body">
                                                            <h5 style={{color:'#87c66c'}}><img style={{ paddingRight: '10px' }} src={require('../assets/img/tag.png')} alt="user" width="40px" className="avatar" />18% Off</h5>
                                                            <span style={{fontSize:'26px',fontWeight:'800'}} id="premier_price">
                                                                ₹ 255.00
                                                                <p style={{ textDecoration: 'line-through' }}>₹309.00</p>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div id="sedan" className={`highlight-card ${selectedCard === 'sedan' ? 'selected' : ''}`} onClick={() => handleCardSelection('sedan')}>
                                                    <div className="media landing-feature" style={{direction:'flex',justifyContent:'center',alignItems:'center'}}>
                                                        <span>
                                                            <img style={{ paddingRight: '10px' }} src={require('../assets/img/sedan.png')} alt="user" width="100px" className="avatar" />
                                                        </span>
                                                        <div className="media-body" style={{ marginLeft: '20px' }}>
                                                            <h5 style={{fontWeight:'800'}}>VC Sedan <span style={{ marginLeft: '20px' }}><img src={require('../assets/img/user.png')} alt="user" width="18px" className="avatar" />4</span></h5>
                                                            <span>3 mins away<br/> {`${departureTime}`} <p> Affordable, sedan rides</p></span>
                                                            
                                                        </div>
                                                        <div className="media-body">
                                                            <h5 style={{color:'#87c66c'}}><img style={{ paddingRight: '10px' }} src={require('../assets/img/tag.png')} alt="user" width="40px" className="avatar" />20% Off</h5>
                                                            <span style={{fontSize:'26px',fontWeight:'800'}} id="sedan_price">
                                                                ₹300.00
                                                                <p style={{ textDecoration: 'line-through' }}>₹380.00</p>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div id="sedanXL" className={`highlight-card ${selectedCard === 'sedanXL' ? 'selected' : ''}`} onClick={() => handleCardSelection('sedanXL')}>
                                                    <div className="media landing-feature" style={{direction:'flex',justifyContent:'center',alignItems:'center'}}>
                                                        <span>
                                                            <img style={{ paddingRight: '10px' }} src={require('../assets/img/sedanXL.png')} alt="user" width="100px" className="avatar" />
                                                        </span>
                                                        <div className="media-body" style={{ marginLeft: '20px' }}>
                                                            <h5 style={{fontWeight:'800'}}>VC XL <span style={{ marginLeft: '20px' }}><img src={require('../assets/img/user.png')} alt="user" width="18px" className="avatar" />6</span></h5>
                                                            <span>3 mins away<br/> {`${departureTime}`} <p> Affordable, SUV rides</p></span>
                                                            
                                                        </div>
                                                        <div className="media-body">
                                                            <h5 style={{color:'#87c66c'}}><img style={{ paddingRight: '10px' }} src={require('../assets/img/tag.png')} alt="user" width="40px" className="avatar" />20% Off</h5>
                                                            <span style={{fontSize:'26px',fontWeight:'800'}} id="sedanXL_price">
                                                                ₹450.00
                                                                <p style={{ textDecoration: 'line-through' }}>₹650.00</p>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </form>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Fare;
