import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";


const Consultationfee = () => {
    const navigate = useNavigate();

   // State to manage selected payment method and the corresponding row
   const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
   const [selectedRow, setSelectedRow] = useState(null);

    // Retrieve selected date and time from session storage
    const selectedDate = sessionStorage.getItem("selectedAppointmentDate");
    const selectedTimeSlot = sessionStorage.getItem("selectedAppointmentTime");

    // Retrieve doctor details from session
    const doctorDetails = sessionStorage.getItem("selectedDoctorDetails");
    const selectedDoctorDetails = doctorDetails
        ? JSON.parse(doctorDetails)
        : null;
    // Retrieve label name from session
    const labelName = sessionStorage.getItem("selectedCardData");
    const selectedCardData = labelName ? JSON.parse(labelName) : null;

    useEffect(() => {
        const checkLoginStatus = () => {
            // Check if the user is not logged in
            const userId = sessionStorage.getItem("userId");
            const firstName = sessionStorage.getItem("firstName");
            const phone = sessionStorage.getItem("phone");

            if (!userId || !firstName) {
                // Redirect to the sign-in page
                navigate("/signin");
            }
        };

        // Check the login status on component mount
        checkLoginStatus();

        // Set up a timer to periodically check the login status
        const intervalId = setInterval(() => {
            checkLoginStatus();
        }, 500); // Refresh every 2 seconds

        // Clean up the timer when the component unmounts
        return () => clearInterval(intervalId);
    }, [navigate]);

    const handlePaymentMethodSelection = (method, row) => {
        setSelectedPaymentMethod(method);
        setSelectedRow(row);
    };

    const handlePayment = () => {
        // Handle payment logic here
        // You can redirect to a payment gateway or perform other payment-related actions
        // For now, just console log the selected payment method
        console.log("Selected Payment Method:", selectedPaymentMethod);
    };

    return (
        <main className="page-blog-post" style={{ paddingTop: "30px" }}>
            <div className="container" style={{paddingTop:'100px'}}>
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <section className="blog-post-author-info-section">
                        <div className="row">
                            <div className="col-lg-12">
                                <h5 className="blog-post-author-info-section-title">
                                    Booking Details
                                </h5>
                                <div className="blog-post-author-info media">
                                    <img src={selectedDoctorDetails.image} alt={`Doctor ${selectedDoctorDetails.doctor}`} className="align-self-sm-center blog-post-author-avatar"/>
                                    <div className="media-body">
                                        <div className="d-flex flex-wrap align-items-center">
                                            <h6 className="mr-3 mb-0">{selectedDoctorDetails.doctor}</h6>
                                            <p className="blog-post-date mr-3 mb-0">{selectedCardData ? selectedCardData.name: "New"}</p>
                                        </div>
                                        <p className="author-description">
                                            <img className="blog-post-excerpt" src={require("../assets/img/location.png")} alt="blog" width="18px" />{" "} {selectedDoctorDetails.location}
                                        </p>
                                        <div className="d-flex flex-wrap align-items-center">
                                            <p className="blog-post-date mr-3 mb-0">{selectedDate} {selectedTimeSlot}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </section>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-10 offset-lg-1">
                                    <h4>Preferred Payment</h4>
                                    <section className="career-section">
                                        <div className="card career-card">
                                            <div className="card-body">
                                                <ul className="list-group list-group-flush careers-list-group">
                                                    <li className="list-group-item d-flex align-items-center">
                                                        <input type="radio" id="googlePay" name="paymentMethod" onChange={() => handlePaymentMethodSelection( "Google Pay UPI", "googlePayRow")} style={{ marginRight: "1rem" }}/>
                                                        <label htmlFor="googlePay" className="d-flex align-items-center w-100">
                                                            <div className="d-flex align-items-center mr-2">
                                                                <img className="payment_image" src={require("../assets/img/google-pay.png")} alt="blog"/>
                                                                <h5 className="position-title mb-0 ml-2">Google Pay UPI</h5>
                                                            </div>
                                                            {selectedRow === "googlePayRow" &&
                                                                selectedPaymentMethod && (
                                                                    <Link to={'/summary'} className="btn btn-primary apply-btn ml-auto" onClick={handlePayment}>PAY NOW</Link>
                                                                )}
                                                        </label>
                                                    </li>

                                                    <li className="list-group-item d-flex align-items-center">
                                                        <input type="radio" id="phonePe" name="paymentMethod" onChange={() =>handlePaymentMethodSelection("PhonePe UPI","phonePeRow")} style={{ marginRight: "1rem" }}/>
                                                        <label htmlFor="phonePe" className="d-flex align-items-center w-100">
                                                            <div className="d-flex align-items-center mr-2">
                                                                <img className="payment_image" src={require("../assets/img/phonepe.png")} alt="blog"/>
                                                                <h5 className="position-title mb-0 ml-2">PhonePe UPI</h5>
                                                            </div>
                                                            {selectedRow === "phonePeRow" &&
                                                                selectedPaymentMethod && (
                                                                    <Link to={'/summary'} className="btn btn-primary apply-btn ml-auto" onClick={handlePayment}>PAY NOW</Link>
                                                                )}
                                                        </label>
                                                    </li>
                                                    
                                                    <li className="list-group-item d-flex align-items-center">
                                                        <input type="radio" id="PayonVisit" name="paymentMethod" onChange={() =>handlePaymentMethodSelection("Pay on Visit","PayonVisit")} style={{ marginRight: "1rem" }}/>
                                                        <label htmlFor="PayonVisit" className="d-flex align-items-center w-100">
                                                            <div className="d-flex align-items-center mr-2">
                                                                <img className="payment_image" src={require("../assets/img/pay.png")} alt="blog"/>
                                                                <h5 className="position-title mb-0 ml-2">Pay on Visit</h5>
                                                            </div>
                                                            {selectedRow === "PayonVisit" &&
                                                                selectedPaymentMethod && (
                                                                <Link to={'/summary'} className="btn btn-primary apply-btn ml-auto" onClick={handlePayment}>Proceed</Link>
                                                                )}
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default Consultationfee;