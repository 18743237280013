import React, { useState } from "react";

const PatientDetailsForm = ({ onClose, onSave }) => {
  const [patientName, setPatientName] = useState("");
  const [relationship, setRelationship] = useState("");
  const [dob, setDob] = useState("");
  const [gender, setGender] = useState("");

  const handleSave = () => {
    // Basic validations
    if (!patientName.trim() || !relationship || !dob || !gender) {
      alert("Please fill in all required fields.");
      return;
    }

    // Validate other conditions if needed

    const details = {
      patientName: patientName.trim(),
      relationship,
      dob,
      gender,
    };

    onSave(details);
  };

  return (
    <main>
      <div className="container" style={{padding:'50px'}}>
        <section className="contact-form-wrapper">
          <form>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <select
                    id="relationship"
                    className="form-control"
                    style={{ borderRadius: "10px" }}
                    onChange={(e) => setRelationship(e.target.value)}
                  >
                    <option value="Select">
                      Select
                    </option>
                    <option value="Self">Self</option>
                    <option value="Father">Father</option>
                    <option value="Mother">Mother</option>
                    <option value="Brother">Brother</option>
                    <option value="Sister">Sister</option>
                    <option value="Father-in-Law">Father-in-Law</option>
                    <option value="Mothrer-in-Law">Mothrer-in-Law</option>
                    <option value="Spouse">Spouse</option>
                    <option value="Others">Others</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-12">
                <input
                  style={{ borderRadius: "10px" }}
                  type="text"
                  className="form-control"
                  id="name"
                  name="patientName"
                  placeholder="Patient Name *"
                  value={patientName}
                  onChange={(e) => setPatientName(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-12">
                <input
                  style={{ borderRadius: "10px" }}
                  type="date"
                  className="form-control"
                  id="dob"
                  name="dob"
                  placeholder="DOB *"
                  value={dob}
                  onChange={(e) => setDob(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <select
                    style={{ borderRadius: "10px" }}
                    id="gender"
                    className="form-control"
                    onChange={(e) => setGender(e.target.value)}
                  >
                    <option value="Select Gender">
                      Select Gender
                    </option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Prefer Not to Say">Prefer Not to Say</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-6">
                <button className="btn btn-primary btn-rounded" onClick={onClose}>
                  Cancel
                </button>
              </div>
              <div className="form-group col-md-6">
                <button className="btn btn-primary btn-rounded" onClick={handleSave}>
                  Add Patient
                </button>
              </div>
            </div>
          </form>
        </section>
      </div>
    </main>
  );
};

export default PatientDetailsForm;
