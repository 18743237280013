import logo from './logo.svg';
import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Appheader from './components/appheader/Appheader';
import Footer from './components/footer/Footer';
import Home from './components/home/Home';
import Signin from './components/signin/Signin';
import Signup from './components/signup/Signup';
import Dashboard from './components/dashboard/Dashboard';
import About from './components/about/About';
import Services from './components/services/Services';
import Features from './components/features/Features';
import Contact from './components/contact/Contact';
import Travel from './components/travel/Travel';
import Fare from './components/travel/Fare';
import Payment from './components/travel/Payment';
import Search from './components/travel/Search';
import HealthIndex from './components/health/Home';
import Category from './components/health/Category';
import Doctors from './components/health/Doctors';
import Appointment from './components/health/Appointment'; 
import Confirmation from './components/health/Confirmation'; 
import Consultationfee from './components/health/Consultationfee'; 
import Summary from './components/health/Summary'; 
import Medicinehome from './components/health/Medicinehome'; 
import Cart from './components/health/Cart'; 
import Address from './components/health/Address'; 
import Checkout from './components/health/Checkout'; 
import Orderconfirm from './components/health/Orderconfirm'; 
import Labhome from './components/health/Labhome'; 
import Testcart  from './components/health/Testcart'; 
import DetailsPage  from './components/health/Detailspage '; 
import TestAddress from './components/health/TestAddress'; 
import TestDate from './components/health/TestDate'; 
import TestPayment from './components/health/TestPayment'; 
import TestPaymentSelection from './components/health/TestPaymentSelection'; 
import TestOrder from './components/health/TestOrder'; 
import FoodHome from './components/food/FoodHome'; 
import RestaurantDetails from './components/food/RestaurantDetails'; 
import FoodCart from './components/food/FoodCart'; 
import FoodPayment from './components/food/FoodPayment'; 
import FoodOrder from './components/food/FoodOrder'; 
import HomeEntertain from './components/entertainment/HomeEntertain';
import MovieDetails from './components/entertainment/MovieDetails'




import { useEffect } from 'react';


function App() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBRk3hp-XsOGAkECIX0QGFjRPYc6DomGwg&libraries=places`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, []);
  const [cart, setCart] = useState([]);

  const updateCart = (updatedCart) => {
      setCart(updatedCart);
  };

  return (
    <div className="App">
      <ToastContainer theme='colored' position='top-center'></ToastContainer>
      <BrowserRouter>
        <Appheader></Appheader>
        <Routes>
          <Route path='/' element={<Home/>}></Route>
          <Route path='/about' element={<About/>}></Route>
          <Route path='/signin' element={<Signin/>}></Route>
          <Route path='/signup' element={<Signup/>}></Route>
          <Route path='/services' element={<Services/>}></Route>
          <Route path='/features' element={<Features/>}></Route>
          <Route path='/contact' element={<Contact/>}></Route>
          <Route path='/travel' element={<Travel/>}></Route>
          <Route path='/fare' element={<Fare/>}></Route>
          <Route path='/payment' element={<Payment/>}></Route>
          <Route path='/healthindex' element={<HealthIndex/>}></Route>
          <Route path='/search' element={<Search/>}></Route>
          <Route path='/dashboard' element={<Dashboard/>}></Route>
          <Route path='/category' element={<Category/>}></Route>
          <Route path='/doctors' element={<Doctors/>}></Route>
          <Route path='/appointment' element={<Appointment/>}></Route>
          <Route path='/confirmation' element={<Confirmation/>}></Route>
          <Route path='/consultationfee' element={<Consultationfee/>}></Route>
          <Route path='/summary' element={<Summary/>}></Route>
          <Route path='/medicinehome' element={<Medicinehome cart={cart} updateCart={updateCart} />}/>
          <Route path='/cart' element={<Cart cart={cart} updateCart={updateCart} />}/>
          <Route path='/address' element={<Address/>}></Route>
          <Route path='/checkout' element={<Checkout/>}></Route>
          <Route path='/orderconfirm' element={<Orderconfirm/>}></Route>
          <Route path='/labhome' element={<Labhome/>}></Route>
          <Route path="/details/:packageName/:packageTitle/:packageDetail/:packagePrice/:packagePrerequisites/:packageNumber/:packagesTestnames" element={<DetailsPage />} />
          <Route path='/testcart' element={<Testcart/>}></Route>
          <Route path='/testAddress' element={<TestAddress/>}></Route>
          <Route path='/testDate' element={<TestDate/>}></Route>
          <Route path='/testPayment' element={<TestPayment/>}></Route>
          <Route path='/testPaymentSelection' element={<TestPaymentSelection/>}></Route>
          <Route path='/testOrder' element={<TestOrder/>}></Route>
          <Route path='/foodHome' element={<FoodHome/>}></Route>
          <Route path="/restaurantDetails/:id" element={<RestaurantDetails/>}></Route>
          <Route path='/foodCart' element={<FoodCart/>}></Route>
          <Route path='/foodPayment' element={<FoodPayment/>}></Route>
          <Route path='/foodOrder' element={<FoodOrder/>}></Route>
          <Route path='/homeEntertain' element={<HomeEntertain/>}></Route>
          <Route path='/movieDetails' element={<MovieDetails/>}></Route>






        </Routes>
        <Footer></Footer>
      </BrowserRouter>
    </div>
  );
}

export default App;
