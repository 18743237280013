import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const Category = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(null);

  const specialities = [
    {
      label: "Cardiologist",
      value: "cardiologist",
      image: "cardiologist.gif",
      color: "success",
    },
    {
      label: "Gynecologist",
      value: "gynecologist",
      image: "gynecologist.gif",
      color: "success",
    },
    {
      label: "General Physician",
      value: "generalPhysician",
      image: "General Physician.gif",
      color: "success",
    },
    {
      label: "Nephrologist",
      value: "nephrologist",
      image: "nephrologist.gif",
      color: "success",
    },
    {
      label: "Pediatrician",
      value: "pediatrician",
      image: "Pediatrician.gif",
      color: "success",
    },
    {
      label: "Dentist",
      value: "dentist",
      image: "dentist.gif",
      color: "success",
    },
    {
      label: "Dermatologist",
      value: "dermatologist",
      image: "Dermatologist.gif",
      color: "success",
    },
    {
      label: "Surgery",
      value: "surgery",
      image: "Surgery.gif",
      color: "success",
    },
    {
      label: "Radiologist",
      value: "radiologist",
      image: "Radiologist.gif",
      color: "success",
    },
    {
      label: "Urology",
      value: "urology",
      image: "Urology.gif",
      color: "success",
    },
    {
      label: "Neurologist",
      value: "neurologist",
      image: "Neurologist.gif",
      color: "success",
    },
    {
      label: "Psychologist",
      value: "psychologist",
      image: "Psycologist.gif",
      color: "success",
    },
  ];

  const handleOptionSelect = (value) => {
    setSelectedOption(value);
  };

  const handleContinue = () => {
    // Check if a speciality is selected
    if (selectedOption) {
      // Get the selected speciality data
      const selectedSpeciality = specialities.find(
        (speciality) => speciality.value === selectedOption
      );

      // Store selected card data in session
    sessionStorage.setItem(
        "selectedCardData",
        JSON.stringify({
          name: selectedSpeciality.label,
          image: selectedSpeciality.image,
        })
      );
  

      // Navigate to the next page
      navigate("/doctors");
    } else {
      // Handle case where no speciality is selected
      alert("Please select a speciality before continuing.");
    }
  };

  useEffect(() => {
    const checkLoginStatus = () => {
      // Check if the user is not logged in
      const userId = sessionStorage.getItem("userId");
      const firstName = sessionStorage.getItem("firstName");

      if (!userId || !firstName) {
        // Redirect to the sign-in page
        navigate("/signin");
      }
    };

    // Check the login status on component mount
    checkLoginStatus();

    // Set up a timer to periodically check the login status
    const intervalId = setInterval(() => {
      checkLoginStatus();
    }, 500); // Refresh every 2 seconds

    // Clean up the timer when the component unmounts
    return () => clearInterval(intervalId);
  }, [navigate]);

  return (
    <main className="page-about" style={{paddingTop:"100px",paddingBottom:'50px'}}>
      <section style={{ paddingTop: "1rem" }}>
        <div className="container">
          <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
            Choose Speciality
          </h2>
          <div className="row">
            {specialities.map((speciality, index) => (
              <label
                key={index}
                className={`col-lg-3 mb-4 card pricing-card_1 border-${speciality.color} ${
                  selectedOption === speciality.value ? "selected" : ""
                }`}
              >
                <input
                  type="radio"
                  name="speciality"
                  value={speciality.value}
                  onChange={() => handleOptionSelect(speciality.value)}
                  style={{ display: "none" }} // Hide the radio button
                />
                <div className="card-body">
                  <h3 className={`mb-0 text-${speciality.color}`}>
                    <img
                      src={require(`../assets/img/${speciality.image}`)}
                      alt="user"
                      width="80px"
                      className="avatar"
                    />
                  </h3>
                  <h6 className="mb-1">{speciality.label}</h6>
                </div>
              </label>
            ))}
          </div>
          <div className="text-center mt-4">
            <button
              className="btn btn-primary"
              onClick={handleContinue}
              disabled={!selectedOption}
            >
              Continue
            </button>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Category;
