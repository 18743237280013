// Import necessary dependencies and components
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import usePlacesAutocomplete from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import { getGeocode, getLatLng } from "use-places-autocomplete";
import "../../external";

// Define libraries, container style, and initial center coordinates
const libraries = ["places"];
const containerStyle = {
  borderRadius: "20px",
  width: "100%",
  height: "570px",
};

const center = {
  lat: 17.447052623830057,
  lng: 78.36428807920888,
};

// Define the Travel component
const Travel = () => {
  // Initialize the navigate function from react-router-dom
  const navigate = useNavigate();

  // State variables for map, Google Maps API, pickup, and dropoff locations
  const [map, setMap] = useState(null);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyBRk3hp-XsOGAkECIX0QGFjRPYc6DomGwg", // Replace with your actual API key
    libraries,
  });
  const [pickupLocation, setPickupLocation] = useState(center);
  const [pickupName, setPickupName] = useState("");
  const [dropoffLocation, setDropoffLocation] = useState(null);
  const [dropoffName, setDropoffName] = useState("");
  const [markerRef, setMarkerRef] = useState(null);

  // Check login status on component mount and set an interval to keep checking
  useEffect(() => {
    const checkLoginStatus = () => {
      const userId = sessionStorage.getItem("userId");
      const firstName = sessionStorage.getItem("firstName");

      if (!userId || !firstName) {
        navigate("/signin");
      }
    };

    checkLoginStatus();

    const intervalId = setInterval(() => {
      checkLoginStatus();
    }, 500);

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, [navigate]);

  // Use Places Autocomplete for pickup and dropoff suggestions
  const { suggestions: pickupSuggestions, setValue: setPickupValue, clearSuggestions: clearPickupSuggestions } = usePlacesAutocomplete();
  const { suggestions: dropoffSuggestions, setValue: setDropoffValue, clearSuggestions: clearDropoffSuggestions } = usePlacesAutocomplete();

  // Callback function for when the map is loaded
  const onMapLoad = (map) => {
    setMap(map);
  };

  // Callback function for map click
  const onMapClick = (event) => {
    setPickupLocation({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
    setDropoffLocation(null);
    setPickupName("");
    setDropoffName("");
    clearPickupSuggestions();
    clearDropoffSuggestions();
  };

  // Handle selection of pickup location from suggestions
  const handleSelect = ({ description }) => {
    setPickupValue(description, false);
    setPickupName(description);
    clearPickupSuggestions();
  };

  // Handle selection of dropoff location from suggestions
  const handleDropoffSelect = ({ description }) => {
    setDropoffValue(description, false);
    setDropoffName(description);
    clearDropoffSuggestions();
  };

  // Handle click outside the input fields to clear suggestions
  const handleClickOutside = () => {
    clearPickupSuggestions();
    clearDropoffSuggestions();
  };

  // Create a ref for handling click outside the input fields
  const clickOutsideRef = useOnclickOutside(() => {
    handleClickOutside();
  });

  // Form submission handler
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevents the default form submission behavior

    // Store pickup and dropoff names in session storage
    sessionStorage.setItem("pickupName", pickupName);
    sessionStorage.setItem("dropoffName", dropoffName);

    // Navigate to the next page (replace '/fare' with your actual next page route)
    navigate("/fare");
  };

  // useEffect hook to run code when component mounts
  useEffect(() => {
    // Retrieve pickup and dropoff names from session storage
    const pickupNameFromSession = sessionStorage.getItem("pickupName");
    const dropoffNameFromSession = sessionStorage.getItem("dropoffName");

    // Log the retrieved values to the console
    console.log("Pickup Name from Session:", pickupNameFromSession);
    console.log("Dropoff Name from Session:", dropoffNameFromSession);

    // Set the retrieved values to the state variables
    setPickupName(pickupNameFromSession || "");
    setDropoffName(dropoffNameFromSession || "");
  }, []); // The empty dependency array ensures that this useEffect runs only once

  return (
    <section className="py-5 mb-5">
      <div className="container" style={{paddingTop:"70px"}}>
        <div className="row">
          <div className="col-md-4 mb-5 mb-md-0 travel_1">
            <section className="contact-form-wrapper">
              <h2 style={{ paddingBottom: "30px" }}>Get a ride</h2>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="form-group col-md-12" ref={clickOutsideRef}>
                    <label>Pickup Location <sup>*</sup></label>
                    <input
                      type="text"
                      name="pickupLocation"
                      className="form-control"
                      placeholder="Pickup Location *"
                      onChange={(e) => {
                        setPickupValue(e.target.value);
                        setPickupName(e.target.value);
                      }}
                      value={pickupName}
                      required
                    />
                    {pickupSuggestions.length > 0 && (
                      <ul>
                        {pickupSuggestions.map((suggestion, index) => (
                          <li key={index} onClick={() => handleSelect(suggestion)}>
                            {suggestion.description}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-12">
                    <label>Dropoff Location <sup>*</sup></label>
                    <input type="text" className="form-control" name="dropoffLocation" placeholder="Dropoff Location *" onChange={(e) => { setDropoffValue(e.target.value); setDropoffName(e.target.value); }} value={dropoffName} required />
                    {dropoffSuggestions.length > 0 && (
                      <ul>
                        {dropoffSuggestions.map((suggestion, index) => (
                          <li key={index} onClick={() => handleDropoffSelect(suggestion)}>
                            {suggestion.description}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
                <div className="text-center">
                  <button type="submit" className="btn btn-primary mb-4">
                    Search
                  </button>
                </div>
              </form>
            </section>
          </div>
          <div className="col-md-8">
            {isLoaded && (
              <GoogleMap mapContainerStyle={containerStyle} center={pickupLocation} zoom={12} onLoad={onMapLoad} onClick={onMapClick} >
                <Marker position={pickupLocation} draggable={true} onDragEnd={(e) => setPickupLocation({ lat: e.latLng.lat(), lng: e.latLng.lng() })} ref={(marker) => setMarkerRef(marker)} />
                {dropoffLocation && (
                  <Marker position={dropoffLocation} draggable={true} onDragEnd={(e) => setDropoffLocation({ lat: e.latLng.lat(), lng: e.latLng.lng() })} />
                )}
              </GoogleMap>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Travel;
