import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const checkLoginStatus = () => {
            // Check if the user is not logged in
            const userId = sessionStorage.getItem('userId');
            const firstName = sessionStorage.getItem('firstName');

            if (!userId || !firstName) {
                // Redirect to the sign-in page
                navigate('/signin');
            }
        };

        // Check the login status on component mount
        checkLoginStatus();

        // Set up a timer to periodically check the login status
        const intervalId = setInterval(() => {
            checkLoginStatus();
        }, 500); // Refresh every 2 seconds

        // Clean up the timer when the component unmounts
        return () => clearInterval(intervalId);
    }, [navigate]);

    return (
        <div className="content">
            <div className="container"  style={{paddingTop:"100px"}}>
            <h1>Dashboard Page</h1>
            {/* Display a welcome message if the user is logged in */}
            {sessionStorage.getItem('userId') && (
                <p>Welcome to the home page, {sessionStorage.getItem('firstName')}!</p>
            )}
            </div>
        </div>
    );
}

export default Dashboard;
