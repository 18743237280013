import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import '../../external';

const containerStyle = {
    width: '100%',
    height: '250px',
};

const center = {
    lat: 17.447052623830057,
    lng: 78.36428807920888,
};

const Search = () => {
    const navigate = useNavigate();
    const [customerlist, setCustomerList] = useState(null);
    const [selectedPaymentOption, setSelectedPaymentOption] = useState(null);

    useEffect(() => {
        // Retrieve the selected card data from session
        const selectedCardData = sessionStorage.getItem("selectedCardData");

        if (selectedCardData) {
            // Parse the JSON data
            const parsedData = JSON.parse(selectedCardData);

            // Update the state with the selected card data
            setCustomerList(parsedData);
        }
    }, []);

    // Function to handle payment option selection
    const handlePaymentOptionSelection = (paymentOption) => {
        setSelectedPaymentOption(paymentOption);
    };

    // Function to handle continue button click
    const handleContinueButtonClick = () => {
        // Perform any necessary actions before navigating to the next page
        // For example, you can store the selected payment option in session
        sessionStorage.setItem("selectedPaymentOption", selectedPaymentOption);

        // Navigate to the next page (replace "/nextpage" with the actual path)
        navigate("/payment");
    };

    return (
        <section className="py-5 mb-5" >
            <div className="container" style={{paddingTop:"70px"}}>
                <h2>Choose A Ride</h2>
                <h5>"Embark on a journey through the picturesque landscapes, where every twist and turn of the road unveils new adventures and memories waiting to be created."</h5>
                <div className="row">
                    {customerlist && (
                        <div className="col-lg-4 mb-4">
                            <div className="card pricing-card border-warning">
                                <div className="card-body">
                                    <h3 className="mb-1">{customerlist.name}</h3>
                                    <h3 className="mb-1 text-warning">{customerlist.price}</h3>
                                    <p className="mb-4"><img src={customerlist.img} alt="Card" width="100px" /></p>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="col-lg-4 mb-4">
                        <div className="card pricing-card border-primary">
                            <div className="">
                                {/* Google Map Component */}
                                <LoadScript googleMapsApiKey="AIzaSyBRk3hp-XsOGAkECIX0QGFjRPYc6DomGwg">
                                    <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={8}>
                                        <Marker position={center} />
                                    </GoogleMap>
                                </LoadScript>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-4">
                        <div className="card pricing-card border-success" style={{textAlign:'left'}}>
                            <div className="card-body">
                                <h6>Payment Method</h6>
                                <div>
                                    <label>
                                        <input type="radio" name="paymentOption" value="creditCard" checked={selectedPaymentOption === "creditCard"} onChange={() => handlePaymentOptionSelection("creditCard")} />
                                        <span style={{marginLeft:'10px'}}>Credit Card</span>
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        <input type="radio" name="paymentOption" value="paypal" checked={selectedPaymentOption === "paypal"} onChange={() => handlePaymentOptionSelection("paypal")} />
                                        <span style={{marginLeft:'10px'}}>PayPal</span>
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        <input type="radio" name="paymentOption" value="upi" checked={selectedPaymentOption === "upi"} onChange={() => handlePaymentOptionSelection("upi")} />
                                        <span style={{marginLeft:'10px'}}>UPI</span>
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        <input type="radio" name="paymentOption" value="cash" checked={selectedPaymentOption === "cash"} onChange={() => handlePaymentOptionSelection("cash")} />
                                        <span style={{marginLeft:'10px'}}>Cash</span>
                                    </label>
                                </div>
                                <div style={{textAlign:'center'}}>
                                    <button className="btn btn-outline-success btn-rounded" onClick={handleContinueButtonClick} disabled={!selectedPaymentOption}>
                                        Continue
                                    </button>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Search;
