import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import CityModal from "./CityModal"; // Import the modal component
import foodlogo from "../assets/images/entertainment/movies/logo.gif";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

const HomeEntertain = () => {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [selectedCity, setSelectedCity] = useState(null); // Define selectedCity state

    
    // Function to update selected city name
    const handleCitySelect = (cityName) => {
        setSelectedCity(cityName);
    };



    useEffect(() => {
        const checkLoginStatus = () => {
            // Check if the user is not logged in
            const userId = sessionStorage.getItem('userId');
            const firstName = sessionStorage.getItem('firstName');

            if (!userId || !firstName) {
                // Redirect to the sign-in page
                navigate('/signin');
            }
        };

        // Check the login status on component mount
        checkLoginStatus();

        // Set up a timer to periodically check the login status
        const intervalId = setInterval(() => {
            checkLoginStatus();
        }, 500); // Refresh every 2 seconds

        // Clean up the timer when the component unmounts
        return () => clearInterval(intervalId);
    }, [navigate]);
     // Settings for react-slick slider
     const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    const item = [
        {
            id: 1,
            name: "Lal Salaam",
            type:"Action/Drama",
            video: require('../assets/images/entertainment/movies/lal salaam.mp4'),
            image: require('../assets/images/entertainment/movies/lal salaam.avif'),
            bg: require('../assets/images/entertainment/movies/movie_thumb/lalsalaam.png'),
        },
        {
            id: 2,
            name: "Eagle",
            type:"Action/Drama/Thriller",
            video: require('../assets/images/entertainment/movies/Eagle.mp4'),
            image: require('../assets/images/entertainment/movies/eagle.avif'),
            bg: require('../assets/images/entertainment/movies/movie_thumb/Eagle.png'),
        },

        {
            id: 3,
            name: "Hanu-Man",
            type:"Action/Adventure/Fantasy",
            video: require('../assets/images/entertainment/movies/Hanu-Man.mp4'),
            image: require('../assets/images/entertainment/movies/hanu-man.avif'),
            bg: require('../assets/images/entertainment/movies/movie_thumb/hanuman.png'),
        },
        {
            id: 4,
            name: "Yatra 2",
            type:"Biography/Drama",
            video: require('../assets/images/entertainment/movies/Yatra 2.mp4'),
            image: require('../assets/images/entertainment/movies/yatra2.avif'),
            bg: require('../assets/images/entertainment/movies/movie_thumb/yatra.png'),
        },
        {
            id: 5,
            name: "Teri Baaton Mein Aisa Uljha Jiya",
            type:"Comedy/Family/Romantic",
            video: require('../assets/images/entertainment/movies/Teri Baaton Mein Aisa Uljha Jiya.mp4'),
            image: require('../assets/images/entertainment/movies/Teri Baaton Mein Aisa Uljha Jiya.avif'),
            bg: require('../assets/images/entertainment/movies/movie_thumb/Teri.png'),
        },
        {
            id: 6,
            name: "Ambajipeta Marriage Band",
            type:"Comedy/Drama",
            video: require('../assets/images/entertainment/movies/Ambajipeta Marriage Band.mp4'),
            image: require('../assets/images/entertainment/movies/Ambajipeta Marriage Band.avif'),
            bg: require('../assets/images/entertainment/movies/movie_thumb/Ambajipeta.jpg'),
        },
        {
            id: 7,
            name: "Fighter",
            type:"Action/Thiller",
            video: require('../assets/images/entertainment/movies/Fighter.mp4'),
            image: require('../assets/images/entertainment/movies/Fighter.avif'),
            bg: require('../assets/images/entertainment/movies/movie_thumb/Fighter.png'),
        },
        {
            id: 8,
            name: "Guntur Kaaram",
            type:"Action/Drama",
            video: require('../assets/images/entertainment/movies/Guntur Kaaram.mp4'),
            image: require('../assets/images/entertainment/movies/Guntur Kaaram.avif'),
            bg: require('../assets/images/entertainment/movies/movie_thumb/guntur.jpg'),
        },
        {
            id: 9,
            name: "Argylle",
            type:"Action/Comedy",
            video: require('../assets/images/entertainment/movies/Argylle.mp4'),
            image: require('../assets/images/entertainment/movies/Argylle.avif'),
            bg: require('../assets/images/entertainment/movies/movie_thumb/Argylle.jpg'),
        },{
            id: 10,
            name: "Sahadev",
            type:"Action/Drama/Thriller",
            video: require('../assets/images/entertainment/movies/Sahadev.mp4'),
            image: require('../assets/images/entertainment/movies/Sahadev.avif'),
            bg: require('../assets/images/entertainment/movies/movie_thumb/Sahadev.png'),
        }
    ];

    const responsive_movie = {
        0: { items: 1 },
        568: { items: 2 },
        1024: { items: 4 },
    };
    
 

    const renderCarouselItems_movie = () => {
        // Shuffle the item array
        const shuffledItems = [...item];
        for (let i = 10 - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffledItems[i], shuffledItems[j]] = [shuffledItems[j], shuffledItems[i]];
        }
    
        const groupedItems_movie = [];
    
        const handleItemClick = (item, event) => {
            // Prevent the default action of the link
            event.preventDefault();
            // Store the selected item in the session
            sessionStorage.setItem('selectedMovie', JSON.stringify(item));
            // Redirect to the details page
            navigate('/movieDetails');
            // Stop event propagation to prevent scrolling the items
            event.stopPropagation();
        };
        
    
        for (let i = 0; i < 10; i += 1) {
            const item = shuffledItems[i];
    
            groupedItems_movie.push(
                <div className="item" key={i}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="team-member-card">
                                <Link className="entertain_items" onClick={(event) => handleItemClick(item, event)}>
                                    <img src={item.image} alt={item.name} className="entertain_item_details" />
                                    <h6 style={{ color: '#414449', textAlign: 'center', paddingBottom: '5px',fontSize:'16px'  }} className="mb-0">{item.name}</h6>
                                    <p style={{ color: '#414449', textAlign: 'center', paddingBottom: '5px', fontSize:'14px' }} className="mb-0">{item.type}</p>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    
        return groupedItems_movie;
    };

    // Custom render for the AliceCarousel buttons
    const renderPrevButton_1 = ({ isDisabled }) => (
        <button
            disabled={isDisabled}
            className="btn btn-entertain active alice-carousel-btn alice-carousel-prev-btn"
            style={{ position: 'absolute', top: 100, left: -20, zIndex:'9999' }}
        >
            &lt;
        </button>
    );

    const renderNextButton_1 = ({ isDisabled }) => (
        <button
            disabled={isDisabled}
            className="btn btn-entertain active alice-carousel-btn alice-carousel-next-btn"
            style={{ position: 'absolute', top: 100, right: -20, zIndex:'9999' }}
        >
            &gt;
        </button>
    );
    

  

    return (
        <section>
            <header className="foi-header landing-header bg_header" style={{paddingTop:'80px'}}>
                <div className="container-fluid bg_navbar_movie">
                    <nav className="navbar navbar-expand-lg navbar-light foi-navbar" style={{padding:'10px'}}>
                        <Link className="navbar-brand" to={'/homeEntertain'}>
                            <img src={foodlogo} alt="Vasudha Connect" width="150px"/>
                        </Link>
                        <button className="navbar-toggler d-lg-none" type="button" data-toggle="collapse" data-target="#collapsibleNavId_1" aria-controls="collapsibleNavId_1" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="collapsibleNavId_1">
                            <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
                                <li className="nav-item">
                                    <Link to={'/homeEntertain'} style={{color:'#000', fontWeight:'600'}} className="nav-link" onClick={(event) => event.preventDefault()}>Movies</Link>
                                </li>
                                <li className="nav-item">
                                    <Link style={{color:'#000', fontWeight:'600'}} className="nav-link" onClick={(event) => event.preventDefault()}>Events</Link>
                                </li>
                                <li className="nav-item">
                                    <Link style={{color:'#000', fontWeight:'600'}} className="nav-link" onClick={(event) => event.preventDefault()}>Amusement Parks</Link>
                                </li>
                                <li className="nav-item">
                                    <Link style={{ color: "#000", fontWeight: "600" }} className="nav-link" onClick={(event) => { event.preventDefault(); setShowModal(true); }}>
                                        {selectedCity ? selectedCity : "Select City"} <i className="fa-solid fa-chevron-down"></i>
                                    </Link>
                                </li>
                            </ul>
                            <ul className="navbar-nav mt-2 mt-lg-0">
                                <li className="nav-item">
                                    <form className="form-inline my-2 my-lg-0">
                                        <input className="form-control mr-sm-2" type="search" placeholder="Search for Movies, Events, Plays, Sports and Activities" aria-label="Search"/>
                                        <button className="btn btn-outline-entertain my-2 my-sm-0" type="submit">Search</button>
                                    </form>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </header>
            <CityModal show={showModal} onHide={() => setShowModal(false)} onSelectCity={handleCitySelect} />
            <section className="slider_home"  style={{paddingTop:'20px'}}>
                {/* Slider section */}
                <div className="container">
                    <Slider {...settings}>
                        <div>
                            <img src={require('../assets/images/entertainment/slide-4.png')} alt="Slack" width="1000" className="img-fluid mx-auto d-block"/>
                        </div>
                        <div>
                            <img src={require('../assets/images/entertainment/slide-1.png')} alt="Slack" width="1000" className="img-fluid mx-auto d-block"/>
                        </div>
                        <div>
                            <img src={require('../assets/images/entertainment/slide-2.png')} alt="Slack" width="1000" className="img-fluid mx-auto d-block"/>
                        </div>
                        <div>
                            <img src={require('../assets/images/entertainment/slide-3.png')} alt="Slack" width="1000" className="img-fluid mx-auto d-block"/>
                        </div>
                    </Slider>
                </div>
            </section>
            
            {/* AliceCarousel section */}
            <section style={{paddingTop:'50px',paddingBottom:'50px'}}>
                <div className="container" style={{ position: 'relative', borderBottom:'3px solid #f0f0f5' }}>
                {/* AliceCarousel section */}
                    <h4 style={{paddingBottom:'20px'}}>Recommended Movies</h4>
                    <AliceCarousel mouseTracking items={renderCarouselItems_movie()} responsive={responsive_movie} renderPrevButton={renderPrevButton_1} renderNextButton={renderNextButton_1} disableDotsControls={true} />
                </div>
            </section>
        </section>
    );
}

export default HomeEntertain;
