import React, { useEffect,useState } from "react";
import { useNavigate } from "react-router-dom";

const Summary = () => {
    const navigate = useNavigate();

    // Retrieve selected date and time from session storage
    const selectedDate = sessionStorage.getItem("selectedAppointmentDate");
    const selectedTimeSlot = sessionStorage.getItem("selectedAppointmentTime");

    // Retrieve doctor details from session
    const doctorDetails = sessionStorage.getItem("selectedDoctorDetails");
    const selectedDoctorDetails = doctorDetails
        ? JSON.parse(doctorDetails)
        : null;
    // Retrieve label name from session
    const labelName = sessionStorage.getItem("selectedCardData");
    const selectedCardData = labelName ? JSON.parse(labelName) : null;

    useEffect(() => {
        const checkLoginStatus = () => {
            // Check if the user is not logged in
            const userId = sessionStorage.getItem('userId');
            const firstName = sessionStorage.getItem('firstName');

            if (!userId || !firstName) {
                // Redirect to the sign-in page
                navigate('/signin');
            }
        };

        // Check the login status on component mount
        checkLoginStatus();

        // Set up a timer to periodically check the login status
        const intervalId = setInterval(() => {
            checkLoginStatus();
        }, 500); // Refresh every 2 seconds

        // Clean up the timer when the component unmounts
        return () => clearInterval(intervalId);
    }, [navigate]);

    return (
        <main class="page-blog-post" style={{paddingTop:'40px', paddingBottom:'40px'}}>
            <div class="container" style={{paddingTop:'100px'}}>
                <div class="row">
                    <div class="col-lg-10 offset-lg-1">
                        <section class="blog-post-content">
                            <div class="row">
                                <div class="col-lg-10 offset-lg-1">
                                    <blockquote>
                                        <p>Appointment Confirmation</p>
                                    </blockquote>
                                    <p >Hi <span style={{fontWeight:'800'}}>{sessionStorage.getItem('firstName')}</span>,</p>
                                    <p class="mb-5">Your Appointment with <span style={{fontWeight:'800'}}>{selectedDoctorDetails.doctor}</span> is confirmed. See below for the full details.</p>
                                </div>
                            </div>
                        </section>
                        <section class="blog-post-author-info-section">
                            <div class="row">
                                <div class="col-lg-10 offset-lg-1">
                                    <h5 class="blog-post-author-info-section-title">Order Summary</h5>
                                    <div class="blog-post-author-info media">
                                        <div class="media-body">
                                            <div class="d-flex flex-wrap align-items-center">
                                                <h6 class="mr-3 mb-0">Category: <span style={{fontWeight:'500'}}>{selectedCardData.name}</span></h6>
                                            </div>
                                            <div class="d-flex flex-wrap align-items-center">
                                                <h6 class="mr-3 mb-0">Date: <span style={{fontWeight:'500'}}>{selectedDate}</span></h6>
                                            </div>
                                            <div class="d-flex flex-wrap align-items-center">
                                                <h6 class="mr-3 mb-0">Time: <span style={{fontWeight:'500'}}>{selectedTimeSlot}</span></h6>
                                            </div>
                                            <div class="d-flex flex-wrap align-items-center">
                                                <h6 class="mr-3 mb-0">Location: <span style={{fontWeight:'500'}}>{selectedDoctorDetails.location}</span></h6>
                                            </div><br/>
                                            <div class="d-flex flex-wrap align-items-center">
                                                <h6 class="mr-3 mb-0">Notes: <span style={{fontWeight:'500'}}>N/A</span></h6>
                                            </div><br/>

                                            <div class="d-flex flex-wrap align-items-center">
                                                <span style={{fontWeight:'500'}}>We look forward to see you soon.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex flex-wrap align-items-center">
                                        <span style={{fontWeight:'500', fontSize:'12px', textAlign:'justify', color:'#989898'}}>
                                            If you need to cancel your appointment, we respectfully request atleast 48 hours' notice. Any cancellation or rescheduling made less than 48 hours before the scheduled appointment will result in a cancellation fee. 
                                            The amount of the fee will be equal to 15% of the reserved services. We do understand that things dont's always go as planned. In case of extraordinary circumstances, please call us directly on 987-654-3210 to make an exception.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Summary;
