import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const FoodPayment = () => {
    const navigate = useNavigate();
    // State to manage selected payment method and the corresponding row
   const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
   const [selectedRow, setSelectedRow] = useState(null);

    // Load cart data from sessionStorage on component mount
    const [cart, setCart] = useState(() => {
        const storedCart = sessionStorage.getItem('cart');
        return storedCart ? JSON.parse(storedCart) : [];
    });

     // Retrieve address details from session storage
     const addressDetails = JSON.parse(sessionStorage.getItem('addressDetails'));

    useEffect(() => {
        const checkLoginStatus = () => {
            // Check if the user is not logged in
            const userId = sessionStorage.getItem('userId');
            const firstName = sessionStorage.getItem('firstName');

            if (!userId || !firstName) {
                // Redirect to the sign-in page
                navigate('/signin');
            }
        };

        // Check the login status on component mount
        checkLoginStatus();

        // Set up a timer to periodically check the login status
        const intervalId = setInterval(() => {
            checkLoginStatus();
        }, 500); // Refresh every 2 seconds

        // Clean up the timer when the component unmounts
        return () => clearInterval(intervalId);
    }, [navigate]);

    // Filter added items
    const addedItems = cart.filter((item) => item.count > 0);

    // Calculate the total price of added items in the cart
    const totalPrice = addedItems.reduce((total, item) => total + item.price * item.count, 0);

    // Function to handle increasing the count of an item in the cart
    const increaseItemCount = (itemId) => {
        const updatedCart = cart.map((item) =>
            item.id === itemId ? { ...item, count: item.count + 1 } : item
        );
        setCart(updatedCart);
        sessionStorage.setItem('cart', JSON.stringify(updatedCart));

        // Notify the Medicinehome page about the change
        window.dispatchEvent(new Event('cartUpdated'));
    };
    // Function to handle decreasing the count of an item in the cart
    const decreaseItemCount = (itemId) => {
        const updatedCart = cart.map((item) =>
            item.id === itemId && item.count > 0 ? { ...item, count: item.count - 1 } : item
        );
        setCart(updatedCart);
        sessionStorage.setItem('cart', JSON.stringify(updatedCart));

        // Notify the Medicinehome page about the change
        window.dispatchEvent(new Event('cartUpdated'));
    };
    const handlePaymentMethodSelection = (method, row) => {
        setSelectedPaymentMethod(method);
        setSelectedRow(row);
    };

    const handlePayment = () => {
        // Handle payment logic here
        // You can redirect to a payment gateway or perform other payment-related actions
        // For now, just console log the selected payment method
        console.log("Selected Payment Method:", selectedPaymentMethod);
    };

    return (
        <main>
            <div className="container" style={{paddingTop:'100px'}}>
                <div className="row">
                    <div className="col-lg-12">
                        <section className="privacy-policy-section">
                            <div className="row">
                                <div class="col-md-12">
                                    <section class="contact-form-wrapper">
                                        <section className="career-section">
                                            <h4>Preferred Payment</h4>
                                            <div className="card career-card">
                                                <div className="card-body">
                                                    <ul className="list-group list-group-flush careers-list-group">
                                                        <li className="list-group-item d-flex align-items-center">
                                                            <input type="radio" id="googlePay" name="paymentMethod" onChange={() => handlePaymentMethodSelection( "Google Pay UPI", "googlePayRow")} style={{ marginRight: "1rem" }}/>
                                                            <label htmlFor="googlePay" className="d-flex align-items-center w-100">
                                                                <div className="d-flex align-items-center mr-2">
                                                                    <img className="payment_image" src={require("../assets/img/google-pay.png")} alt="blog"/>
                                                                    <h5 className="position-title mb-0 ml-2">Google Pay UPI</h5>
                                                                </div>
                                                                {selectedRow === "googlePayRow" &&
                                                                    selectedPaymentMethod && (
                                                                        <Link to={'/foodOrder'} className="btn btn-food apply-btn ml-auto" onClick={handlePayment}>PAY NOW</Link>
                                                                    )}
                                                            </label>
                                                        </li>

                                                        <li className="list-group-item d-flex align-items-center">
                                                            <input type="radio" id="phonePe" name="paymentMethod" onChange={() =>handlePaymentMethodSelection("PhonePe UPI","phonePeRow")} style={{ marginRight: "1rem" }}/>
                                                            <label htmlFor="phonePe" className="d-flex align-items-center w-100">
                                                                <div className="d-flex align-items-center mr-2">
                                                                    <img className="payment_image" src={require("../assets/img/phonepe.png")} alt="blog"/>
                                                                    <h5 className="position-title mb-0 ml-2">PhonePe UPI</h5>
                                                                </div>
                                                                {selectedRow === "phonePeRow" &&
                                                                    selectedPaymentMethod && (
                                                                        <Link to={'/foodOrder'} className="btn btn-food apply-btn ml-auto" onClick={handlePayment}>PAY NOW</Link>
                                                                    )}
                                                            </label>
                                                        </li>
                                                        
                                                        <li className="list-group-item d-flex align-items-center">
                                                            <input type="radio" id="Payondelivery" name="paymentMethod" onChange={() =>handlePaymentMethodSelection("Cash On Delivery","Payondelivery")} style={{ marginRight: "1rem" }}/>
                                                            <label htmlFor="Payondelivery" className="d-flex align-items-center w-100">
                                                                <div className="d-flex align-items-center mr-2">
                                                                    <img className="payment_image" src={require("../assets/img/pay.png")} alt="blog"/>
                                                                    <h5 className="position-title mb-0 ml-2">Cash On Delivery</h5>
                                                                </div>
                                                                {selectedRow === "Payondelivery" &&
                                                                    selectedPaymentMethod && (
                                                                    <Link to={'/foodOrder'} className="btn btn-food apply-btn ml-auto" onClick={handlePayment}>Proceed</Link>
                                                                    )}
                                                            </label>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </section>
                                    </section>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default FoodPayment;
