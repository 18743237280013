import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

const Medicinehome = () => {
    const navigate = useNavigate();
    const location = useLocation();

    // Load cart data from sessionStorage on component mount
    const [cart, setCart] = useState(() => {
        const storedCart = sessionStorage.getItem('cart');
        return storedCart ? JSON.parse(storedCart) : [];
    });

    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const checkLoginStatus = () => {
            const userId = sessionStorage.getItem('userId');
            const firstName = sessionStorage.getItem('firstName');

            if (!userId || !firstName) {
                navigate('/signin');
            }
        };

        checkLoginStatus();

        const intervalId = setInterval(() => {
            checkLoginStatus();
        }, 500);

        return () => clearInterval(intervalId);
    }, [navigate]);

    const products = [
        {
            id: 1,
            name: "EAGLE WEIGHING SCALE - 1 Unit",
            imgSrc: require('../assets/img/weight.jpg'),
            type: "Boxes",
            price: 1999.00,
        },
        {
            id: 2,
            name: "OMNIGEL GEL 75GM - 75gm Gel",
            imgSrc: require('../assets/img/omni.jpg'),
            type: "Tube",
            price: 175.00,
        },
        {
            id: 3,
            name: "JUMPER PULSE OXYMETERS - 1 Unit",
            imgSrc: require('../assets/img/jumper.jpg'),
            type: "Boxes",
            price: 2499.00,
        },
        {
            id: 4,
            name: "DR.MOREPEN GLUCOMETER - 1 Kit",
            imgSrc: require('../assets/img/glucos.jpg'),
            type: "Boxes",
            price: 685.00,
        },
        {
            id: 5,
            name: "VWash Plus Expert Intimate Hygien 100ML - 100ml Liquid",
            imgSrc: require('../assets/img/vwash.jpg'),
            type: "Bottle",
            price: 199.00,
        },
        {
            id: 6,
            name: "I-KNOW OVULATION DETECTION KIT - 1 Self Test Kit",
            imgSrc: require('../assets/img/iknow.jpg'),
            type: "Packet",
            price: 599.00,
        },
        
        // Add more products as needed
    ];

    const addToCart = (item) => {
        const existingItemIndex = cart.findIndex((cartItem) => cartItem.id === item.id);
    
        if (existingItemIndex !== -1) {
            const updatedCart = [...cart];
            updatedCart[existingItemIndex].count += 1;
            setCart(updatedCart);
            sessionStorage.setItem('cart', JSON.stringify(updatedCart)); // Update sessionStorage
        } else {
            setCart([...cart, { ...item, count: 1 }]);
            sessionStorage.setItem('cart', JSON.stringify([...cart, { ...item, count: 1 }])); // Update sessionStorage
        }
    };
    
    const removeFromCart = (itemId) => {
        const updatedCart = cart.filter((item) => item.id !== itemId);
        setCart(updatedCart);
        sessionStorage.setItem('cart', JSON.stringify(updatedCart)); // Update sessionStorage
    };
    
    const updateItemCount = (itemId, newCount) => {
        const updatedCart = cart.map((item) => {
            if (item.id === itemId) {
                // Ensure count does not go below 0
                const count = newCount < 0 ? 0 : newCount;
                return { ...item, count };
            }
            return item;
        });
        setCart(updatedCart);
        sessionStorage.setItem('cart', JSON.stringify(updatedCart)); // Update sessionStorage
    };

    const totalItemsInCart = cart.reduce((total, item) => total + item.count, 0);

    const handleCarouselPrev = () => {
        setActiveIndex((prevIndex) => (prevIndex - 1 + Math.ceil(products.length / 3)) % Math.ceil(products.length / 3));
    };

    const handleCarouselNext = () => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % Math.ceil(products.length / 3));
    };
    const handleCheckout = () => {
        navigate('/cart', { state: { cart } });
    };
    // Save cart data to localStorage whenever the cart state changes
    useEffect(() => {
        localStorage.setItem('cart', JSON.stringify(cart));
    }, [cart]);

    return (
        <main className="page-blog-post" style={{ paddingTop: '40px' }}>
            <div className="container" style={{paddingTop:'100px'}}>
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <section className="blog-post-content">
                            <div className="row">
                                <div className="col-12 mb-5">
                                    <img style={{ borderRadius: '20px' }} src={require('../assets/img/medicine.png')} alt="Slack" width="1000" className="img-fluid" />
                                </div>
                            </div>
                        </section>
                        {/* Carousel Section */}
                        <section className="related-posts-section" style={{ paddingLeft: "20px", position: 'relative' }}>
                            <div className="row">
                                <div className="col-md-6">
                                    <h4 className="related-posts-section-title" style={{ paddingLeft: '30px' }}>Top Deals</h4>
                                </div>
                                <div className="col-md-6 text-right">
                                    <Link style={{ paddingRight: '50px' }}>View All</Link>
                                </div>
                            </div>
                            <div className="row">
                                {products.slice(activeIndex * 3, (activeIndex + 1) * 3).map((product) => (
                                    <div key={product.id} className="col-sm-12 col-md-4 related-post">
                                        <div className="product-card">
                                            <div className="related-post-thumbnail-wrapper">
                                                <img style={{ borderRadius: '20px' }} src={product.imgSrc} alt="related post" />
                                                {/* Cart controls */}
                                                {cart.some((cartItem) => cartItem.id === product.id && cartItem.count > 0) ? (
                                                    <div className="cart-controls">
                                                        <button className="blog-post-badge_button_1" onClick={() => updateItemCount(product.id, cart.find((item) => item.id === product.id).count - 1)}>-</button>
                                                        <span className="blog-post-badge_button_3">{cart.find((item) => item.id === product.id).count}</span>
                                                        <button className="blog-post-badge_button_2" onClick={() => updateItemCount(product.id, cart.find((item) => item.id === product.id).count + 1)}>+</button>
                                                    </div>
                                                ) : (
                                                    <button className="blog-post-badge_2" onClick={() => addToCart(product)}>
                                                        ADD
                                                    </button>
                                                )}
                                            </div>
                                            <h5 className="post-tite" style={{paddingTop:'20px'}}>{product.name}</h5>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <p className="post-published-date" style={{ fontSize: '14px' }}>{product.type}</p>
                                                </div>
                                                <div className="col-md-6">
                                                    <p className="post-published-date" style={{ fontSize: '14px' }}>MRP</p>
                                                    <h5 className="post-tite">₹{product.price}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="carousel-controls">
                                <button className="carousel-control-prev" onClick={handleCarouselPrev}>
                                    &lt;
                                </button>
                                <button className="carousel-control-next" onClick={handleCarouselNext}>
                                    &gt;
                                </button>
                            </div>
                        </section>

                        {/* Shopping cart */}
                        {totalItemsInCart > 0 && (
                            <div className="shopping-cart fixed-bottom-right">
                                <span className="cart-icon" onClick={handleCheckout}>
                                    <img src={require('../assets/img/shopping-cart.gif')} alt="blog" width="45px" className="blog-post-thumbnail" /> {totalItemsInCart}
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Medicinehome;