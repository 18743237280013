import React, { useEffect } from "react";
import { Link,useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import { API_URL } from '../../constants';
import '../../external';

const Home = () => {
    

    return (
        <>
        <header class="foi-header landing-header">
            <div class="container" style={{paddingTop:'50px'}}>
                <div class="header-content">
                    <div class="row">
                        <div class="col-md-6">
                            <h1>Great app that makes your life awesome</h1>
                            <p class="text-dark">He has led a remarkable campaign, defying the traditional mainstream parties courtesy of his En Marche! movement.</p>
                            <Link className="btn btn-primary mb-4" to={'/services'}>Get Started</Link>
                            <div class="my-2">
                                <p class="header-app-download-title">GET OUR MOBILE APP</p>
                            </div>
                            <div>
                                <button class="btn btn-app-download mr-2"><img src={require('../assets/images/ios.svg').default} alt="App store"/></button>
                                <button class="btn btn-app-download"><img src={require('../assets/images/android.svg').default} alt="play store"/></button>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <img src={require('../assets/images/Group-23.png')} alt="app" width="388px" class="img-fluid"/>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <section class="py-5 mb-5">
            <div class="container">
                <h2 class="section-title">Application Features</h2>
                <div class="row">
                    <div class="col-lg-6 mb-4 mb-lg-0">
                        <h5>Secure Data</h5>
                        <p class="text-dark">Safeguard your information with our top-notch secure data services, ensuring confidentiality and peace of mind.</p>
                        <p class="mb-5">
                            <Link className="text-primary mb-5" to={'/features'}>Find out More</Link>
                        </p>
                        <h5>Fully functional</h5>
                        <p class="text-dark">Empower your success with our all-encompassing service solutions tailored to meet your unique needs.</p>
                        <p class="mb-5">
                            <Link className="text-primary mb-5" to={'/features'}>Find out More</Link>
                        </p>
                    </div>
                    <div class="col-lg-6 mb-3 mb-lg-0">
                        <h5>Live Chat</h5>
                        <p class="text-dark">Experience real-time support and instant solutions through our responsive live chat services.</p>
                        <p class="mb-5">
                            <Link className="text-primary mb-5" to={'/features'}>Find out More</Link>
                        </p>
                        <h5>Powerful dashboard</h5>
                        <p class="text-dark">Empower your operations with our powerful, user-friendly dashboard for streamlined control and real-time insights.</p>
                        <p class="mb-5">
                            <Link className="text-primary mb-5" to={'/features'}>Find out More</Link>
                        </p>
                    </div>
                    
                </div>
            </div>
        </section>
        <section class="py-5 mb-5">
        <div class="container">
            <div class="row">
                <div class="col-md-6 mb-5 mb-md-0">
                    <img src={require('../assets/images/Frame-4.png')} alt="special offers" class="img-fluid" width="492px"/>
                </div>
                <div class="col-md-6">
                    <h2 class="section-title">Get special offers on the things you love</h2>
                    <p class="mb-5">He has led a remarkable campaign, defying the traditional mainstream parties courtesy of his En Marche! movement. For many, however, the campaign has become less about backing Macron and instead about voting against Le Pen, the National Front candidate.</p>
                    <div class="row">
                        <div class="col-md-6 mb-3">
                            <div class="media landing-feature">
                                <span class="landing-feature-icon"></span>
                                <div class="media-body">
                                    <h5>Essentials</h5>
                                    <p>All the basics for businesses that are just getting started.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mb-3">
                            <div class="media landing-feature">
                                <span class="landing-feature-icon"></span>
                                <div class="media-body">
                                    <h5>Premium</h5>
                                    <p>All the basics for businesses that are just getting started.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mb-3">
                            <div class="media landing-feature">
                                <span class="landing-feature-icon"></span>
                                <div class="media-body">
                                    <h5>Standard</h5>
                                    <p>All the basics for businesses that are just getting started.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="py-5 mb-5">
        <div class="container">
            <h2>Choose the plan that’s right for yor business</h2>
            <p class="text-muted mb-5">Thank you for your very professional and prompt response. I wished I had found you before </p>
            <div class="row">
                <div class="col-lg-4 mb-4">
                    <div class="card pricing-card active">
                        <div class="card-body">
                            <h3 class="mb-1">Starter</h3>
                            <h3 class="mb-1 text-primary">Free</h3>
                            <p class="payment-period">Per month</p>
                            <p class="mb-4">Thank you for your very professional and prompt response.</p>
                            <button class="btn btn-primary btn-rounded">Get Started</button>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 mb-4">
                    <div class="card pricing-card active">
                        <div class="card-body">
                            <h3>Popular</h3>
                            <h3 class="text-primary">$23.00</h3>
                            <p class="payment-period">Per month</p>
                            <p class="mb-4">Thank you for your very professional and prompt response.</p>
                            <button class="btn btn-primary btn-rounded">Get Started</button>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 mb-4">
                    <div class="card pricing-card active">
                        <div class="card-body">
                            <h3>Enterprise</h3>
                            <h3 class="text-primary">$40.00</h3>
                            <p class="payment-period">Per month</p>
                            <p class="mb-4">Thank you for your very professional and prompt response.</p>
                            <button class="btn btn-primary btn-rounded">Get Started</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="py-5 mb-5">
        <div class="container">
            <h2>Satisfied Users</h2>
            <p class="text-muted mb-5">Thank you for your very professional and prompt response. I wished I had found you before </p>
            <div class="row">
                <div class="col-md-4 foi-review mb-5 mb-md-0">
                    <div class="foi-rating">
                        <span class="fas fa-star checked"></span>
                        <span class="fas fa-star checked"></span>
                        <span class="fas fa-star checked"></span>
                        <span class="fas fa-star checked"></span>
                        <span class="fas fa-star checked"></span>
                    </div>
                    <h5 class="foi-review-heading">Great support available</h5>
                    <p class="foi-review-content">Thank you for your very professional and prompt response. I wished I had found you before I spent money on a competitors theme.</p>
                    <div class="media foi-review-user">
                        <img src={require('../assets/images/avatar/avatar_11.jpg')} alt="user" class="avatar"/>
                        <div class="media-body">
                            <h6 class="mb-0">Amarachi Nkechi</h6>
                            <p>UX Designer</p>
                        </div>
                    </div>

                </div>
                <div class="col-md-4 foi-review mb-5 mb-md-0">
                    <div class="foi-rating">
                        <span class="fas fa-star checked"></span>
                        <span class="fas fa-star checked"></span>
                        <span class="fas fa-star checked"></span>
                        <span class="fas fa-star checked"></span>
                        <span class="fas fa-star checked"></span>
                    </div>
                    <h5 class="foi-review-heading">Great support available</h5>
                    <p class="foi-review-content">Thank you for your very professional and prompt response. I wished I had found you before I spent money on a competitors theme.</p>
                    <div class="media foi-review-user">
                        <img src={require('../assets/images/avatar/avatar_12.jpg')} alt="user" class="avatar"/>
                        <div class="media-body">
                            <h6 class="mb-0">Margje Jutten</h6>
                            <p>Developer</p>
                        </div>
                    </div>

                </div>
                <div class="col-md-4 foi-review mb-5 mb-md-0">
                    <div class="foi-rating">
                        <span class="fas fa-star checked"></span>
                        <span class="fas fa-star checked"></span>
                        <span class="fas fa-star checked"></span>
                        <span class="fas fa-star checked"></span>
                        <span class="fas fa-star checked"></span>
                    </div>
                    <h5 class="foi-review-heading">Great support available</h5>
                    <p class="foi-review-content">Thank you for your very professional and prompt response. I wished I had found you before I spent money on a competitors theme.</p>
                    <div class="media foi-review-user">
                        <img src={require('../assets/images/avatar/avatar_13.jpg')} alt="user" class="avatar"/>
                        <div class="media-body">
                            <h6 class="mb-0">Monica Böttger</h6>
                            <p>UX Designer</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
   
    </>
    );
}

export default Home;
