import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const RestaurantDetails = () => {
    const navigate = useNavigate();

    // Retrieve restaurant details from session storage
    const storedRestaurant = sessionStorage.getItem('selectedRestaurant');
    const storedTypes = sessionStorage.getItem('selectedRestaurantTypes');
    const restaurant = storedRestaurant ? JSON.parse(storedRestaurant) : null;
    const types = storedTypes ? JSON.parse(storedTypes) : null;

    // State for managing cart items and total cart quantity
    const [cartItems, setCartItems] = useState([]);
    const [cartQuantity, setCartQuantity] = useState(0);

    // Function to handle adding items to the cart
    const addToCart = (item) => {
        const existingItem = cartItems.find((cartItem) => cartItem.name === item.name);
        if (existingItem) {
            // If the item already exists in the cart, increase its quantity
            const updatedCartItems = cartItems.map((cartItem) =>
                cartItem.name === item.name ? { ...cartItem, quantity: cartItem.quantity + 1 } : cartItem
            );
            setCartItems(updatedCartItems);
            sessionStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
        } else {
            // If the item is not in the cart, add it with quantity 1
            const updatedCartItems = [...cartItems, { ...item, quantity: 1 }];
            setCartItems(updatedCartItems);
            sessionStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
        }
        // Update total cart quantity
        setCartQuantity(cartQuantity + 1);
    };

    const increaseQuantity = (itemId) => {
        const updatedCartItems = cartItems.map((cartItem) =>
            cartItem.name === itemId ? { ...cartItem, quantity: cartItem.quantity + 1 } : cartItem
        );
        setCartItems(updatedCartItems);
        setCartQuantity(cartQuantity + 1);
        sessionStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
    };

    const decreaseQuantity = (itemId) => {
        const updatedCartItems = cartItems
            .map((cartItem) =>
                cartItem.name === itemId ? { ...cartItem, quantity: cartItem.quantity - 1 } : cartItem
            )
            .filter((cartItem) => cartItem.quantity > 0);
        setCartItems(updatedCartItems);
        setCartQuantity(cartQuantity - 1);
        sessionStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
    };

    // Function to navigate to the cart page
    const goToCartPage = () => {
        // Pass cart items and total price to the cart page
        navigate('/foodCart', { state: { cartItems, totalPrice: calculateTotalPrice() } });
    };

    // Function to calculate the total price of items in the cart
    const calculateTotalPrice = () => {
        return cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
    };

    useEffect(() => {
        // Retrieve cart items from session storage on component mount
        const storedCartItems = sessionStorage.getItem('cartItems');
        if (storedCartItems) {
            setCartItems(JSON.parse(storedCartItems));
            setCartQuantity(
                JSON.parse(storedCartItems).reduce((total, item) => total + item.quantity, 0)
            );
        }
    }, [cartItems]);

    if (!restaurant) {
        // Handle the case where there are no stored restaurant details
        return <div>No restaurant details available.</div>;
    }

    return (
        <main class="page-blog-post" style={{paddingTop:"60px"}}>
            <div class="container">
                <div class="row">
                    <div class="col-lg-10 offset-lg-1">
                        <section class="page-header">
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb foi-breadcrumb">
                                    <li class="breadcrumb-item"><Link to={'/foodHome'}>Home</Link></li>
                                    <li class="breadcrumb-item active" aria-current="page">{restaurant.name}</li>
                                </ol>
                            </nav>
                        </section>
                        <section class="related-posts-section" style={{borderBottom:'2px dotted #8094a3', marginBottom:'20px'}}>
                            <div class="row" style={{paddingBottom:"20px"}}>
                                <div class="col-md-8 related-post">
                                    <h6 >{restaurant.name}</h6>
                                    <p class="post-published-date">{restaurant.avilable}</p>
                                    <p class="post-published-date">{restaurant.location}, <span>{restaurant.distance}</span></p>
                                    <p class="post-published-date"><span><img src={require('../assets/images/food/restaurant/fast-delivery.png')} alt="user" width="18px" className="avatar"/> {restaurant.distance} | <span style={{ fontFamily: "sans-serif" }}>₹</span> {restaurant.charges} Delivery fee will apply</span></p>
                                </div>
                                <div class="col-md-4 related-post">
                                    <div class="card pricing-card_1">
                                        <div class="card-body">
                                            <p class="post-published-date" style={{color:"#13690b", fontSize:'20px', borderBottom:"2px solid #8094a3"}}><img src={require('../assets/images/food/restaurant/star.png')} alt="star" width="20px" className="blog-post-thumbnail mr-md-1" />{restaurant.rating}</p>
                                            <p class="post-published-date" style={{color:"#8094a3", fontSize:'18px'}}>{restaurant.reviews} ratings</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section style={{paddingTop:'0', marginTop:'0'}}>
                            <div className="row">
                                {/* Map through types and render each as a FAQ card */}
                                {types.map((type, index) => (
                                    <div className="col-lg-12" key={index}>
                                        <div className="card mb-3 landing-faq-card">
                                            <div className="card-header bg-white">
                                                <a href={`#faq${index + 1}Collapse`} className="d-flex align-items-center" data-toggle="collapse">
                                                    <h6 className="mb-0">{type.name}</h6> <i className="fa-solid fa-chevron-down ml-auto"></i>
                                                </a>
                                            </div>
                                            <div id={`faq${index + 1}Collapse`} className={`collapse ${index === 0 ? 'show' : ''}`} aria-labelledby={`faq${index + 1}Title`}>
                                                <div className="card-body">
                                                    {/* Render items within the type */}
                                                    {type.items.map((item, itemIndex) => (
                                                        <div key={itemIndex} style={{borderBottom:'1px solid #d3d3d3', marginBottom:'20px'}}>
                                                            <div className='container'>
                                                                <div className='row' style={{paddingBottom:'15px'}}>
                                                                    <div className='col-md-8'>
                                                                        <h6>{item.category === 'Veg' ? <img src={require('../assets/images/food/veg.png')} alt="Veg"  width="15px"/> : <img src={require('../assets/images/food/non-veg.png')} alt="Non-Veg" width="15px"/>} {item.name}</h6>
                                                                        <p>{item.description}</p>
                                                                        <p><span style={{ fontFamily: "sans-serif" }}>₹</span>{item.price}</p>
                                                                        {cartItems.find(cartItem => cartItem.name === item.name) ? (
                                                                            <div>
                                                                                <button className="btn btn-food" style={{ padding: '10px' }} onClick={() => decreaseQuantity(item.name)}>
                                                                                    -
                                                                                </button>
                                                                                <span style={{paddingLeft:'10px',paddingRight:'10px'}}>{cartItems.find(cartItem => cartItem.name === item.name).quantity}</span>
                                                                                <button className="btn btn-food" style={{ padding: '10px' }} onClick={() => increaseQuantity(item.name)}>
                                                                                    +
                                                                                </button>
                                                                            </div>
                                                                        ) : (
                                                                            <button className="btn btn-food" style={{ padding: '10px' }} onClick={() => addToCart(item)}>
                                                                                Add
                                                                            </button>
                                                                        )}
                                                                    </div>
                                                                    <div className='col-md-4 d-flex' style={{justifyContent:'right',padding:"10px"}}>
                                                                        <img src={item.image} alt={item.name} className="food_item_details"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </section>
                    
                    </div>
                </div>
            </div>
            {/* Show the cart icon with total number of items in the cart */}
            {cartQuantity > 0 && (
                <div className="shopping-cart food_cart" onClick={goToCartPage}>
                    <span className="cart-icon">
                        <img src={require('../assets/img/shopping-cart.gif')} alt="blog" width="45px" className="blog-post-thumbnail" /> {cartQuantity}
                    </span>
                </div>
            )}
        </main>
    );
}

export default RestaurantDetails;