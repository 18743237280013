import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import AddressForm from '../food/FoodAddress'; // Import the AddressForm component

const FoodCart = () => {
    const location = useLocation();
    const { cartItems, totalPrice } = location.state;
    const [showModal, setShowModal] = useState(false);
    const [addresses, setAddresses] = useState([]);
    const [isNewAddressAdded, setIsNewAddressAdded] = useState(false);
    const [cart, setCart] = useState([]);

       // Initialize cart with cartItems from location state
       useEffect(() => {
        setCart(cartItems);
    }, [cartItems]);

     // Load addresses from local storage on component mount
    useEffect(() => {
        const storedAddresses = JSON.parse(sessionStorage.getItem('addresses'));
        if (storedAddresses && storedAddresses.length > 0) {
            setAddresses(storedAddresses);
            setIsNewAddressAdded(true);
        }
    }, []);

    const updateTotalPrice = (updatedCart) => {
        const newTotalPrice = updatedCart.reduce((acc, item) => {
            return acc + (item.quantity * item.price);
        }, 0);
        // Update the totalPrice state directly
        location.state.totalPrice = newTotalPrice;
    };


    const increaseQuantity = (index) => {
        const updatedCart = [...cart];
        updatedCart[index].quantity += 1;
        setCart(updatedCart);
        updateTotalPrice(updatedCart);
        updateCartItemsInStorage(updatedCart); // Update cart items in session storage
    };

    const decreaseQuantity = (index) => {
        const updatedCart = [...cart];
        if (updatedCart[index].quantity > 0) {
            updatedCart[index].quantity -= 1;
        } else {
            // If the quantity becomes zero, remove the item from the cart
            updatedCart.splice(index, 1);
        }
        setCart(updatedCart);
        updateTotalPrice(updatedCart);
        updateCartItemsInStorage(updatedCart); // Update cart items in session storage
    };

    const updateCartItemsInStorage = (updatedCart) => {
        sessionStorage.setItem('cartItems', JSON.stringify(updatedCart));
    };
    
    const handleFormSubmit = (formData) => {
        // Handle form submission logic here
        // For example, you can console.log(formData) or send it to an API
        console.log(formData);
        // Update addresses and set flag for new address added
        const updatedAddresses = [...addresses, formData];
        setAddresses(updatedAddresses);
        setIsNewAddressAdded(true);

        // Store the addresses in session storage
        sessionStorage.setItem('addresses', JSON.stringify(updatedAddresses));

        // Close the modal after form submission
        setShowModal(false);
    };

    const showFormModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleRemoveAddress = (index) => {
        // Remove the address from the addresses state
        const updatedAddresses = [...addresses];
        updatedAddresses.splice(index, 1);
        setAddresses(updatedAddresses);

        // Update session storage by removing the address at the specified index
        sessionStorage.setItem('addresses', JSON.stringify(updatedAddresses));

        // If there are no addresses left, reset isNewAddressAdded
        if (updatedAddresses.length === 0) {
            setIsNewAddressAdded(false);
        }
    };

    return (
        <main>
            <div className="container" style={{ paddingTop: '120px' }}>
                <div className="row">
                    <div className="col-lg-6">
                        <section className="career-section">
                            <div className="card career-card">
                                <div className="card-body">
                                    <ul className="list-group list-group-flush careers-list-group">
                                        <li className="list-group-item" style={{ display: 'contents' }}>
                                            <div>
                                                <>
                                                    {isNewAddressAdded ? (
                                                        <h4 className="position-title" style={{ paddingBottom: "20px" }}>Delivery Address <img src={require('../assets/images/food/checked.png')} alt="user" width="20px" className="avatar" /></h4>
                                                    ) : (
                                                            <>
                                                                <h6 className="position-title">Add a delivery address</h6>
                                                                <p className="position-location" style={{ color: '#7e80aa', paddingBottom: "20px" }}>You seem to be in the new location</p>
                                                            </>
                                                        )
                                                    }
                                                </>
                                                <div className="container" >
                                                    <div className="card career-card" style={{ border: '2px dashed #b6b6b7' }}>
                                                        <div className="card-body">
                                                            <ul className="list-group list-group-flush careers-list-group">
                                                                <li className="list-group-item" style={{ display: 'contents' }}>
                                                                    {isNewAddressAdded ? (
                                                                        <div className="row">
                                                                            <div className="col-md-2">
                                                                                <img src={require('../assets/images/food/placeholder.png')} alt="user" width="20px" className="avatar" />
                                                                            </div>
                                                                            <div className="col-md-10">
                                                                                {addresses.map((address, index) => (
                                                                                    <div key={index}>
                                                                                        <h5 className="position-title" style={{ textTransform: 'capitalize' }}>{address.name}</h5>
                                                                                        <p style={{ color: '#7e80aa', paddingTop: '10px' }}>{address.addressLine1}, {address.addressLine2}, {address.city}, {address.state}, {address.postalCode}</p>
                                                                                        <button className="btn btn-food" onClick={() => handleRemoveAddress(index)}>Remove</button>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                            <div className="row">
                                                                                <div className="col-md-2">
                                                                                    <img src={require('../assets/images/food/pin.png')} alt="user" width="20px" className="avatar" />
                                                                                </div>
                                                                                <div className="col-md-10">
                                                                                    <h6 className="position-title">Add New Address</h6>
                                                                                    <p style={{ color: '#7e80aa', paddingTop: '10px' }}></p>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    {!isNewAddressAdded && (
                                                                        <button className="btn btn-food" onClick={showFormModal}>Add New</button>
                                                                    )}

                                                                    <AddressForm
                                                                        show={showModal}
                                                                        handleClose={handleCloseModal}
                                                                        handleFormSubmit={handleFormSubmit}
                                                                    />
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div className="col-lg-6">
                        <section className="career-section">
                            <div  className="card career-card" >
                                <div className="card-body" style={{padding:'40px'}}>
                                    <ul className="list-group list-group-flush careers-list-group">
                                        <li className="list-group-item" style={{ display: 'contents' }}>
                                        <h5>Cart Items:</h5>
                                            {cartItems.map((item, index) => (
                                                item.quantity > 0 && (
                                                <li key={index} style={{listStyle:'none'}}>
                                                    <div className="container">
                                                        <div className="row" style={{paddingTop:'20px'}}>
                                                            <div className="col-md-4" style={{paddingBottom:'20px',textAlign:'left'}}>
                                                                <span style={{paddingRight:'10px',color:'#404665', fontWeight:'800'}}>{item.category === 'Veg' ? <img src={require('../assets/images/food/veg.png')} alt="Veg"  width="15px"/> : <img src={require('../assets/images/food/non-veg.png')} alt="Non-Veg" width="15px"/>} {item.name}</span>
                                                            </div>
                                                            <div className="col-md-4" style={{paddingBottom:'20px', textAlign:'center'}}>
                                                                <button style={{padding:'10px'}} className="btn btn-food" onClick={() => decreaseQuantity(index)}>-</button>
                                                                <span style={{padding:'10px', fontWeight:'800'}}>{item.quantity}</span>
                                                                <button style={{padding:'10px'}} className="btn btn-food" onClick={() => increaseQuantity(index)}>+</button>
                                                            </div>
                                                            <div className="col-md-4" style={{textAlign:'right'}}>
                                                                Price: <span style={{ fontFamily: "sans-serif", color:'#404665', fontWeight:'800' }}>₹ {item.price * item.quantity}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                )
                                            ))}
                                    {/* Display total price */}
                                    <div className="container" style={{paddingTop:'20px'}}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <h5 style={{textAlign:'left'}}>Total Price</h5>
                                            </div>
                                            <div className="col-md-6">
                                                <h5 style={{textAlign:'right'}}><span style={{ fontFamily: "sans-serif", fontWeight:'800' }}>₹</span> {totalPrice}</h5>
                                            </div>
                                        </div>
                                        {isNewAddressAdded && (
                                            <center><Link to={'/foodPayment'} className="btn btn-food">PROCEED TO PAY</Link></center>
                                        )}
                                    </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default FoodCart;
