import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import movielogo from "../assets/images/entertainment/movies/logo.gif";
import { Modal } from "react-bootstrap";

const MovieDetails = () => {
    const navigate = useNavigate();
    const [movieDetails, setMovieDetails] = useState(null);
    const [showTrailerModal, setShowTrailerModal] = useState(false);

    useEffect(() => {
        // Retrieve movie details from session storage
        const storedMovieDetails = sessionStorage.getItem('selectedMovie');
        if (storedMovieDetails) {
            // Parse stored movie details if available
            const parsedMovieDetails = JSON.parse(storedMovieDetails);
            setMovieDetails(parsedMovieDetails);
        } else {
            // If no movie details found, redirect to home page or handle the scenario accordingly
            navigate('/');
        }
    }, [navigate]);

    const handleTrailerModalClose = () => {
        setShowTrailerModal(false);
    };

    const handleTrailerModalShow = () => {
        setShowTrailerModal(true);
    };


    return (
        <section>
            <header className="foi-header landing-header bg_header" style={{paddingTop:'80px'}}>
                <div className="container-fluid bg_navbar_movie">
                    <nav className="navbar navbar-expand-lg navbar-light foi-navbar" style={{padding:'10px'}}>
                        <Link className="navbar-brand" to={'/homeEntertain'}>
                            <img src={movielogo} alt="Vasudha Connect" width="150px"/>
                        </Link>
                        <button className="navbar-toggler d-lg-none" type="button" data-toggle="collapse" data-target="#collapsibleNavId_1" aria-controls="collapsibleNavId_1" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="collapsibleNavId_1">
                            <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
                                <li className="nav-item">
                                    <Link to={'/homeEntertain'} style={{color:'#000', fontWeight:'600'}} className="nav-link">Movies</Link>
                                </li>
                                <li className="nav-item">
                                    <Link style={{color:'#000', fontWeight:'600'}} className="nav-link" onClick={(event) => event.preventDefault()}>Events</Link>
                                </li>
                                <li className="nav-item">
                                    <Link style={{color:'#000', fontWeight:'600'}} className="nav-link" onClick={(event) => event.preventDefault()}>Amusement Parks</Link>
                                </li>
                            </ul>
                            <ul className="navbar-nav mt-2 mt-lg-0">
                                <li className="nav-item">
                                    <form className="form-inline my-2 my-lg-0">
                                        <input className="form-control mr-sm-2" type="search" placeholder="Search for Movies, Events, Plays, Sports and Activities" aria-label="Search"/>
                                        <button className="btn btn-outline-entertain my-2 my-sm-0" type="submit">Search</button>
                                    </form>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </header>
            <main className="page-blog-post">
                {movieDetails && (
                    <div className="container-fluid">
                        <div className="movie-details-background" style={{backgroundSize:'cover' ,position: 'absolute', left: 0, width: '100%', height: '100%', backgroundImage: `url(${movieDetails.bg})`, filter: 'brightness(50%)' }}></div>
                        <div className="movie-details-content" style={{ position: 'relative', zIndex: 1 }}>
                            <div className="row">
                                <div className="col-lg-12"> 
                                    <section className="related-posts-section">
                                        <div className="row" style={{ paddingTop: '70px' }}>
                                            <div className="col-md-4 related-post" >
                                                <div className="related-post-thumbnail-wrapper">
                                                    <img className="entertain_item_details_1" src={movieDetails.image} alt="related post" />
                                                    <button className="blog-post-badge_movie" onClick={handleTrailerModalShow}>Trailer</button>
                                                </div>
                                            </div>
                                            <div class="col-md-4 related-post">
                                                <div class="related-post-thumbnail-wrapper">
                                                    <h3 class="post-tite" style={{color:'#fff',textAlign:'left'}}>{movieDetails.name}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </main>
             {/* Trailer Modal */}
             <Modal show={showTrailerModal} onHide={handleTrailerModalClose} style={{paddingTop:'100px'}}>
                <Modal.Header>
                    <Modal.Title>Trailer: {movieDetails && movieDetails.name}</Modal.Title>
                    <button type="button" className="close" onClick={handleTrailerModalClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>

                <Modal.Body>
                    {movieDetails && (
                        <video width="100%" height="auto" controls>
                            <source src={movieDetails.video} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    )}
                </Modal.Body>
            </Modal>
        
        </section>


     
    );
}

export default MovieDetails;
